import {Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'iql-collapse-block',
  templateUrl: './collapse-block.component.html',
  styleUrls: ['./collapse-block.component.scss'],
})
export class CollapseBlockComponent {
  @Input()
  public title: string;

  @Input()
  public collapsed: boolean;

  @Input()
  public subTitle: string = '';

  @Input()
  public buttonTitle: string = '';

  @Output()
  public clickButtonTitle = new EventEmitter<{ type: string, event: any }>();
}
