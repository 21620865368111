import { RulesEntity } from '@Mesh/core/models/bonus/bonus-configuration';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { SALEPLAN_IMAGES_URL } from '@Env/environment';
import {
  Bonus,
  getEveryPlan,
  getMaxProgressByStrategy,
  Strategy, TEveryAmount
} from '@Mesh/core/models/APImodels/saleplan/bonus';
import { TargetNamesRus } from '@Mesh/core/models/APImodels/saleplan/saleplans';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';

@Component({
  selector: 'iql-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent {
  LoadingStatus = LoadingStatus;
  baseUrl = SALEPLAN_IMAGES_URL;

  @Input() bonus: Bonus;
  @Input() predictBonus: Bonus;
  @Input() progressColor: number;
  @Input() predictColor: number;
  @Input() enable: RulesEntity;
  @Input() bonusLoadingStatus: LoadingStatus;
  @Input() typeState: boolean;
  @Input() single: boolean;
  @Input() period: string;
  @Input() mode: boolean;
  @Output() activeSelect = new EventEmitter<Bonus>();
  @Output() enablePlan = new EventEmitter<{bonus: Bonus, enableId: RulesEntity}>();

  plans = {
    3500000039: 'assets/img/plans/redbull.svg',
    3500000028: 'assets/img/plans/tabak_val.svg',
    1: 'assets/img/plans/pmi.svg',
    2: 'assets/img/plans/jti.svg',
    3: 'assets/img/plans/itg.svg',
    7: 'assets/img/plans/iqos.svg',
    2222222212: 'assets/img/plans/top_marki.svg',
    2222222213: 'assets/img/plans/drive_list.svg',
    2222222214: 'assets/img/plans/top_marki.svg',
    3500000103: 'assets/img/plans/duracell.svg',
  };

  get unit(): string {
    return TargetNamesRus[this.bonus?.strategy] ?? 'шт';
  }

  constructor(private cdr: ChangeDetectorRef) {}

  onActiveSelect(bonus: Bonus): void {
    if (this.mode) {
      this.cdr.detectChanges();
      const value = { bonus, enableId: this.enable };
      console.log(value);
      this.enablePlan.emit(value);
    } else {
      console.log('active:', bonus);
      this.activeSelect.emit(bonus);
    }
  }

  get waitingBonus(): string[] {
    const ballBonus =
      this.predictBonus.currentBonus - this.bonus.currentBonus;
    switch (this.bonus.strategy) {
      case Strategy.amount:
      case Strategy.driveList:
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        const countAmount =
          this.predictBonus.executedAmount -
          this.bonus.executedAmount;
        return [
          `Ожидается получение ${ballBonus} Б`,
          `Ожидается поступления товара ${countAmount > 0 ? countAmount : 0} ${this.unit}`,
        ];
      case Strategy.orderAmount:
        return [`Ожидается получение ${ballBonus} Б`, null];
    }
  }

  percentEvery(everyPlan: TEveryAmount): number {
    const { executedAmount } = this.bonus;
    if (everyPlan) {
      const mod = executedAmount % everyPlan.amount;
      if (executedAmount > 0) {
        return mod > 0 ? (mod / everyPlan.amount) * 100 : 100;
      } else {
        return 0;
      }
    }
    return 0;
  }

  percentEveryPredict(everyPlan: TEveryAmount): number {
    const { executedAmount } = this.predictBonus;
    if (everyPlan) {
      if (this.predictBonus.currentBonus > this.bonus.currentBonus) {
        return 100;
      } else {
        const mod = this.predictBonus.executedAmount % everyPlan.amount;
        if (executedAmount > 0) {
          return mod > 0 ? (mod / everyPlan.amount) * 100 : 100;
        } else {
          return 0;
        }
      }
    }
    return 0;
  }

  needOrder(everyPlan: TEveryAmount): number {
    const { executedAmount } = this.bonus;
    if (everyPlan) {
      const mod = executedAmount % everyPlan.amount;
      if (executedAmount > 0) {
        return everyPlan.amount - mod;
      } else {
        return everyPlan.amount;
      }
    }
  }

  get progress(): number {
    switch (this.bonus.strategy) {
      case Strategy.amount:
        return this.percentEvery(getEveryPlan(this.bonus.planId));
      case Strategy.driveList:
        return this.bonus.executedPercent;
      case Strategy.orderAmount:
        return this.bonus.currentBonus > 0 ? 15 : 0;
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        return this.bonus.executedPercent;
    }
  }

  get progressPredict(): number {
    switch (this.bonus.strategy) {
      case Strategy.amount:
        return this.percentEveryPredict(getEveryPlan(this.bonus.planId));
      case Strategy.driveList:
        return this.predictBonus.executedPercent;
      case Strategy.orderAmount:
        return this.predictBonus.executedPercent > 0 ? this.predictBonus.executedPercent : 0;
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        return this.predictBonus.executedPercent;
    }
  }

  get maxProgress(): number {
    return getMaxProgressByStrategy(this.bonus.strategy);
  }

  get valueText(): string[] {
    let percent, amount, ball;
    switch (this.bonus.strategy) {
      case Strategy.amount:
        percent = `${this.percentEvery(getEveryPlan(this.bonus.planId)).toFixed()}%`;
        amount = `${this.bonus.executedAmount} ${this.unit}`;
        ball = `${this.bonus.currentBonus} Б`;
        return [amount, this.bonus.currentBonus > 0 ? ball : percent];
      case Strategy.driveList:
        percent = `${this.bonus.executedPercent}%`;
        amount = `${this.bonus.executedAmount} ${this.unit}`;
        return [amount, percent];
      case Strategy.orderAmount:
        ball = `${this.bonus.currentBonus} Б`;
        return [ball, ball];
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        percent = `${this.bonus.executedPercent}%`;
        amount = `${this.bonus.executedAmount} ${this.unit}`;
        return [amount, percent];
    }
  }

  get receivedBonus(): string[] {
    let ballBonus, count, percent;
    switch (this.bonus.strategy) {
      case Strategy.amount:
        const everyPlan = getEveryPlan(this.bonus.planId);
        ballBonus = this.bonus.currentBonus;
        return [
          `Уже получено ${ballBonus} Б`,
          `Осталось заказать до 100% ${this.needOrder(everyPlan).toFixed()} ${this.unit}`,
        ];
      case Strategy.driveList:
        ballBonus = this.bonus.currentBonus;
        percent = this.bonus.nextStepPercent;
        const _count =
          (this.bonus.requiredAmount ?? 0) -
          this.predictBonus.executedAmount;
        count = _count > 0 ? _count : 0;
        return [
          `Уже получено ${ballBonus} Б`,
          count > 0
            ? `Осталось заказать до ${percent.toFixed()}% ${count > 0 ? count.toFixed() : 0} ${
                this.unit
              } `
            : this.predictBonus.executedAmount > 0
            ? 'План выполнен!'
            : 'Нет плана',
        ];
      case Strategy.orderAmount:
        ballBonus = this.bonus.currentBonus;
        count =
          this.predictBonus.executedAmount -
          this.bonus.executedAmount;
        return [
          `Уже получено ${ballBonus} Б`,
          `Ожидается поступления товара ${count > 0 ? count : 0} ${this.unit}`,
        ];
      case Strategy.top24Material:
      case Strategy.top24SaleVolume:
      case Strategy.val:
        ballBonus = this.bonus.currentBonus;
        percent = this.predictBonus.nextStepPercent;
        const requiredWithPercent =
          ((this.predictBonus.requiredAmount ?? 0) *
            this.predictBonus.nextStepPercent) /
          100;
        count = requiredWithPercent - this.predictBonus.executedAmount;
        return [
          `Уже получено ${ballBonus} Б`,
          count > 0
            ? `Осталось заказать до ${percent.toFixed()}% ${count > 0 ? count.toFixed() : 0} ${
                this.unit
              } `
            : this.predictBonus.executedAmount > 0
            ? 'План выполнен!'
            : 'Нет плана',
        ];
    }
  }
}
