import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductsFilter } from '@Mesh/core/models/internal/products-filter';
import { Observable } from 'rxjs';
import { IProduct, Product, ProductInfo, ProductsCriteria, ProductsData } from '@Mesh/core/models/product';
import { SALEPLAN_URL } from '@Env/environment';
import { map } from 'rxjs/operators';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { IPageableList } from '../../../models/pageable';
import { CategoriesCriteria, ICategory } from '../../../models/category';
import { IManufacturer, ManufacturerCriteria } from '../../../models/manufacturer';
import { BrandCriteria, IBrand } from '../../../models/brand';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  readonly connectionString = `${SALEPLAN_URL}/v1/product`;

  constructor(private readonly http: HttpClient) {
  }

  getProduct(product: ProductsFilter): Observable<ProductsData> {
    let params = new HttpParams();
    const keys = Object.keys(product);
    keys.forEach(k => {
      params = params.append(k, `${product[k]}`);
    });
    const isPlan = false;
    if (product.active) {
      params = params.append('active', `${product.active}`);
    }
    return this.http.get<ProductsData>(`${this.connectionString}`, { params }).pipe(map(data => ({ ...data, isPlan })));
  }

  getProductInfo(materialId: number): Observable<ProductInfo> {
    return this.http.get<ProductInfo>(`${this.connectionString}/info/${materialId}`);
  }

  deleteProductInfo(materialId: number): Observable<number> {
    return this.http.delete<number>(`${this.connectionString}/info/${materialId}`);
  }

  createProductInfo(productInfo: ProductInfo, materialId: number): Observable<ProductInfo> {
    return this.http.put<ProductInfo>(`${this.connectionString}/info/${materialId}`, productInfo);
  }

  patchProductInfo(productInfo: Partial<ProductInfo>, materialId: number): Observable<ProductInfo> {
    return this.http.patch<ProductInfo>(`${this.connectionString}/info/${materialId}`, productInfo);
  }

  getGoalProducts(planIds: number[], outletId: number, page: number): Observable<ProductsData> {
    const params = new HttpParams().append('addressId', `${outletId}`).append('planId', `${planIds}`).append('pageNumber', `${page}`);

    return this.http.get<ProductsData>(`${this.connectionString}`, { params });
  }

  getShoppingCartProducts(outletId: number, materialIds: number[]): Observable<ProductsData> {
    const params = new HttpParams().append('addressId', `${outletId}`).append('materialIds', `${materialIds}`).append('pageSize', '9999');

    return this.http.get<ProductsData>(`${this.connectionString}`, { params });
  }

  getAllProducts(criteria: Partial<ProductsCriteria>): Observable<IPageableList<IProduct>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<IProduct>>(`${SALEPLAN_URL}/v1/product`, { params });
  }

  getTopLevelCategories(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(`${SALEPLAN_URL}/v1/category/top-level-categories`);
  }

  getAllCategories(criteria: CategoriesCriteria = {}): Observable<ICategory[]> {
    const params = objectToParams(criteria);
    return this.http.get<ICategory[]>(`${SALEPLAN_URL}/v1/category`, { params });
  }

  getManufacturer(criteria: ManufacturerCriteria = {}): Observable<IPageableList<IManufacturer>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<IManufacturer>>(`${SALEPLAN_URL}/v1/manufacturer`, { params });
  }

  getBrands(criteria: BrandCriteria = {}): Observable<IPageableList<IBrand>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<IBrand>>(`${SALEPLAN_URL}/v1/brand`, { params });
  }
}
