import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { GroupItem } from './group-item.model';

@Component({
  selector: 'iql-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleComponent implements OnInit {
  @Input() public selectedItem: any;

  @Input() public items: Array<GroupItem> = [];
  @Input() public btn: GroupItem;

  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  public select(item: GroupItem): void {
    this.selectedItem = item.value;
    this.itemSelected.emit(item);
  }
}
