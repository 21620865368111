import { ProductsFilter } from '@Mesh/core/models/internal/products-filter';
import { IProduct, Product, ProductInfo, ProductsCriteria, ProductsData } from '@Mesh/core/models/product';
import { createAction, props } from '@ngrx/store';
import { BrandCriteria, IBrand } from '../../../core/models/brand';
import { CategoriesCriteria } from '../../../core/models/category';
import { ManufacturerCriteria } from '../../../core/models/manufacturer';
import { IPageableList } from '../../../core/models/pageable';

export const loadProductsWithOrders = createAction('[Product] Load Products With Orders', props<ProductsFilter>());
export const productsWithOrdersLoaded = createAction('[Product] Products With Orders loaded', props<{ products: Product[] }>());
export const loadProducts = createAction('[Product] Load Products', props<ProductsFilter>());

export const loadProductsForGoal = createAction('[Product] Load Products For Goal', props<{ planIds: number[], outletId: number, page: number }>());
export const loadProductsForShoppingCart = createAction('[Product] Load Products For Shopping Cart', props<{ outletId: number, materialIds: number[] }>());
export const productsLoaded = createAction('[Product] Products loaded', props<{ products: ProductsData }>());
export const productsForShoppingCartLoaded = createAction('[Product] Products For Shopping Cart loaded', props<{ products: ProductsData }>());

export const clearProducts = createAction('[Product] Clear Products');

export const loadProductInfo = createAction('[Product] load Product info', props<{ materialId: number }>());
export const productInfoLoaded = createAction('[Product] Product info Loaded', props<{ productInfo: ProductInfo[] }>());
export const createProductInfo = createAction('[Product] Create Product info', props<{ productInfo: ProductInfo, materialId: number }>());
export const productInfoCreated = createAction('[Product] Product info created', props<{ productInfo: ProductInfo, materialId: number }>());
export const patchProductInfo = createAction('[Product] Patch Product info', props<{ productInfo: Partial<ProductInfo>, materialId: number }>());
export const productInfoPatched = createAction('[Product] Product info patched', props<{ productInfo: Partial<ProductInfo>, materialId: number }>());

export const loadAllProduct = createAction('[Product] Load All Products ', props<{ criteria: Partial<ProductsCriteria> }>());
export const loadAllProductSuccess = createAction('[Product] Load All Products Success', props<{ data: IPageableList<IProduct> }>());
export const loadAllProductFailure = createAction('[Product] Load All Products Failure', props<{ error: any }>());
export const resetAllProduct = createAction('[Product] Reset All Products ');

export const loadTopLevelCategories = createAction('[Product] LoadTop Level Categories');
export const loadTopLevelCategoriesSuccess = createAction('[Product] LoadTop Level Categories Success', props<{ data: any }>());
export const loadTopLevelCategoriesFailure = createAction('[Product] LoadTop Level Categories Failure', props<{ error: any }>());

export const loadAllCategories = createAction('[Product] Load All Categories', props<{ criteria: CategoriesCriteria }>());
export const loadAllCategoriesSuccess = createAction('[Product] Load All Categories Success', props<{ data: any }>());
export const loadAllCategoriesFailure = createAction('[Product] Load All Categories Failure', props<{ error: any }>());

export const loadManufacturer = createAction('[Product] Load Manufacturer', props<{ criteria?: ManufacturerCriteria }>());
export const loadManufacturerSuccess = createAction('[Product] Load Manufacturer Success', props<{ data: any }>());
export const loadManufacturerFailure = createAction('[Product] Load Manufacturer Failure', props<{ error: any }>());

export const loadBrands = createAction('[Product] Load Brands', props<{ criteria?: BrandCriteria }>());
export const loadBrandsSuccess = createAction('[Product] Load Brands Success', props<{ data: IBrand[] }>());
export const loadBrandsFailure = createAction('[Product] Load Brands Failure', props<{ error: any }>());
