import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'iql-table-plan-progress',
    templateUrl: './plan-progress.component.html',
    styleUrls: ['./plan-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanProgressComponent {
    @Input() row: any;
    @Input() isPrimary = false;

    constructor() {
    }
}
