import { Action, createReducer, on } from '@ngrx/store';
import { BalanceData } from '@Mesh/core/models/balance';
import { financeAction } from '@Mesh/store/actions/finance/finance.actions';
import { FinanceFullData } from '@Mesh/core/models/finance';

export interface State {
  balanceData: BalanceData;
  outletsBalanceData: BalanceData;
  financeFullData: FinanceFullData[];
}

export const initialState: State = {
  balanceData: null,
  outletsBalanceData: null,
  financeFullData: null
};


const documentReducer = createReducer(
  initialState,
  on(financeAction.loadBalanceSuccess, (state, {data}) => ({...state, balanceData: data})),
  on(financeAction.loadOutletsBalanceSuccess, (state, {data}) => ({...state, outletsBalanceData: data})),
  on(financeAction.loadFinanceFullDataSuccess, (state, {data}) => ({...state, financeFullData: data})),
);

export function reducer(state: State | undefined, action: Action) {
  return documentReducer(state, action);
}


export const financeReducer = {
  getBalanceData: (state: State) => state.balanceData,
  getOutletsBalanceData: (state: State) => state.outletsBalanceData,
  getFinanceFullData: (state: State) => state.financeFullData,
};
