import { createReducer, Action, on } from '@ngrx/store';
import * as fromActions from '../../actions/deprecated/plans.actions';
import { PlanConfig } from '@Mesh/core/models/plan';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';

export interface State {
    planConfigs: PlanConfig[];
    planConfigStatus: LoadingStatus;
}

export const initialState: State = {
    planConfigs: [],
    planConfigStatus: LoadingStatus.NotLoaded
};

const plansReducer = createReducer(
    initialState,
    on(fromActions.loadPlanConfigs, (state) => ({ ...state, planConfigs: [], planConfigStatus: LoadingStatus.Loading })),
    on(fromActions.planConfigsLoaded, (state, { planConfigs }) => ({ ...state, planConfigs, planConfigStatus: LoadingStatus.Loaded })),
    on(fromActions.createPlanConfig, (state) => ({ ...state, planConfigStatus: LoadingStatus.Loading })),
    on(fromActions.planConfigCreated, (state, { planConfig }) => ({ 
        ...state, 
        planConfigs: [...state.planConfigs, planConfig], 
        planConfigStatus: LoadingStatus.Loaded 
    })),
    on(fromActions.planConfigCreateError, (state) => ({ ...state, planConfigStatus: LoadingStatus.Error })),
    on(fromActions.updatePlanConfig, (state) => ({ ...state, planConfigStatus: LoadingStatus.Loading })),
    on(fromActions.planConfigUpdated, (state, { planConfig }) => ({ 
        ...state, 
        planConfigs: [...state.planConfigs.map(pc => pc.planId === planConfig.planId ? planConfig : pc)], 
        planConfigStatus: LoadingStatus.Loaded 
    })),
    on(fromActions.planConfigUpdateError, (state) => ({ ...state, planConfigStatus: LoadingStatus.Error })),
    on(fromActions.planConfigDeleted, (state, { planId }) => ({ 
        ...state, 
        planConfigs: [...state.planConfigs.filter(p => p.planId !== planId)]
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return plansReducer(state, action);
}

export const getPlanConfigs = (state: State) => state.planConfigs;
export const getPlanConfigStatus = (state: State) => state.planConfigStatus;
