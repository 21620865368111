import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { USER_URL } from '@Env/environment';
import { Observable } from 'rxjs';
import { BalanceData } from '@Mesh/core/models/balance';
import { IPageableList } from '@Mesh/core/models/pageable';
import { Balance, FinanceSchedule, FinanceScheduleNearest } from '@Mesh/core/models/finance';
import { objectToParams } from '@Mesh/shared/helpers/object.helpers';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  readonly baseUrl = `${USER_URL}/finance`;

  constructor(private http: HttpClient) {
  }

  getBalance(param: BalanceRequestParams): Observable<BalanceData> {
    let params = new HttpParams();

    if (param.size) {
      params = params.append('size', `${param.size}`);
    }
    if (param.pageNumber) {
      params = params.append('page', `${param.pageNumber}`);
    }
    if (param.sort) {
      params = params.append('sort', `${param.sort}`);
    }
    if (param.direction) {
      params = params.append('direction', `${param.direction}`);
    }
    if (param.clientIds && param.clientIds.length) {
      params = params.append('clientId', `${param.clientIds}`);
    }
    if (param.startDate) {
      params = params.append('startDate', `${param.startDate}`);
    }
    if (param.endDate) {
      params = params.append('endDate', `${param.endDate}`);
    }
    return this.http.get<BalanceData>(`${this.baseUrl}/balance`, {params});
  }

  getOutletsBalance(param: BalanceRequestParams): Observable<BalanceData> {
    let params = new HttpParams();

    if (param.size) {
      params = params.append('size', `${param.size}`);
    }
    if (param.pageNumber) {
      params = params.append('page', `${param.pageNumber}`);
    }
    if (param.sort) {
      params = params.append('sort', `${param.sort}`);
    }
    if (param.direction) {
      params = params.append('direction', `${param.direction}`);
    }
    if (param.clientIds && param.clientIds.length) {
      params = params.append('clientIds', `${param.clientIds}`);
    }
    if (param.addressIds && param.addressIds.length) {
      params = params.append('addressIds', `${param.addressIds}`);
    }
    if (param.startDate) {
      params = params.append('startDate', `${param.startDate}`);
    } else {
      params = params.append('startDate', '2020-09-20');
    }
    if (param.endDate) {
      params = params.append('endDate', `${param.endDate}`);
    } else {
      params = params.append('endDate', '2022-09-20');
    }
    return this.http.get<BalanceData>(`${this.baseUrl}/balance/by-addresses`, {params});
  }

  getBalanceFull(criteria: { clientId: number }): Observable<Balance[]> {
    const params = objectToParams(criteria);
    return this.http.get<Balance[]>(this.baseUrl + '/balance', { params });
  }

  getFinanceScheduleFull(criteria: { clientId: number }): Observable<IPageableList<FinanceSchedule>> {
    const params = objectToParams(criteria);
    return this.http.get<IPageableList<FinanceSchedule>>(this.baseUrl + '/paySchedule', { params });
  }

  getFinanceScheduleNearestFull(criteria: { clientId: number }): Observable<FinanceScheduleNearest[]> {
    const params = objectToParams(criteria);
    return this.http.get<FinanceScheduleNearest[]>(this.baseUrl + '/paySchedule/nearest', { params });
  }
}

export interface BalanceRequestParams {
  size?: number;
  pageNumber?: number;
  sort?: string;
  direction?: string;
  clientIds?: number[];
  addressIds?: number[];
  startDate?: string;
  endDate?: string;
}
