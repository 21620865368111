import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SALEPLAN_URL } from '@Env/environment';
import { PlanConfig, PlanConfigItem } from '@Mesh/core/models/plan';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private readonly http: HttpClient) {
  }

  getPlanConfigs(): Observable<PlanConfig[]> {
    return this.http.get<PlanConfig[]>(`${SALEPLAN_URL}/v1/sale-plan/plan-config`);
  }

  getPlanConfig(id: number): Observable<PlanConfig> {
    return this.http.get<PlanConfig>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${id}`);
  }

  createPlanConfig(planConfig: PlanConfig): Observable<PlanConfig> {
    return this.http.post<PlanConfig>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planConfig.planId}`, planConfig);
  }

  updatePlanConfig(planConfig: PlanConfig): Observable<PlanConfig> {
    return this.http.put<PlanConfig>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planConfig.planId}`, planConfig);
  }

  patchPlanConfig(planId: number, planConfig: Partial<PlanConfig>): Observable<PlanConfig> {
    return this.http.patch<PlanConfig>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}`, planConfig);
  }

  deletePlanConfig(id: number): Observable<PlanConfig> {
    return this.http.delete<PlanConfig>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${id}`);
  }

  getPlanConfigItems(planId: number): Observable<PlanConfigItem[]> {
    return this.http.get<PlanConfigItem[]>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}/item`);
  }

  getPlanConfigItem(planId: number, id: number): Observable<PlanConfigItem> {
    return this.http.get<PlanConfigItem>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}/item/${id}`);
  }

  createPlanConfigItem(planId: number, item: PlanConfigItem): Observable<PlanConfigItem> {
    return this.http.post<PlanConfigItem>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}/item`, item);
  }

  updatePlanConfigItem(planId: number, id: number, item: PlanConfigItem): Observable<PlanConfigItem> {
    return this.http.put<PlanConfigItem>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}/item/${id}`, item);
  }

  patchPlanConfigItem(planId: number, id: number, item: Partial<PlanConfigItem>): Observable<PlanConfigItem> {
    return this.http.patch<PlanConfigItem>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}/item/${id}`, item);
  }

  deletePlanConfigItem(planId: number, id: number): Observable<PlanConfigItem> {
    return this.http.delete<PlanConfigItem>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/${planId}/item/${id}`);
  }

  getAllPlanConfigItems(): Observable<PlanConfigItem[]> {
    return this.http.get<PlanConfigItem[]>(`${SALEPLAN_URL}/v1/sale-plan/plan-config/items`);
  }
}
