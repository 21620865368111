import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { getToken } from '@Mesh/store/actions/auth/auth.actions';
import { LoginInfo } from '@Mesh/core/models/loginInfo';
import { State } from '@Mesh/store/reducers';

@Component({
  selector: 'iql-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error: string;

  constructor(private readonly store: Store<State>) {
  }

  ngOnInit(): void {
    localStorage.clear();

    this.loginForm = new FormGroup({
      'phone': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required)
    });
  }

  onSubmit(): void {
    const params = this.loginForm.value as LoginInfo;
    this.store.dispatch(getToken({ params }));
  }
}
