import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  isDevMode, OnChanges,
  OnDestroy,
  OnInit, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { QuickviewService } from './quickview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesToggleService } from '@Mesh/core/services/chat/toggler.service';
import { filter } from 'rxjs/operators';

declare var pg: any;

@Component({
  selector: 'iql-quickview',
  templateUrl: './quickview.component.html',
  styleUrls: ['./quickview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickviewComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  subscriptions: Array<Subscription> = [];
  private _isOpen = false;


  get isOpen(): boolean {
    return this._isOpen;
  }

  ngAfterViewChecked(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
    this.cdr.detectChanges();
    if (!value) {
      const queryParams = {
        action: null,
        action_type: null,
        action_id: null,
        step_id: null,
        address_sap_id: null,
        client_sap_id: null,
        approve: null,
        cancel: null,
        sent_by: null
      };
      this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge'
          });
    }
  }


  constructor(
      private _service: QuickviewService,
      private toggler: PagesToggleService,
      private cdr: ChangeDetectorRef,
      private router: Router,
      private route: ActivatedRoute
  ) {
  }

  get isDevMode(): boolean {
    return isDevMode();
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {

    this.subscriptions.push(this.toggler.quickViewToggle.subscribe(message => {
      this.toggle();
    }));

    this.route.queryParams
        .pipe(filter(params => params.action))
        .subscribe(params => {
          if (params.action === 'chat') {
            if (!this.isOpen) {
              this.toggler.toggleQuickView();
            }
          }
        });

  }

  closeQuickview(): void {
    if (this.isOpen) {
      this.toggler.toggleQuickView();
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.cdr.detectChanges();
  }


}
