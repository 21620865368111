import { DocumentsData } from '@Mesh/core/models/document';
import { Action, createReducer, on } from '@ngrx/store';
import { docAction } from '@Mesh/store/actions/document/document.actions';

export interface State {
  documentsData: DocumentsData;
}

export const initialState: State = {
  documentsData: null,
};


const documentReducer = createReducer(
  initialState,
  on(docAction.loadDocumentsSuccess, (state, {data}) => ({...state, documentsData: data})),
);

export function reducer(state: State | undefined, action: Action) {
  return documentReducer(state, action);
}


export const docReducer = {
  getDocumentData: (state: State) => state.documentsData,
};
