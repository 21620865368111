import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/deprecated/manufacturer.actions';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { DataService } from '@Mesh/core/services/api/deprecated/data.service';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ManufacturerEffects {
    constructor(private _actions$: Actions, private dataService: DataService, private readonly _store: Store<State>) {}

    // manufacturers
    loadCategoryManufacturers$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadCategoryManufacturers),
            switchMap(({ categoryName }) => this.dataService.getCategoryManufacturer(categoryName)),
            map(manufacturers => fromActions.categoryManufacturersLoaded({ manufacturers}))
        )
    );
}

