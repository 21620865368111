import { Deserializable } from '@Mesh/core/models/deserializable';

interface AdditionsType {
  id: number | null;
  name: string | null;
}

export class ChatUserInfo implements Deserializable {
  id: number;
  name: string;
  surname: string;
  avatar?: string;
  patronymic?: string | null;
  allianceId?: number | null;
  clanId?: number | null;
  guildId?: number | null;
  division?: AdditionsType;
  position?: AdditionsType;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get short_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name.charAt(0));
    }
    if (this.surname) {
      result.push(this.surname.charAt(0));
    }
    return result.length ? result.join(' ') : '';
  }

  get full_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name);
    }
    if (this.surname) {
      result.push(this.surname);
    }
    return result.length ? result.join(' ') : '';
  }

}
