import { ITaskOutlet, StepTemplate, StepTemplatePayload } from './../../../core/models/task';
import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/task/tasks.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { TasksService } from '@Mesh/core/services/api/task/tasks.service';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { StepType, TaskStep } from '@Mesh/core/models/task';
import { DataService } from '@Mesh/core/services/api/deprecated/data.service';
import { GetErrorType } from '@Mesh/core/models/APPmodels/errors';
import { AgentPositionService } from '../../../core/services/api/users/agent-position.service';
import { AgentDivisionService } from '../../../core/services/api/users/agent-division.service';
import { AgentUserService } from '../../../core/services/api/users/agent-user.service';

@Injectable()
export class TasksEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly tasksService: TasksService,
        private readonly dataService: DataService,
        private agentUserService: AgentUserService,
        private agentDivisionService: AgentDivisionService,
        private agentPositionService: AgentPositionService) {
    }

    tasksLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadTasks),
            switchMap(({ criteria }) => this.tasksService.getTasks(criteria).pipe(
                map(tasks => fromActions.tasksLoaded({ tasks })),
                catchError(err => ([fromActions.tasksLoadError()]))
            ))
        )
    );

    getClientsForOutlets$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadAllClientsOutlets),
            switchMap(({ params, reset }) => this.dataService.getClientsForOutlets(params).pipe(
                map(data => {
                    return fromActions.loadAllClientsOutletsLoaded({ data: data.content, reset });
                }),
                catchError(err => ([fromActions.tasksLoadError()]))
            ))
        )
    );

    completedTasksLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadCompletedTasks),
            switchMap(props => this.tasksService.getTasks(props).pipe(
                map(tasks => fromActions.completedTasksLoaded({ tasks })),
                catchError(err => ([fromActions.completedTasksLoadError()]))
            ))
        )
    );

    createTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createTask),
            mergeMap(({ task, taskAssignment }) => forkJoin(this.tasksService.createTask(task), of(task), of(taskAssignment))),
            map(([{ id }, task, taskAssignment]) => fromActions.createSteps({
                steps: task.step,
                autoAssignment: task.autoAssignment,
                taskId: id,
                assignment: taskAssignment,
                actionOnSuccess: () => fromActions.taskCreated({ task: { ...task, id } })
            })),
            catchError(err => {
                console.log(err);
                return [fromActions.taskUpdateError()];
            })
        )
    );

    updateTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.updateTask),
            mergeMap(({ task, taskAssignment }) => forkJoin(this.tasksService.updateTask(task), of(task), of(taskAssignment))),
            map(([{ id }, task, taskAssignment]) => fromActions.createSteps({
                steps: task.step,
                taskId: id,
                assignment: taskAssignment,
                actionOnSuccess: () => fromActions.taskUpdated({ task })
            })),
            catchError(err => {
                console.log(err);
                return [fromActions.taskUpdateError()];
            })
        )
    );

    createSteps$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createSteps),
            mergeMap(({ steps, taskId, assignment, actionOnSuccess, autoAssignment }) => {
                const stepsCreation = [];
                steps.forEach(s => s.id ? stepsCreation.push(this.tasksService.updateStep(s, s.id)) : stepsCreation.push(this.tasksService.createStep(s, taskId)));
                return combineLatest(stepsCreation).pipe(mergeMap((taskSteps: TaskStep[]) => [
                    fromActions.stepsCreated({ steps: taskSteps, taskId }),
                    fromActions.addTaskOutlet({
                        taskId,
                        assignment,
                        autoAssignment,
                        actionOnSuccess,
                        taskOutlets: assignment.clientsOutletsToAdd.map(({ addressId, clientId }) => ({ addressId, clientId } as ITaskOutlet))
                    }),
                ]));
            }),
            catchError(error => {
                console.log(error);
                return [fromActions.stepUpdateError({ error })];
            })
        )
    );

    addTaskOutlet$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.addTaskOutlet),
            mergeMap(({ assignment, taskOutlets, taskId, actionOnSuccess, autoAssignment }) => this.tasksService.addTaskOutlet(taskId, taskOutlets).pipe(
                mapTo(
                    autoAssignment ? actionOnSuccess() : fromActions.updateTaskClientsOutlets({ assignment, taskId, actionOnSuccess })
                ),
                catchError(err => ([fromActions.taskUpdateError()]))
            )
            )
        )
    );

    updateTaskClientsOutletsNew$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.updateTaskClientsOutlets),
            mergeMap(({ type, assignment, taskId, actionOnSuccess }) => {
                if (!assignment.clientsOutletsToRemove?.length && !assignment.clientsOutletsToAdd?.length) {
                    return of(fromActions.taskClientsOutletsUpdated());
                }

                const addRequest = !assignment.clientsOutletsToAdd.length ? undefined : this.tasksService.addTaskClientsOutlets(assignment.clientsOutletsToAdd, taskId);
                const removeRequest = !assignment.clientsOutletsToRemove.length ? undefined : this.tasksService.deleteTaskClientsOutlets(assignment.clientsOutletsToRemove, taskId);
                const requests = !!addRequest ? [addRequest] : [];
                if (!!removeRequest) {
                    requests.push(removeRequest);
                }
                return combineLatest(requests).pipe(
                    mergeMap(() => [fromActions.taskClientsOutletsUpdated(), actionOnSuccess()]),
                    catchError(err => {
                        return [fromActions.taskUpdateError()];
                    })
                );
            }))
    );

    patchTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.patchTask),
            mergeMap(({ task, id }) => this.tasksService.patchTask(task, id).pipe(
                mapTo(fromActions.taskPatched({ task, id })),
                catchError(err => ([fromActions.taskUpdateError()]))
            )
            )
        )
    );

    stepLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadStepStatuses),
            switchMap(props => {
                return this.tasksService.getStepsStatuses(props.taskOutletClientId, props.stepId)
            }),
            map(steps => fromActions.stepStatusesLoaded({ steps }))
        )
    );

    proceedWithStep$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.proceedWithStep),
            switchMap(props => this.tasksService.proceedWithStep(props.taskStep, StepType.calculateStock).pipe(
                map(() => fromActions.proceedWithStepSucceeded()),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadCurrentTask$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadTask),
            switchMap(({ taskId }) => this.tasksService.getTaskById(taskId).pipe(
                map(task => fromActions.taskLoaded({ task })),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadTaskClientOutlets$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadTask),
            switchMap(({ taskId }) => this.tasksService.getTaskById(taskId).pipe(
                map(task => fromActions.taskLoaded({ task })),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadPlanNames$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadPlanNames),
            switchMap(() => this.tasksService.getPlans().pipe(
                map(planNames => fromActions.planNamesLoaded({ planNames })),
                catchError(err => {
                    console.log(err);
                    return [];
                })
            ))
        )
    );

    loadPlanStock$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadPlanStock),
            mergeMap(({ outletId, recommendedOrderConfigId, date }) => {
                return combineLatest(this.tasksService.getStockInfo(outletId, recommendedOrderConfigId, date), this.tasksService.getProductsInfo(outletId, recommendedOrderConfigId)).pipe(map(([stockInfo, productsInfo]) => fromActions.planStockLoaded({
                    stockInfo,
                    productsInfo: productsInfo.content
                })));
            }),
            catchError((error) => {
                console.log(error);
                return [fromActions.planStockError()];
            })
        )
    );

    loadPredictBonusClient = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.taskBonusAction.loadPredictBonusClient),
            switchMap(props => this.tasksService.getPredictBonusClient(props.clientId).pipe(
                map(data => fromActions.taskBonusAction.loadPredictBonusClientSuccess({ data })),
                catchError(err => [fromActions.taskBonusAction.loadPredictBonusClientError({ error: GetErrorType(err) })])
            ))
        )
    );

    tradeAgentsLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.tradeAgentsAction.loadTradeAgents),
            switchMap(({ type, ...params }) => this.agentUserService.searchAgentUser(params).pipe(
                map(data => {
                    return fromActions.tradeAgentsAction.loadTradeAgentsSuccess({ data: data.content });
                }),
                catchError(err => ([fromActions.tradeAgentsAction.loadTradeAgentsError()]))
            ))
        )
    );

    divisionsLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.divisionsAction.loadDivisions),
            switchMap(({ type, ...params }) => this.agentDivisionService.searchAgentDivision(params).pipe(
                map(response => {
                    return fromActions.divisionsAction.loadDivisionsSuccess({ data: response.content })
                }),
                catchError(err => ([fromActions.divisionsAction.loadDivisionsError()]))
            ))
        )
    );

    positionsLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.positionsAction.loadPositions),
            switchMap(({ type, ...params }) => this.agentPositionService.searchAgentPosition(params).pipe(
                map(response => {
                    return fromActions.positionsAction.loadPositionsSuccess({ data: response.content })
                }),
                catchError(err => ([fromActions.positionsAction.loadPositionsError()]))
            ))
        )
    );

    createTaskTemplate$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createTaskTemplate),
            switchMap(({ payload }) => this.tasksService.createTaskTemplate(payload)
                .pipe(
                    map(data => fromActions.createTaskTemplateSuccess({ data })),
                    catchError(err => ([fromActions.createTaskTemplateFailure(err)]))
                )
            )
        ));

    createStepTemplate$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createStepTemplate),
            mergeMap(({ saveTemplateSteps, payload }) => {
                const stepsCreation = [];
                payload.forEach(item => {
                    if (saveTemplateSteps[item.body.step]) {
                        stepsCreation.push(this.tasksService.createStepTemplate(item));
                    }
                });
                return combineLatest(stepsCreation)
                    .pipe(
                        map((data: StepTemplate[]) => fromActions.createStepTemplateSuccess({ data })),
                        catchError(error => {
                            return [fromActions.stepUpdateError({ error })];
                        }));
            })));

    loadAllTaskTemplates$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadAllTaskTemplates),
            switchMap(() => this.tasksService.getAllTaskTemplates()
                .pipe(
                    map(data => fromActions.loadAllTaskTemplatesSuccess({ data })),
                    catchError(err => ([fromActions.loadAllTaskTemplatesFailure(err)]))
                )
            )
        ));

    loadAllStepTemplates$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadAllStepTemplates),
            switchMap(() => this.tasksService.getAllStepTemplates()
                .pipe(
                    map(data => fromActions.loadAllStepTemplatesSuccess({ data })),
                    catchError(err => ([fromActions.loadAllStepTemplatesFailure(err)]))
                )
            )
        ));

    createTaskByTemplate$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createTaskByTemplate),
            switchMap(({ templateId, payload }) => this.tasksService.createTaskByTemplate(templateId, payload)
                .pipe(
                    map(data => fromActions.createTaskByTemplateSuccess({ data })),
                    catchError(err => ([fromActions.createTaskByTemplateFailure(err)]))
                )
            )
        ));

    loadModulesLearn$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadModulesLearn),
            mergeMap(({ criteria }) => this.tasksService.getModulesLearnSearch(criteria)
                .pipe(
                    map((data) => fromActions.loadModulesLearnSuccess({ data: data[criteria.moduleType] })),
                    catchError(error => {
                        return [fromActions.loadModulesLearnFailure({ error })];
                    }))
            )));

    loadClientTasks$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadClientTasks),
            mergeMap(({ criteria }) => this.tasksService.getClientTasks(criteria)
                .pipe(
                    map((data) => fromActions.loadClientTasksSuccess({ data: data.content })),
                    catchError(error => {
                        return [fromActions.loadClientTasksFailure({ error })];
                    }))
            )));
}
