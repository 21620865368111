import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@Mesh/core/models/APPmodels/errors';
import { DistroTabTypes } from '../distro.types';
import { Distributor, DistributorSearchParams } from '../../../core/models/distributor';

export enum type {
    SELECT_DISTRIBUTOR = '[ Distributor ] Select a Distributor',
    FIND_ALL_DISTRIBUTORS = '[ Distributor ] Find All Distributors',
    FIND_ALL_DISTRIBUTORS_ERROR = '[ Distributor ] Find All Distributors Error',
    FIND_ALL_DISTRIBUTORS_SUCCESS = '[ Distributor ] Find All Distributors Success',
    RESET_ALL_DISTRIBUTORS = '[ Distributor ] Reset All Distributors',

    FIND_ONE_DISTRIBUTOR = '[ Distributor ] Find One Distributor',
    FIND_ONE_DISTRIBUTOR_ERROR = '[ Distributor ] Find One Distributor Error',
    FIND_ONE_DISTRIBUTOR_SUCCESS = '[ Distributor ] Find One Distributor Success',

    CREATE_DISTRIBUTOR = '[ Distributor ] Create Distributor',
    CREATE_DISTRIBUTOR_ERROR = '[ Distributor ] Create Distributor Error',
    CREATE_DISTRIBUTOR_SUCCESS = '[ Distributor ] Create Distributor Success',

    UPDATE_DISTRIBUTOR = '[ Distributor ] Update Distributor',
    UPDATE_DISTRIBUTOR_ERROR = '[ Distributor ] Update Distributor Error',
    UPDATE_DISTRIBUTOR_SUCCESS = '[ Distributor ] Update Distributor Success',

    DELETE_DISTRIBUTOR = '[ Distributor ] Delete Distributor',
    DELETE_DISTRIBUTOR_ERROR = '[ Distributor ] Delete Distributor Error',
    DELETE_DISTRIBUTOR_SUCCESS = '[ Distributor ] Delete Distributor Success',

    RESET_CURRENT_DISTRIBUTOR = '[ Distributor ] Reset current Distributor',
}

export const setLineActiveStep = createAction('[ Distributor ] setLineActiveStep', props<{ stepsCount: number }>());
export const setCategoryLineActiveStep = createAction('[ Distributor ] setCategoryLineActiveStep', props<{ stepsCount: number }>());
export const setCompanyInfoValid = createAction('[ Distributor ] setCompanyInfoValid', props<{ valid: boolean }>());
export const setOutletValid = createAction('[ Distributor ] Set Outlet Valid', props<{ valid: boolean }>());
export const setCategoryValid = createAction('[ Distributor ] setCategoryValid', props<{ valid: boolean }>());
export const setProductPriceValid = createAction('[ Distributor ] Set Product Price Valid', props<{ valid: boolean }>());
export const selectDistroTab = createAction('[ Distributor ] selectDistroTab', props<{ tab: DistroTabTypes }>());
export const setCompanyData = createAction('[ Distributor ] setCompanyData', props<{ companyData: Distributor }>());
export const saveCompanyData = createAction('[ Distributor ] saveCompanyData', props<{ companyData: Distributor }>());
export const distroError = createAction('[ Distributor ] distroError', props<{ error: ErrorType }>());
export const distroLoaded = createAction('[ Distributor ] distroLoaded', props<{ distro: any }>());

export const findAllDistributors = createAction(type.FIND_ALL_DISTRIBUTORS, props<{ params?: Partial<DistributorSearchParams> }>());
export const findAllDistributorsError = createAction(type.FIND_ALL_DISTRIBUTORS_ERROR, props<{ error: any }>());
export const findAllDistributorsSuccess = createAction(type.FIND_ALL_DISTRIBUTORS_SUCCESS, props<{ distributors: Array<Distributor> }>());
export const resetAllDistributors = createAction(type.RESET_ALL_DISTRIBUTORS);

export const findOneDistributor = createAction(type.FIND_ONE_DISTRIBUTOR, props<{ id: number }>());
export const findOneDistributorError = createAction(type.FIND_ONE_DISTRIBUTOR_ERROR, props<{ error: any }>());
export const findOneDistributorSuccess = createAction(type.FIND_ONE_DISTRIBUTOR_SUCCESS, props<{ distributor: Distributor }>());

export const createDistributor = createAction(type.CREATE_DISTRIBUTOR, props<{ distributor: Distributor }>());
export const createDistributorError = createAction(type.CREATE_DISTRIBUTOR_ERROR, props<{ error: any }>());
export const createDistributorSuccess = createAction(type.CREATE_DISTRIBUTOR_SUCCESS, props<{ distributor: Distributor }>());

export const updateDistributor = createAction(type.UPDATE_DISTRIBUTOR, props<{ distributor: Distributor }>());
export const updateDistributorError = createAction(type.UPDATE_DISTRIBUTOR_ERROR, props<{ error: any }>());
export const updateDistributorSuccess = createAction(type.UPDATE_DISTRIBUTOR_SUCCESS, props<{ distributor: Distributor }>());

export const deleteDistributor = createAction(type.DELETE_DISTRIBUTOR, props<{ id: number }>());
export const deleteDistributorError = createAction(type.DELETE_DISTRIBUTOR_ERROR, props<{ error: any }>());
export const deleteDistributorSuccess = createAction(type.DELETE_DISTRIBUTOR_SUCCESS, props<{ id: number }>());

export const resetCurrentDistributors = createAction(type.RESET_CURRENT_DISTRIBUTOR);
