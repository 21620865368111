export enum DistroTabTypes {
  COMPANY_INFO,
  CLIENT_AND_OUTLET,
  CATEGORIES,
  PRICING
}

export enum DistroCategoryTypes {
  CATEGORIES,
  SUB_CATEGORIES,
  CREATORS,
  BRANDS,
  PRODUCTS
}


