import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Outlet } from '@Mesh/core/models/outlet';

@Component({
  selector: 'iql-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatHeaderComponent implements OnInit {

  @Input() name: string;
  @Input() icon: string;
  @Input() status: string;
  @Input() dialog: any;
  @Input() nameClickable: boolean;
  private _sticky: boolean;
  @Output() onBack = new EventEmitter;
  @Output() onNameClick = new EventEmitter;
  outlet: Outlet;

  constructor(private cdr: ChangeDetectorRef) {
  }


  get sticky(): boolean {
    return this._sticky;
  }

  @Input()
  set sticky(value: boolean) {
    this._sticky = value;
    this.cdr.detectChanges();
  }

  nameClick(): void {
    this.onNameClick.emit();
  }

  ngOnInit(): void {
    console.log('dialog:', this.dialog);
  }

}
