import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@Mesh/store/reducers';
import { getAdditionals } from '@Mesh/store/selectors/breadcrumb/breadcrumb.selector';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';

export interface MenuItem {
  label: string;
  url: string;
  type?: string;
  additional?: string;
  additionalTag?: string;
  active?: boolean;
}

@Component({
  selector: 'pg-breadcrumb',
  template: `
      <ol class="breadcrumb">
        <li *ngFor="let item of menuItems" class="breadcrumb-item">
          <a [routerLink]="!item.active?item.url:null"
             [ngClass]="{'active':item.active }">{{ item.label}} {{item.additional}}</a>
        </li>
      </ol>
      `,
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  menuItems: MenuItem[];

  private unsubscribe$ = new Subject();

  constructor(public readonly _store: Store<State>,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute) {
  }


  ngOnInit() {
    this.menuItems = Array.from(this.createBreadcrumbs(this.activatedRoute.root).values());
    if (this.menuItems.length > 0) {
      this.menuItems[this.menuItems.length - 1].active = true;
    }
    // this.router.events
    //   .pipe(takeUntil(this.unsubscribe$), filter(event => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     this.menuItems = Array.from(this.createBreadcrumbs(this.activatedRoute.root).values());
    //     if (this.menuItems.length > 0) {
    //       this.menuItems[this.menuItems.length - 1].active = true;
    //     }
    //   });
    this._store.select(getAdditionals).pipe(
      map(additionals => {
        if (additionals && this.menuItems) {
          this.menuItems.forEach(item => item.additional = additionals[item.additionalTag] || '');
        }
      }),
      takeUntil(this.unsubscribe$)).subscribe();
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '',
                            breadcrumbs: Map<string, MenuItem> = new Map()): Map<string, MenuItem> {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    const child = children[0];
    const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
    if (routeURL !== '') {
      url += `/${routeURL}`;
    }
    if (child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB]) {
      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB].label;
      const queryParamKey = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB].queryParamKey;
      const additionalTag = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB].additionalTag;
      let additional = '';
      if (queryParamKey) {
        additional = child.snapshot.queryParams[queryParamKey];
      }
      if (!isNullOrUndefined(label)) {
        breadcrumbs.set(label, {label, url, additional, additionalTag});
      }
    }
    return this.createBreadcrumbs(child, url, breadcrumbs);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
