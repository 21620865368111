import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { FinanceService } from '@Mesh/core/services/api/finance/finance.service';
import { financeAction } from '@Mesh/store/actions/finance/finance.actions';
import { of, combineLatest } from 'rxjs'
import { groupBy, flow } from 'lodash/fp';
import { FinanceSchedule, FinanceFullData, FinanceCardData, PaymentSchedule } from '@Mesh/core/models/finance';


@Injectable()
export class FinanceEffects {
  constructor(private readonly _actions$: Actions,
              private readonly financeService: FinanceService) {
  }

  balanceLoading$ = createEffect(() =>
    this._actions$.pipe(
      ofType(financeAction.loadBalance),
      switchMap(params => this.financeService.getBalance(params)
        .pipe(
          map(data => financeAction.loadBalanceSuccess({data})),
          catchError(err => [financeAction.loadBalanceError])
        ))
    )
  );

  outletsBalanceLoading$ = createEffect(() =>
    this._actions$.pipe(
      ofType(financeAction.loadOutletsBalance),
      switchMap(params => this.financeService.getOutletsBalance(params)
        .pipe(
          map(data => financeAction.loadOutletsBalanceSuccess({data})),
          catchError(err => [financeAction.loadOutletsBalanceError])
        ))
    )
  );

  loadFinanceSchedule$ = createEffect(() =>
    this._actions$.pipe(
      ofType(financeAction.loadFinanceFullData),
      switchMap(params => {
        const criteria = {
          sort: '',
          size: params.itemsPerPage,
          page: params.page,
          direction: '',
          startDate: params.startDate,
          endDate: params.finishDate,
        };
        // console.log("EFFECTS params::::", params);
        return combineLatest([
          this.financeService.getFinanceScheduleFull({ ...criteria, clientId: params.clientId }),
          this.financeService.getBalanceFull({ clientId: params.clientId }),
          this.financeService.getFinanceScheduleNearestFull({ clientId: params.clientId }),
        ]).pipe(
            map(([financeSchedule, balance, nearest]) => {
              let payScheduleFilter;
              const _financeBalance = balance.map((b) => {
                const nearestFind = nearest.find((n) => n.kkber === b.kkber && (n.sumFp ?? 0) > 0);
                payScheduleFilter = financeSchedule.content.filter((ps) => ps.kkber === b.kkber);
                return { ...b, nearest: nearestFind, paySchedule: payScheduleFilter };
              });

              let financeBalance: FinanceCardData;
              let paymentSchedule: PaymentSchedule[] = [];

              const providerList = flow(
                  groupBy('buText')
                )(_financeBalance)
                .map((value, key: string) => {
                  let providerCardData: FinanceCardData;
                  let nearestPayment: number;
                  let nearestPayDate: string;
                  const _n = value
                    .filter((o) => !!o.nearest)
                    .map((o) => {
                      nearestPayment = o.nearest.sumFp;
                      nearestPayDate = o.nearest.payDate;
                      return o.nearest;
                    });
                  value.forEach((el) => {
                    return (providerCardData = {
                      id: el.id,
                      title: key,
                      delayed: el.delayed,
                      nearestPayment: nearestPayment,
                      nearestPayDate: nearestPayDate,
                      limit: el.balance,
                      debLimit: el.debLimit,
                      status: 1,
                    });
                  });
                  return { provider: key, data: providerCardData, children: [], show: null };
                })
                .value();

              const financeDpsList = flow(
                  groupBy('externalContractNumber')
                )(_financeBalance)
                .map((value, key: string) => {
                  let financeDpsCardData: FinanceCardData;
                  let nearestPayment: number;
                  let nearestPayDate: string;
                  const _n = value
                    .filter((o) => !!o.nearest)
                    .map((o) => {
                      nearestPayment = o.nearest.sumFp;
                      nearestPayDate = o.nearest.payDate;
                      return o.nearest;
                    });
                  value.forEach((el) => {
                    return (financeDpsCardData = {
                      id: el.id,
                      title: key,
                      delayed: el.delayed,
                      nearestPayment: nearestPayment,
                      nearestPayDate: nearestPayDate,
                      limit: el.balance,
                      debLimit: el.debLimit,
                      status: 2,
                    });
                  });
                  return { provider: 'ДП-' + key, data: financeDpsCardData, children: [], show: null };
                })
                .value();

              const financeUipList = flow(
                  groupBy('kkber')
                )(_financeBalance)
                .map((value, key: string) => {
                  let financeUipCardData: FinanceCardData;
                  let nearestPayment: number;
                  let nearestPayDate: string;
                  const _n = value
                    .filter((o) => !!o.nearest)
                    .map((o) => {
                      nearestPayment = o.nearest.sumFp;
                      nearestPayDate = o.nearest.payDate;
                      return o.nearest;
                    });
                  paymentSchedule = payScheduleFilter.map((item) => {
                    return {
                      paymentDate: item.payDate,
                      paymentSum: item.sumFp,
                    };
                  });

                  value.forEach((el) => {
                    financeBalance = {
                      id: el.id,
                      balance: el.balance,
                      buDate: el.buDate,
                      debLimit: el.debLimit,
                      debts: el.debts,
                      plan: el.plan,
                      debtswa: el.debtswa,
                      delayed: el.delayed,
                    };

                    return (financeUipCardData = {
                      id: el.id,
                      title: key,
                      delayed: el.delayed,
                      nearestPayment: nearestPayment,
                      nearestPayDate: nearestPayDate,
                      limit: el.balance,
                      debLimit: el.debLimit,
                      status: 3,
                    });
                  });
                  return { provider: 'УИП-' + key, data: financeUipCardData, children: [], show: null };
                })
                .value();

              financeUipList.forEach(
                (el) =>
                  (el.children = [
                    {
                      provider: 'Баланс',
                      template: 'balancePayScheduleTemplate',
                      children: [{ template: 'detailsBalanceTemplate', data: financeBalance, children: [], show: null }],
                    },
                    {
                      provider: 'График платежей',
                      template: 'balancePayScheduleTemplate',
                      children: [{ template: 'detailsPayScheduleTemplate', data: paymentSchedule, children: [], show: null }],
                    },
                  ])
              );
              financeDpsList.forEach((el) => (el.children = financeUipList));
              providerList.forEach((el) => (el.children = financeDpsList));
              const finalData: FinanceFullData[] = providerList;
              // console.log("FINAL DATA:::::", finalData)
              return financeAction.loadFinanceFullDataSuccess({data: finalData})
            }),
            catchError(err => [financeAction.loadFinanceFullDataError()])
          )
      })
    )
  );
}
