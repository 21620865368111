import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DocumentService } from '@Mesh/core/services/api/document/document.service';
import { docAction } from '@Mesh/store/actions/document/document.actions';
import { switchMap, map, catchError } from 'rxjs/operators';


@Injectable()
export class DocumentEffects {
  constructor(private readonly _actions$: Actions,
              private readonly documentService: DocumentService) {
  }

  documentsLoading$ = createEffect(() =>
    this._actions$.pipe(
      ofType(docAction.loadDocuments),
      switchMap(params => this.documentService.getDocuments(params)
        .pipe(
          map(data => docAction.loadDocumentsSuccess({data})),
          catchError(err => ([docAction.loadDocumentsError()]))
        ))
    )
  );
}
