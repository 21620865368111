export const transformPhone = (phone: string): string => {
  if (!phone) {
    return '';
  }
  if (phone.length !== 11) {
    return phone;
  }
  try {
    const rawNumber = '+' + phone;

    const countrySection = rawNumber.slice(0, 2);
    const firstSection = rawNumber.slice(2, 5);
    const midSection = rawNumber.slice(5, 8);
    const preLastSection = rawNumber.slice(8, 10);
    const lastSection = rawNumber.slice(10);

    return `${countrySection} (${firstSection}) ${midSection}-${preLastSection}-${lastSection}`;
  } catch (e) {
    return phone;
  }
};
