import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL, USER_URL } from '@Env/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { Plan } from '@Mesh/core/models/brand';
import { CitiesCriteria, City, Region, RegionCriteria } from '@Mesh/core/models/location';
import { CategoryMain, IQLCategory } from '@Mesh/core/models/tree';
import { IResponse } from '../../../models/IResponse';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsService {
    constructor(private readonly http: HttpClient) {
    }

    readonly connectionString = USER_URL;

    getCategoriesMain(): Observable<Array<CategoryMain>> {
        return this.http
            .get<Array<CategoryMain>>(`${SALEPLAN_URL}/v1/category/main`)
            .pipe(
                map((categories: Object) => {
                    return Object.keys(categories).map(k => ({
                        categoryNameId: k,
                        categoryName: categories[k]
                    }));
                })
            );
    }

    getPlans(): Observable<Array<Plan>> {
        return this.http.get<Array<Plan>>('/assets/api/plans.json');
    }

    @Cacheable()
    getCities(query: CitiesCriteria): Observable<IResponse<City[]>> {
        let params = new HttpParams();
        if (query.cityNamePattern) {
            params = params.append('cityNamePattern', query.cityNamePattern);
        }
        if (query.regionId) {
            params = params.append('regionId', query.regionId + '');
        }
        if (query.page) {
            params = params.append('page', query.page + '');
        }
        if (query.size) {
            params = params.append('size', query.size + '');
        }
        return this.http.get<IResponse<City[]>>(`${this.connectionString}/address/cities`, { params });
    }

    getRegions(query: RegionCriteria): Observable<IResponse<Region[]>> {
        let params = new HttpParams();
        if (query.regionNamePattern) {
            params = params.append('regionNamePattern', query.regionNamePattern);
        }
        if (query.page) {
            params = params.append('page', query.page + '');
        }
        if (query.size) {
            params = params.append('size', query.size + '');
        }
        return this.http.get<IResponse<Region[]>>(`${this.connectionString}/address/regions`, { params }).pipe(
          tap((regions) => { console.log('regions', regions) })
        );
    }

    getCategoriesTree(): Observable<CategoryMain[]> {
        return this.getCategoriesMain().pipe(
            switchMap((mains) => {
                return forkJoin(
                    mains.map((main) => {
                        return this.http
                            .get<IQLCategory[]>(`${SALEPLAN_URL}/v2/category/categoryManufactureBrands?materialGroupNameId=${main.categoryNameId}`)
                            .pipe(
                                map((results) => {
                                    main.categories = results;
                                    return main;
                                })
                            );
                    })
                );
            })
        );
    }
}
