import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../../reducers/auth/auth.reducers';

export const getAuthState = createFeatureSelector<fromAuth.State>('auth');

export const getUser = createSelector(
    getAuthState,
    fromAuth.getUser
);
export const getToken = createSelector(
    getAuthState,
    fromAuth.getToken
);
