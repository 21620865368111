import { AuthService } from '../../../../../core/services/api/auth/auth.service';
import { IUserData } from '@Mesh/core/models/user';
import {
  goToNotification,
  loadNotifications,
} from '@Mesh/store/actions/notification/notification.actions';
import { getNotifications } from '@Mesh/store/selectors/notification/notification.selector';
import { Store, select } from '@ngrx/store';
import { State } from '@Mesh/store/reducers';
import { readNotification } from '@Mesh/store/actions/notification/notification.actions';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  Notification,
  NotificationRequest,
} from '@Mesh/core/models/notification';
import { filter } from 'rxjs/operators';
import { WebsocketService } from '@Mesh/core/services/api/websocket/websocket.service';
import { Sounds, SoundService } from '@Mesh/core/services/chat/sound.service';

@Component({
  selector: 'iql-message-notifications',
  templateUrl: './message-notifications.component.html',
  styleUrls: ['./message-notifications.component.scss'],
})
export class MessageNotificationsComponent implements OnInit {
  popups: any[] = [];
  notifications: any[];
  allNotifications: any[];
  notificationToOpen;
  notifications$ = this.store.pipe(select(getNotifications));

  constructor(
    private service: WebsocketService,
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private soundService: SoundService,
  ) {}

  ngOnInit(): void {
    this.notifications$.subscribe((notifications) => {
      if (notifications) {
        this.notifications = notifications.content
          .filter((notification) => {
            const popup = this.popups.find((_popup) => _popup.identifier.indexOf('id=' + notification.id) !== -1);
            if (popup && !popup.sound && notification.payload && notification.payload.eventType === 'message') {
              popup.sound = true;
              this.soundService.play(Sounds.NOTIFICATION);
            }
            return !!popup;
          })
          .map((notification) => {
            //notification.popId = this.popups.find((popup) => popup.identifier.indexOf('id=' + notification.id) !== -1).id;
            // if (!notification.timeout) {
            //   notification.timeout = true;
            //   const removePopup = (notify) => {
            //     this.notifications.slice(
            //       this.notifications.findIndex((pop) => notify.id === pop.id),
            //       1
            //     );
            //     this.cdr.detectChanges();
            //   };
            //   setTimeout(removePopup.bind(null, notification), 5000);
            // }
            return {
              ...notification,
            } as Notification;
          });
        this.cdr.detectChanges();
      }
    });

    this.service.messagesSubject$
      .pipe(filter(({ entityType }) => entityType === 'NOTIFICATION'))
      .subscribe((event) => {
        this.popups.unshift({
          ...event,
          sound: false
        });
      });
  }

  loadNotification(): void {
    const params: NotificationRequest = {
      userId: (this.authService.user as IUserData).id,
      size: 10,
      page: 0,
      sort: 'id',
      direction: 'desc',
    };
    return this.store.dispatch(loadNotifications({ params }));
  }

  removePopup($event: any, index: number, notification: any): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.notifications = [];
    this.popups = [];
    this.cdr.detectChanges();
  }

  openNotification($event: any, index: number, notification: any): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.removePopup(null, index, notification);
    this.store.dispatch(readNotification({ id: notification.id }));
    this.store.dispatch(goToNotification({ notification }));
  }
}
