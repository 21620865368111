import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { FormsModule } from '@angular/forms';
import { ChatAvailableDialogItemComponent } from './chat-available-dialog-item/chat-available-dialog-item.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ChatCommunityComponent } from './chat-community/chat-community.component';
import { ChatUserRecordComponent } from './chat-users-list/chat-user-record/chat-user-record.component';
import { ChatMainPageComponent } from './chat-main-page/chat-main-page.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { UserInfoModule } from '@Mesh/shared/modules/user-info/user-info.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutosizeModule } from 'ngx-autosize';
import { ChatDialogContextMenuComponent } from './chat-dialog/chat-dialog-context-menu/chat-dialog-context-menu.component';
import { ReceivedReadComponent } from './received-read/received-read.component';
import { ChatMessageComponent } from './chat-dialog/chat-message/chat-message.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ChatSearchInputComponent } from './chat-search-input/chat-search-input.component';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
import { ChatUsersListComponent } from './chat-users-list/chat-users-list.component';
import { PreventParentScrollModule } from 'ngx-prevent-parent-scroll';
import { ChatDialogsListComponent } from './chat-dialogs-list/chat-dialogs-list.component';
import { ChatDialogListContextMenuComponent } from './chat-dialog-list-context-menu/chat-dialog-list-context-menu.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { LongPressDirective } from '@Mesh/shared/directives/long-press.directive';
import { ChatAudioComponent } from './chat-audio/chat-audio.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TimesModule } from '@Mesh/shared/modules/times/times.module';
import { SafeModule } from '@Mesh/shared/modules/safe/safe.module';
import { TokenModule } from '@Mesh/shared/modules/token/token.module';
import { TableModule } from 'primeng-lts/table';
import { SharedModule } from '@Mesh/shared/shared.module';
import {ProductRecognitionComponent} from '@Mesh/shared/modules/chat/product-recognition/product-recognition.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
@NgModule({
  imports: [
    PreventParentScrollModule,
    NgxUiLoaderModule,
    TooltipModule.forRoot(),
    NgxDropzoneModule,
    BsDropdownModule.forRoot(),
    AutosizeModule,
    InfiniteScrollModule,
    UserInfoModule,
    CommonModule,
    GalleryModule,
    TimesModule,
    PerfectScrollbarModule,
    LightboxModule,
    SwiperModule,
    TimesModule,
    SafeModule,
    TokenModule,
    FormsModule,
    ContextMenuModule.forRoot(),
    TableModule,
    SharedModule,
    ImageCropperModule
  ],
  declarations: [
    ChatDialogsListComponent,
    ChatUsersListComponent,
    ChatHeaderComponent,
    ChatSearchInputComponent,
    ChatMessageComponent,
    ReceivedReadComponent,
    ChatDialogContextMenuComponent,
    ChatMainPageComponent,
    ChatDialogComponent,
    ChatComponent,
    ChatUserRecordComponent,
    ChatAvailableDialogItemComponent,
    ChatDialogListContextMenuComponent,
    ChatCommunityComponent,
    LongPressDirective,
    ChatAudioComponent,
    ProductRecognitionComponent
  ],
  exports: [ChatComponent]
})
export class ChatModule {
}
