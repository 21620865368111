import { createReducer, Action, on } from '@ngrx/store';
import * as fromActions from "../../actions/deprecated/manufacturer.actions";
import { Manufacturer } from '@Mesh/core/models/manufacturer';

export interface State {
    manufacturers: Manufacturer[];
  }
  
export const initialState: State = {
    manufacturers: []
}

const manufacturerReducer = createReducer(
    initialState,
    on(fromActions.categoryManufacturersLoaded, (state, { manufacturers }) => ({...state, manufacturers })),
);

export function reducer(state: State | undefined, action: Action) {
    return manufacturerReducer(state, action);
}
export const getManufacturers = (state: State) => state.manufacturers;
