import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { MESSAGING_WEBSOCKET_URL } from '@Env/environment';
import { Subject } from 'rxjs';
import {
  RSocketClient,
  JsonSerializer,
  IdentitySerializer,
} from 'rsocket-core';
import RSocketWebSocketClient from 'rsocket-websocket-client';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  messagesSubject$ = new Subject<any>();
  constructor(private authService: AuthService) {
    // Create an instance of a client
  }

  connect(): void {
    const client = new RSocketClient({
      serializers: {
        data: JsonSerializer,
        metadata: IdentitySerializer
      },
      setup: {
        // ms btw sending keepalive to server
        keepAlive: 60000,
        // ms timeout if no keepalive response
        lifetime: 180000,
        // format of `data`
        dataMimeType: 'application/json',
        // format of `metadata`
        metadataMimeType: 'message/x.rsocket.routing.v0',
      },
      transport: new RSocketWebSocketClient({
        url: MESSAGING_WEBSOCKET_URL,
      }),
    });

    client.connect().subscribe({
      onComplete: (socket) => {
        socket.connectionStatus().subscribe((event) => console.log(event));
        // socket provides the rsocket interactions fire/forget, request/response,
        // request/stream, etc as well as methods to close the socket.
        socket
          .requestStream({
            data: {
              token: this.authService.accessToken
            },
            metadata:
              String.fromCharCode('get.manage.events.route'.length) +
              'get.manage.events.route',
          })
          .subscribe({
            onComplete: () => console.log('Request-stream completed'),
            onError: (error) =>
              console.error(`Request-stream error:${error.message}`),
            onNext: (payload) => {
              this.messagesSubject$.next(payload.data);
            },
            onSubscribe: (subscription) => {
              subscription.request(2147483647);
            },
          });
      },
      onError: (error) =>
        console.error(`Request-stream error:${error.message}`),
    });
  }
}
