import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/deprecated/clients.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ClientService } from '@Mesh/core/services/api/deprecated/client.service';
import { DataService } from '@Mesh/core/services/api/deprecated/data.service';
import { TasksService } from '@Mesh/core/services/api/task/tasks.service';

@Injectable()
export class ClientsEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly clientService: ClientService,
        private readonly dataService: DataService,
        private readonly tasksService: TasksService,
        private readonly router: Router
    ) {
    }

    clientsLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadClientsByParams),
            switchMap(params => this.clientService.getClientsByParams(params)),
            map(res => {
                const arr = [];
                res.content.forEach(c => arr.push({ clientId: c.id, name: c.name }));
                return fromActions.clientsByParamsLoaded({ clients: arr });
            })
        )
    );

    clientsDataLoading$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadDataClients),
            // map(({ type, ...data }) => data),
            switchMap(params => this.clientService.getClients(params)),
            map(clientsData => fromActions.clientsDataLoaded({ clientsData })),
            catchError(error => {
                return [];
            })
        )
    );

    loadCurrentClient$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadCurrentClient),
            switchMap(({ clientId }) => this.clientService.getClientDetails(clientId).pipe(
                map(client => fromActions.currentClientLoaded({ client })),
                catchError(() => [])
            ))
        )
    );
    loadClientInfo$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadClientInfo),
            switchMap(({ clientId }) => this.clientService.getClientInfo(clientId).pipe(
                map(clientInfo => fromActions.clientInfoLoaded({ clientInfo })),
                catchError(() => {
                    return [fromActions.currentClientLoaded({ client: undefined })];
                })
            ))
        )
    );
    createClientInfo$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createClientInfo),
            switchMap(({ clientInfo }) => this.clientService.createClientInfo(clientInfo).pipe(
                map(clientInfo => fromActions.clientInfoCreated({ clientInfo })),
                catchError(() => [])
            ))
        )
    );
    updateClientInfot$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.updateClientInfo),
            switchMap(({ clientInfo }) => this.clientService.patchClientInfo(clientInfo).pipe(
                map(clientInfo => fromActions.clientInfoUpdated({ clientInfo })),
                catchError(() => [])
            ))
        )
    );

    loadBonusClient$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadBonusClient),
            switchMap((param) => this.clientService.getBonusClient(param.clientId).pipe(
                map(data => fromActions.loadBonusClientSuccess({ data })),
                catchError(() => [])
            ))
        )
    );
}
