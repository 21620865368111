import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoComponent } from './user-info.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

export * from './user-avatar/user-avatar.component';
@NgModule({
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
  ],
  declarations: [UserInfoComponent, UserAvatarComponent],
  exports: [UserInfoComponent, UserAvatarComponent]
})
export class UserInfoModule { }
