import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TableColumn } from '@Mesh/shared/components/table-v2/models/columns';

@Component({
    selector: 'iql-text-link',
    templateUrl: './text-link.component.html',
    styleUrls: ['./text-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLinkComponent implements OnChanges {
    @Input() row: any;
    @Input() column: TableColumn;
    @Input() invoker: any;
    @Input() isArray = false;
    link = '';

    constructor() {
    }

    private makeLink(link: string, array: string[]): string {
        if (array) {
            array.forEach(v => {
                if (this.row[v] !== null && this.row[v] !== undefined) {
                    link += `/${this.row[v]}`;
                } else {
                    if (!v.includes('.')) {
                        link += `/${v}`;
                    } else {
                        const splits = v.split('.');
                        let value = this.row;
                        for (const split of splits) {
                            value = value[split];
                            if (!value) {
                                break;
                            }
                        }

                        link += value ? `/${value}` : `/${v}`;
                    }
                }
            });
        }
        return link;
    }

    ngOnChanges(): void {
        if (this.isArray) {
            const links: {id: string, link: string[] }[] = this.column.value;
            if (links && links.length) {
                const cArray = links.find(l => !!this.row[l.id] );
                if (cArray) {
                    this.link = this.makeLink(this.column.link, cArray.link);
                } else {
                    this.link = this.makeLink(this.column.link, []);
                }
            }
        } else {
            this.link = this.makeLink(this.column.link, this.column.value);
        }
    }
}
