import { createFeatureSelector, createSelector } from '@ngrx/store';
import { pickBy, uniqBy } from 'lodash-es';
import { Dictionary } from '@ngrx/entity';

import { selectTaskProgressEntities } from '../task/task-progress.selector';
import * as fromOutlets from '../../reducers/deprecated/outlets.reducer';
import { TaskProgress, TaskStatusApi } from '@Mesh/core/models/task';
import { Outlet, OutletFilters } from '@Mesh/core/models/outlet';

const selectState = createFeatureSelector<fromOutlets.State>(fromOutlets.outletFeatureKey);

export const getOutletsState = createFeatureSelector<fromOutlets.State>('outlets');
export const getOutletList = createSelector(getOutletsState, fromOutlets.getOutletData);
export const selectOutletEntities = createSelector(selectState, fromOutlets._selectOutletEntities);
export const selectAllOutlets = createSelector(selectState, fromOutlets._selectAllOutlets);
export const selectOutletFilters = createSelector(selectState, state => state.filters);
export const selectSelectedOutletIds = createSelector(selectState, state => state.selectedOutletIds);
export const selectOutlets = createSelector(
    selectOutletEntities,
    selectOutletFilters,
    selectTaskProgressEntities,
    (outlets: Dictionary<Outlet>, filters: OutletFilters, taskProgress: Dictionary<TaskProgress>, props: { clientId?: number }) => {
        let filteredOutlets = outlets;

        if (!filteredOutlets) {
            return [];
        }

        if (props.clientId) {
            filteredOutlets = pickBy(filteredOutlets, o => o.clientId === props.clientId);
        }

        if (filters.city) {
            filteredOutlets = pickBy(filteredOutlets, o => o.cityName === filters.city);
        }

        if (filters.region) {
            filteredOutlets = pickBy(filteredOutlets, o => o.regionName === filters.region);
        }

        if (filters.outletId) {
            filteredOutlets = pickBy(filteredOutlets, o => o.addressId === filters.outletId);
        }

        const hasStatusFilters = filters.new || filters.moderation || filters.history;
        if (hasStatusFilters && !taskProgress) {
            return [];
        }

        if (!hasStatusFilters) {
            return Object.values(filteredOutlets);
        }

        const result = [];
        for (const [key] of Object.entries(taskProgress)) {
            const outlet = filteredOutlets[key];
            const progress = taskProgress[key];

            if (!outlet) {
                continue;
            }

            const newStatusCount = progress.statusCounts.find(s => s.status === TaskStatusApi.new).stepCount;
            const acceptedStatusCount = progress.statusCounts.find(s => s.status === TaskStatusApi.accepted).stepCount;
            const declinedStatusCount = progress.statusCounts.find(s => s.status === TaskStatusApi.notAccepted).stepCount;
            const moderationStatusCount = progress.statusCounts.find(s => s.status === TaskStatusApi.checking).stepCount;

            const isNew = newStatusCount + declinedStatusCount === progress.stepCount;
            const isAccepted = acceptedStatusCount === progress.stepCount;
            const isOnModeration = moderationStatusCount > 0 && (acceptedStatusCount + moderationStatusCount === progress.stepCount);

            if (filters.new && (isNew || !isNew && !isOnModeration && !isAccepted)) {
                result.push(outlet);
                continue;
            }

            if (filters.history && isAccepted) {
                result.push(outlet);
                continue;
            }

            if (filters.moderation && isOnModeration) {
                result.push(outlet);
            }
        }

        return Object.values(result);
    }
);
export const selectOutletLookup = createSelector(selectOutlets, (state: Outlet[], props) => {
    return [
        { id: 0, name: 'Все' },
        ...state.map(o => {
            return {
                id: o.addressId,
                name: `${o.street} ${o.house}`
            };
        })
    ];
});
export const selectCityLookup = createSelector(selectOutlets, (state: Outlet[], props) => {
    return [
        { id: '', name: 'Все' },
        ...uniqBy(state.map(o => {
            return {
                id: o.cityName,
                name: o.cityName
            };
        }), 'name')
    ];
});
export const selectRegionLookup = createSelector(selectOutlets, (state: Outlet[], props) => {
    return [
        { id: '', name: 'Все' },
        ...uniqBy(state.map(o => {
            return {
                id: o.regionName,
                name: o.regionName
            };
        }), 'name')
    ];
});
export const selectSelectedOutletId = createSelector(selectState, state => state.selectedId);
export const selectSelectedTaskOutletClient = createSelector(selectState, state => state.selectTaskOutletClient);
export const selectOutletsAddress = createSelector(selectState, state => state.outletsAddress);
export const selectAllOutletsAddress = createSelector(selectState, fromOutlets.getAllOutletsAddressData);
export const selectFilterOutletsAddress = createSelector(selectState, fromOutlets.getFilterOutletsAddressData);
export const selectLoadingOutletsAddress = createSelector(selectState, fromOutlets.getLoadingOutletsAddressData);
