import { SALEPLAN_URL, USER_URL} from '@Env/environment';
import { IPageableList } from '@Mesh/core/models/pageable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ICategory } from '../../core/models/category';

@Injectable({ providedIn: 'root' })
export class DistroService {

  constructor(private readonly http: HttpClient) {
  }

  getCounties(): Observable<IPageableList<any>> {
    return this.http.get<IPageableList<any>>(`${USER_URL}/country`);
  }

  getRegions(params: { countryIds: string[], pageNumber: string, name: string, sort: string }): Observable<IPageableList<any>> {
    return this.http.get<IPageableList<any>>(`${USER_URL}/region`, { params });
  }

  getCity(params: { countryIds: string[], regionIds: string[], pageNumber: string, name: string, sort?: string }): Observable<IPageableList<any>> {
    return this.http.get<IPageableList<any>>(`${USER_URL}/city`, { params });
  }

  getCategories(): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(`${SALEPLAN_URL}/v1/category/top-level-categories`);
  }

  getSubCategories(parentCategoryIds: string[]): Observable<ICategory[]> {
    return this.http.get<ICategory[]>(`${SALEPLAN_URL}/v1/category`, { params: { parentCategoryIds } });
  }

  getCreators(): Observable<IPageableList<any[]>> {
    return this.http.get<IPageableList<any[]>>(`${SALEPLAN_URL}/v1/manufacturer`, { params: { pageSize: '1000' } });
  }

  getBrands(): Observable<IPageableList<any[]>> {
    return this.http.get<IPageableList<any[]>>(`${SALEPLAN_URL}/v1/brand`, { params: { pageSize: '1000' } });
  }

  getProducts(params: any): Observable<any> {
    return this.http.get<any>(`${SALEPLAN_URL}/v1/product`, { params });
  }

  createCategory(payload: any): Observable<ICategory> {
    return this.http.post<ICategory>(`${SALEPLAN_URL}/v1/category`, payload);
  }

}
