import { SalePlanEnable } from './saleplans';

export interface BonusPercent {
  persent: number;
  bonus: number;
}
export interface Bonus {
  availablePercents: BonusPercent[];
  expectedBonus: string;
  maxBonus: number;
  strategy: Strategy;
  currentBonus: number;
  nextStepBonus: number;
  executedPercent: number;
  nextStepPercent: number;
  requiredAmount: number | null;
  executedAmount: number | null;
  planName: string;
  planId: number;
  disable?: SalePlanEnable;
  disableOn?: boolean;
  predictBonus: Bonus;
}

export enum Strategy {
  val = 'Val',
  orderAmount = 'OrderAmount',
  amount = 'Amount',
  top24Material = 'Top24Material',
  top24SaleVolume = 'Top24SaleVolume',
  driveList = 'DriveList',
}

export interface TEveryAmount {
  planId: number | null;
  amount: number;
  ballStep: number;
  strategy: Strategy;
  expectedBonus: string;
  planName: string;
}

const everyAmount: TEveryAmount[] = [
  {
    planId: 3500000039,
    amount: 1000,
    ballStep: 50,
    strategy: Strategy.amount,
    expectedBonus: 'За каждые 1 000 ₽ получите - 50 Б',
    planName: 'Red Bull',
  },
  {
    planName: 'Зажигалки + Контрацептивы + Батареи',
    planId: null,
    amount: 2000,
    ballStep: 100,
    strategy: Strategy.orderAmount,
    expectedBonus: 'За каждые 2 000 ₽ получите - 100 Б',
  },
];

export const getMaxProgressByStrategy = (strategy: Strategy): number => {
  switch (strategy) {
    case Strategy.val:
    case Strategy.top24SaleVolume:
      return 120;
    case Strategy.driveList:
      return 100;
    default:
      return 100;
  }
};

export const getEveryPlan = (planId: number | null): TEveryAmount | undefined =>
  everyAmount.find(
    (e) =>
      (e.planId === planId && planId !== null) ||
      (e.strategy === Strategy.orderAmount && planId === null)
  );
