import { Component,
  OnInit, OnDestroy,
  Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@Mesh/core/models/user';
import { ChatService } from '../chat.service';
import { Comment } from '@Mesh/core/models/comment';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { IListItem, MessagesService } from '@Mesh/core/services/chat/messages.service';

@Component({
  selector: 'iql-chat-dialogs-list',
  templateUrl: './chat-dialogs-list.component.html',
  styleUrls: ['./chat-dialogs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDialogsListComponent implements OnInit, OnDestroy {

  @Output() onSettings = new EventEmitter();
  @Output() dialogSelect = new EventEmitter<{ dialog: Comment }>();
  _searchQuery: string;
  u$ = new Subject;
  availDialogs: any[] = [];
  current_user: User;
  total = 0;
  page = 0;
  @Input()
  loading;
  protected _dialogs;

  @Input() set searchDialogs(value: string) {
    if (value === this._searchQuery) { return; }
    this._searchQuery = value;

    // update dialogs
    // this.loadMoreAvailDialogs(true);
    //TODO: run search method
  }

  @Input()
  get dialogs(): IListItem {
    return this._dialogs;
  }
  set dialogs(value: IListItem) {
    this._dialogs = value;
    this.cdr.detectChanges();
  }
  trackBy(index: number, dialog: Comment): string {
      return `${dialog.id}--${dialog.type}`;
  }

  settings(settings: any): void {
    this.onSettings.emit(settings);
  }

  constructor(
    private chatService: ChatService,
    private messagesService: MessagesService,
    private feathersService: FeathersService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnDestroy(): void {
    this.u$.next();
    this.u$.unsubscribe();
  }

  ngOnInit(): void {
    this.current_user = this.feathersService.currentUserSubject.value;
  }

  loadMoreAvailDialogs(update?: any): void {
    // tslint:disable-next-line: curly
    // if (!update && this.dialogs.data.length >= this.total) return;
    // this.messagesService.findChats({ $limit: 10, $skip: this.dialogs.data.length });
  }
}
