import { EventEmitter, Injectable } from '@angular/core';
import { first, flatMap, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { User } from '@Mesh/core/models/user';
import { ChatMessage } from './models/chat-message';
import { ChatLikesService } from '@Mesh/core/services/chat/chat-likes.service';
import { ChatSettingsService } from '@Mesh/core/services/chat/chat-settings.service';
import { MessagesService } from '@Mesh/core/services/chat/messages.service';
import { Sounds, SoundService } from '@Mesh/core/services/chat/sound.service';
import { UserService } from '@Mesh/core/services/chat/user.service';
import { SALEPLAN_URL, TASK_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Outlet } from '../../../core/models/outlet';
import { Comment } from '../../../core/models/comment';

export class ChatPager {
  page?: number;
  skip?: number;
  take?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  updateDialogs = new EventEmitter();
  userChatSubscription$: Subject<Observable<ChatMessage[]>> = new Subject();
  recognitionSectionToggle = false;
  recommendedOrderConfigId: number;
  materialId = [];
  stepId;
  addressId;
  recognitionResultId;
  cigarettesShowcaseImg = '';
  cigarettesShowcase$: BehaviorSubject<ChatMessage> = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private feathers: FeathersService,
    private userService: UserService,
    private messagesService: MessagesService,
    private chatSettings: ChatSettingsService,
    private chatLikesService: ChatLikesService,
    private feathersService: FeathersService,
    private soundService: SoundService
  ) {}
  readonly taskUrl = `${TASK_URL}`;
  readonly baseUrl = `${SALEPLAN_URL}/v1`;

  user: User;

  getUser(userId: number): Observable<any> {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('users')
        .get(userId)
    );
  }

  getUserByClientId(clientId: number): Observable<User> {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('users')
        .find({ query: { clientId }, paginate: false })
    ).pipe(
      map((users: any) => {
        users = Array.isArray(users) ? users : users.data;
        return users[0];
      })
    );
  }

  getTaskById(id: number): Observable<any> {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('tasks')
        .get(id)
    ).pipe(
      tap((task) => {
        return task;
      })
    );
  }

  getOutletByAddressId(addressId: number): Observable<Outlet> {
    return from(
      this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
        .service('outlets')
        .find({ query: { addressId }, paginate: false })
    ).pipe(
      map((outlets: any) => {
        outlets = Array.isArray(outlets) ? outlets : outlets.data;
        return outlets[0];
      })
    );
  }

  getTaskUser(id: number): any {
    return this.feathers.service('task-user').get(id);
  }

  getTaskStep(id: number): any {
    return from(this.feathers.service('task-steps').get(id)).pipe(
      tap((taskStep) => {
        return taskStep;
      })
    );
  }

  getModule(type: string, id: number): any {
    return this.feathers.service('users-chat/module/' + type).get(id);
  }

  getUsers(param: { page: number, omit_ids?: any[], searchQuery: string } ): any {
    const limit = 20;

    const query = {
      /* $sort: {
        name: 1
      }, */
      // $select: ['id', 'name', 'patronymic', 'positionId', 'birthPlace', 'avatarId', 'surname'],
      // readed: false
      $limit: limit,
      $skip: param.page * limit,
      name: {
        $ne: '',
      },
      /* id: {
        $nin: omit_ids,
      }, */
      /* dismissalDate: {
        $in: [ null ]
      } */
    };
    if (param.searchQuery) {
      query['$like'] = param.searchQuery;
    }

    return this.feathers // todo: remove 'any' assertion when feathers-reactive typings are up-to-date with buzzard
      .service('users')
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({
        query,
      });
  }

  getChatCommunity(type: string): Observable<any> {
    return new Observable((observer) => {
      let community;
      this.feathers.service('messages/:type/:typeId').on('created', (msg) => {
        if (
          community &&
          community.type === type &&
          community.info.id === msg.typeId
        ) {
          community.messages.pop();
          community.messages.unshift(msg);
          observer.next(
            (community = {
              ...community,
            })
          );
        }
      });

      this.userService
        .user(false)
        .pipe(flatMap((user) => {
          this.user = user;
          return this.getCommunity({
            type,
            type_id: ['guild', 'clan', 'alliance'].includes(type)
              ? user[type].id
              : 0,
            limit: 2,
          }).pipe(
            map(
              (messages) => ({
                type,
                info: ['guild', 'clan', 'alliance'].includes(type)
                  ? user[type]
                  : { name: 'Общий чат', id: 0 },
                messages,
              }),
              first()
              // catchError(err => of(null))
            )
          );
        }))
        .subscribe((_community) => {
          community = _community;
          observer.next(community);
        });
    });
  }

  getCommunity({ type, type_id, page = 0, limit = 100 }: any): Observable<any> {
    return this.feathers
      .service(`messages/${type}/${type_id}`)
      .watch({
        idField: 'id',
        listStrategy: 'never',
      })
      .find({
        query: {
          $limit: limit,
          $skip: page * limit,
          // participant: user_id
          $sort: {
            createdAt: -1,
          },
        },
      })
      .pipe(map(({ data }) => data));
  }

  getAvailableDialogs({
    page = 0,
    take = 10,
    skip = 0,
  }: ChatPager = {}): Observable<{ total: number; data: any[] }> {
    const $limit = take;
    const $skip = skip;
    this.messagesService.findChats({ type: 'all', $limit, $skip });
    return this.messagesService.subjects.getObs('users-chat/list');
  }

  togglePin(dialog: Comment, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.togglePin(dialog, type, typeId, flag);
  }

  toggleSound(dialog: Comment, type: string, typeId: number, flag: boolean): any {
    return this.chatSettings.toggleSound(dialog, type, typeId, flag);
  }

  removeDialog(dialog: Comment, type: string, typeId: number): any {
    return this.chatSettings.removeDialog(dialog, type, typeId);
  }

  toggleLike(type: string, typeId: number, messageId: number): void {
    this.soundService.play(Sounds.CHAT_MESSAGE_LIKED);
    this.chatLikesService.toggleLike(type, typeId, messageId);
  }
  recognitionResult(id: number): any {
    return this.http.get(
      `${this.taskUrl}/recognition-result/by-task-success-id/${id}`
    );
  }
  recognizedProducts(materialIds?: number[], addressId?: number, materialName?: string): Observable<any> {
    let params = new HttpParams();
    if (materialIds && addressId) {
      params = params.append('addressId', `${addressId}`);
      params = params.append('materialIds', `${materialIds}`);
      params = params.append('pageSize', materialIds.length.toString());
    } else {
      //params = params.append('recommendedOrderConfigId', `${this.recommendedOrderConfigId}`);
      params = params.append('materialGroupNameId', '0101');
      params = params.append('pageSize', '1000');
      params = params.append('addressId', `${this.addressId}`);
    }
    params = params.append('ignoreStock', 'true');
    return this.http.get(`${this.baseUrl}/product`, { params });
  }
  openRecognition(message: any): void {
    const images = message?.attached?.uploads?.filter(
      (item) => ['jpg', 'png'].indexOf(item.ext) !== -1
    );
    this.recognitionResultId = message.taskStep.stepSuccess.recognitionResult.id;
    this.cigarettesShowcaseImg = images[0].url;
    this.cigarettesShowcase$.next(message);
    this.recognitionSectionToggle = true;
  }
  closeRecognition(): void {
    this.recognitionSectionToggle = false;
  }
  cancelResult(): Observable<any> {
    return this.http.put(
      `${this.taskUrl}/task/success/cancel-result`,
      {
        addressId: this.addressId,
        stepId: this.stepId,
      }
    );
  }

  acceptResult(): Observable<any> {
    return this.http.put(
      `${this.taskUrl}/task/success/accept-result`,
      {
        addressId: this.addressId,
        stepId: this.stepId,
      }
    );
  }
  acceptRecognition(coordinates: any): Observable<any> {
    return this.http.put(
      `${this.taskUrl}/recognition-result/${this.recognitionResultId}/add-coord`,
      coordinates
    );
  }
}
