import { Routes } from '@angular/router';
import { CondensedComponent } from './core/components';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'tasks',
    pathMatch: 'full'
  },

  {
    path: '',
    component: CondensedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'clients',
        loadChildren: './pages/clients/clients.module#ClientsModule',
        data: {
          breadcrumb: { label: 'Клиенты' }
        }
      },
      {
        path: 'catalog',
        loadChildren: './pages/catalog/catalog.module#CatalogModule'
      },
      {
        path: 'goals',
        loadChildren: './pages/goals/goals.module#GoalsModule'
      },
      {
        path: 'statistics',
        loadChildren: './pages/statistics/statistics.module#StatisticsModule'
      },
      {
        path: 'tasks',
        loadChildren: './pages/tasks/tasks.module#TasksModule',
        data: {
          breadcrumb: { label: 'Задачи' }
        }
      },
      {
        path: 'auto-orders',
        loadChildren: './pages/auto-orders/auto-orders.module#AutoOrdersModule'
      },
      {
        path: 'min-order-amount',
        loadChildren: './pages/minimum-order-amounts/minimum-order-amounts.module#MinimumOrderAmountsModule'
      },
      {
        path: 'plans',
        loadChildren: './pages/plans/plans.module#PlansModule'
      },
      {
        path: 'manufacturers',
        loadChildren: './pages/manufacturer/manufacturer.module#ManufacturerModule'
      },
      {
        path: 'equipment',
        loadChildren: './pages/equipments/equipments.module#EquipmentsModule',
        data: {
          breadcrumb: { label: 'Оборудование' }
        }
      },
      {
        path: 'tmc-materials',
        loadChildren: './pages/tmc-materials/tmc-materials.module#TmcMaterialsModule',
        data: {
          breadcrumb: { label: 'ТМЦ материалы' }
        }
      },
      {
        path: 'custom-fields',
        loadChildren: './pages/custom-fields/custom-fields.module#CustomFieldsModule',
        data: {
          breadcrumb: { label: 'ТМЦ материалы' }
        }
      },
      {
        path: 'distributors',
        loadChildren: './pages/distro/distro.module#DistroModule'
      },
      {
        path: 'territories',
        loadChildren: './pages/territories/territories.module#TerritoriesModule'
      },
      {
        path: 'company-information',
        loadChildren: './pages/company-information/company-information.module#CompanyInformationModule'
      },
      {
        path: 'vendors',
        loadChildren: './pages/vendors/vendors.module#VendorsModule'
      },
      // {
      //   path: 'manufacturers',
      //   loadChildren: './pages/manufacturers/manufacturers.module#ManufacturersModule'
      // },
      {
        path: 'documents',
        loadChildren: './pages/documents/documents.module#DocumentsModule'
      },
      {
        path: 'routes-and-visits',
        loadChildren: './pages/routes-and-visits/routes-and-visits.module#RoutesAndVisitsModule',
        data: {
          breadcrumb: { label: 'Маршруты и визиты' }
        }
      },
      {
        path: 'routes-and-visits-v2',
        loadChildren: './pages/routes-and-visits-v2/routes-and-visits.module#RoutesAndVisitsModule',
        data: {
          breadcrumb: { label: 'Маршруты и визиты' }
        }
      },
      {
        path: 'users',
        loadChildren: './pages/users/users.module#UsersModule'
      },
      {
        path: 'orders',
        loadChildren: './pages/orders/orders.module#OrdersModule'
      },
      {
        path: 'contracts',
        loadChildren: './pages/contracts/contracts.module#ContractsModule'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'iql-id',
    loadChildren: './pages/iql-id/iql-id.module#IqlIdModule'
  },
  { path: '**', redirectTo: 'tasks' }
];
