import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from '../../reducers';
import { map, tap } from 'rxjs/operators';
import * as fromActions from '../../actions/chat/chat.actions';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable()
export class ChatEffects {
  constructor(
      private readonly _actions$: Actions,
      private readonly _router: Router,
      private readonly _route: ActivatedRoute
  ) {
  }

  openChat$ = createEffect(
      () => this._actions$.pipe(
          ofType(fromActions.openChat),
          tap(({taskId, stepId, clientId, typeId, addressId, approve, cancel}) => {
            const queryParams = {
              action: 'chat',
              action_type: null,
              action_id: typeId,
              task_id: taskId,
              step_id: stepId,
              approve,
              cancel,
              address_sap_id: addressId,
              client_sap_id: clientId,
              sent_by: null
            };
            if (taskId) {
              queryParams['action_type'] = 'task-comments';
              queryParams['action_id'] = typeId;
            }
            return this._router.navigate(
                [],
                {
                  relativeTo: this._route,
                  queryParams,
                  queryParamsHandling: 'merge'
                });
          }),
          map(() => fromActions.openChatSuccess())
      )
  );

  openSuportChat$ = createEffect(
    () => this._actions$.pipe(
        ofType(fromActions.openSupportChat),
        tap(({userId, addressId, clientId}) => {
          const queryParams = {
            action: 'chat',
            action_type: 'support',
            action_id: null,
            sent_by: userId,
            address_sap_id: addressId,
            client_sap_id: clientId,
          };
          console.log(queryParams);
          return this._router.navigate(
              [],
              {
                relativeTo: this._route,
                queryParams,
                queryParamsHandling: 'merge'
              });
        }),
        map(() => fromActions.openChatSuccess())
    )
);


}
