import { Component, OnInit, Input } from '@angular/core';
import { User } from '@Mesh/core/models/user';

@Component({
  selector: 'iql-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
  @Input('user') user: User;
  constructor() { }

}
