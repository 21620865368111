import { PipeTransform, Pipe} from "@angular/core";

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
    transform(items: any[], keys: string[], terms: string[]): any { 
        if (!items || !items.length) {
            return items
        }
        keys.forEach((key, index) => {
            if (terms[index]) {
                items = items.filter(item => item[key].indexOf(terms[index]) !== -1)
            }
        });
        return items
    }
}