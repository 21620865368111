import { createAction, props } from '@ngrx/store';
import {IUser, UsersSearchParams} from '../../core/models/user';

export enum type {
  FIND_ALL_USERS = '[ User ] Find All Users',
  FIND_ALL_USERS_SUCCESS = '[ User ] Find All Users Success',
  FIND_ALL_USERS_FAIL = '[ User ] Find All Users Fail',
  RESET_ALL_USERS = '[ User ] Reset All Users',
}

export const findAllUsers = createAction(type.FIND_ALL_USERS, props<{ searchUserParams: Partial<UsersSearchParams> }>());
export const findAllUsersSuccess = createAction(type.FIND_ALL_USERS_SUCCESS, props<{ users: Array<IUser> }>());
export const findAllUsersFail = createAction(type.FIND_ALL_USERS_FAIL, props<{ error: any }>());
export const resetAllUsers = createAction(type.RESET_ALL_USERS);
