import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Bonus, Strategy } from '@Mesh/core/models/APImodels/saleplan/bonus';

@Component({
  selector: 'iql-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {

  border = {
    maxPercentile: 100,
    curPercentile: 0,
    predictPrecentile: 0
  };

  Strategy = Strategy;

  @Input() bonus: Bonus | undefined;
  @Input() predictBonus: Bonus | undefined;
  @Input() color: number;
  @Input() predictColor: number;
  @Input() typeState: boolean;

  @Input() maxPercentile: number;
  @Input() curPercentile: number;
  @Input() predictPrecentile: number;
  @Input() text: string;

  constructor() { }


  ngOnChanges({ bonus, predictBonus }: SimpleChanges): void {
    if (bonus?.currentValue) {
      const curBonus: Bonus = bonus.currentValue;
      if (bonus.currentValue.strategy === Strategy.val) {
        this.border.curPercentile = curBonus.executedPercent;
        if (curBonus.executedPercent > 120) {
          this.border.maxPercentile = this.border.curPercentile =  this.border.predictPrecentile = 120;
          return;
        }
        if (curBonus.executedPercent > 110) {
          this.border.maxPercentile = 120;
          if (this.predictBonus) {
            this.border.predictPrecentile = this.predictBonus?.executedPercent > 120 ? 120 : this.predictBonus?.executedPercent;
          }
          return;
        }
        if (curBonus.executedPercent > 100) {
          this.border.maxPercentile = 110;
          if (this.predictBonus) {
            this.border.predictPrecentile = this.predictBonus?.executedPercent > 110 ? 110 : this.predictBonus?.executedPercent;
          }
          return;
        }
        this.border.maxPercentile = 100;
        if (this.predictBonus) {
          this.border.predictPrecentile = this.predictBonus?.executedPercent > 100 ? 100 : this.predictBonus?.executedPercent;
        }
      }
      if (bonus.currentValue.strategy === Strategy.amount) {
        this.border.maxPercentile = 100;
        const bonusAmount = curBonus.executedAmount % curBonus.requiredAmount;
        this.border.curPercentile = Math.floor(bonusAmount / curBonus.requiredAmount * 100);
      }
    }
    if (predictBonus?.currentValue) {
      const curPrBonus: Bonus = predictBonus.currentValue;
      if (predictBonus.currentValue.strategy === Strategy.val) {
        this.border.predictPrecentile = curPrBonus.executedPercent;
        if (curPrBonus.executedPercent > 120) {
          this.border.predictPrecentile = this.border.maxPercentile;
          return;
        }
        if (curPrBonus.executedPercent > 110) {
          this.border.predictPrecentile = this.border.maxPercentile > 110 ? curPrBonus.executedPercent : 110;
          return;
        }
        if (curPrBonus.executedPercent > 100) {
          this.border.predictPrecentile = this.border.maxPercentile > 100 ? curPrBonus.executedPercent : 100;
        }
      }
      if (predictBonus.currentValue.strategy === Strategy.amount) {
        const bonusAmount = curPrBonus.executedAmount % curPrBonus.requiredAmount;
        const expectedPercentile = Math.floor(bonusAmount / curPrBonus.requiredAmount * 100);
        this.border.predictPrecentile = expectedPercentile < this.border.curPercentile ? 100 : expectedPercentile;
      }
    }
  }

}
