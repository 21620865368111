import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { IMAGE_URL, SALEPLAN_IMAGES_URL } from '@Env/environment';
import { faEdit, faEye, faPaperPlane, faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ITableInputData, ITableType } from '../table-v2/models/table';
import { TableColumn, TableColumnType } from '../table-v2/models/columns';
import { LoadingStatus } from '@Mesh/core/models/external/loadable';
import moment, { Moment } from 'moment';

@Component({
    selector: 'iql-table-v3',
    templateUrl: './table-v3.component.html',
    styleUrls: ['./table-v3.component.scss']
})
export class TableV3Component implements OnChanges {
    readonly url = IMAGE_URL;
    readonly basePath = SALEPLAN_IMAGES_URL;

    icons = {
        faEye: faEye,
        faEdit: faEdit,
        faTrash: faTrashAlt,
        faPaperPlane: faPaperPlane,
        faSave: faSave
    };

    @Input() data: ITableInputData;
    @Input() columns: TableColumn[];
    @Input() tableType: ITableType;
    @Input() changings: { row: any, key: string }[] = [];
    @Output() onSend = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onShow = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() onActivate = new EventEmitter<any>();
    @Output() onScrollTable = new EventEmitter<any>();

    @Output() onExpand = new EventEmitter<any>();
    @Output() onDateScrollChange = new EventEmitter<Moment>();
    // table
    limit = 9999;
    tableRowData: any[];
    selected = [];
    scrollBarHorizontal = (window.innerWidth < 1250);
    columnModeSetting = 'force';
    TableType = ITableType;
    TableColumnType = TableColumnType;
    selectedMessage = 'выбрано';
    totalMessage = 'всего';
    rowCount = 0;
    pageSize = 0;
    pageCoef = 0;

    LoadingStatus = LoadingStatus;
    tableStatus = LoadingStatus.NotLoaded;
    pageStatus = LoadingStatus.NotLoaded;
    pageNumber = 0;

    prevMonth = null;

    @ViewChild('table', { static: false }) table: DatatableComponent;

    testScroll(event: any): void {
        const target: any = event.srcElement;
        const count = this.tableRowData.length;
        const height = target.scrollHeight;
        const scrolled = target.scrollTop;
        const clientHeight = target.clientHeight;
        const elHeight = Math.round(height / count);
        const curEl = Math.floor((clientHeight + scrolled) / elHeight);
        if (this.tableType === ITableType.orders) {
            if (this.tableRowData[curEl - 1]?.docDate) {
                const curMonth = moment(this.tableRowData[curEl - 1]?.docDate).utc().startOf('month');
                if (curMonth.format('MMMM').toString() !== this.prevMonth?.format('MMMM').toString()) {
                    this.prevMonth = curMonth;
                    this.onDateScrollChange.emit(curMonth);
                }
            }
        }
        if ((target.scrollHeight - target.scrollTop) === target.clientHeight && this.pageStatus !== LoadingStatus.Complete) {
            const pageNumber = this.pageStatus === LoadingStatus.NotLoaded ? 0 : this.pageNumber;
            this.onScrollTable.emit(pageNumber);
        }
    }

    constructor() { window.onresize = () => this.scrollBarHorizontal = window.innerWidth < 2250; }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.tableType === ITableType.catalog) {
            if (this.data.products?.content) {
                const currentPage = this.data.products.number;
                const maxPages = this.data.products.totalPages - 1;
                if (currentPage === maxPages) {
                    this.pageStatus = LoadingStatus.Complete;
                } else {
                    this.pageNumber = currentPage + 1;
                    this.pageStatus = LoadingStatus.Loaded;
                }
                this.tableRowData = [...this.data.products.content];
                this.tableRowData.forEach(r => {
                    r.active = true;
                    if (r.readonly === undefined) {
                        r.readonly = true;
                    }
                    r.icon = r.url ? `/${r.url}` : (r.productInfo && r.productInfo.imageUrl ? r.productInfo.imageUrl : '');
                    r.shortName = r.productInfo && r.productInfo.name ? r.productInfo.name : r.materialName;
                    r.isBasketEnabled = false; // basket state
                    // прайс
                    if (r.stock && r.stock.length) {
                        const priceList = r.stock[0];
                        r.ndsPrice = priceList.ndsPrice;
                        r.price = priceList.price;
                        r.unit = priceList.unit;
                        r.mrc = priceList.mrc;
                    }
                    // план
                    if (r.salePlans && r.salePlans.length) {
                        r.salePlans = r.salePlans[0];
                        r.goes = [r.salePlans.dateFrom, r.salePlans.dateTo];
                        r.planCurrent = r.salePlans.planOverQuantity;
                        r.planQuantity = r.salePlans.quant;
                        r.planLeftQuantity = r.salePlans.planLeftQuantity;
                    }
                    r.requestCount =  r.amountOrderFrozen + r.amountHistory;
                    r.totalCount = r.requestCount > r.salePlanQuant ? r.salePlanQuant : r.requestCount;
                    // количество
                    if (this.data.quantity) {
                        const quantity = this.data.quantity ? this.data.quantity.find(q => q.materialId === r.materialId) : null;
                        quantity ? r.quantity = quantity.quantity : r.quantity = 0;
                    }
                });
                this.rowCount = this.data.products.totalElements;
            }
            if (this.data.loadingStatus) {
                this.tableStatus = this.data.loadingStatus;
            }
        }
        if (changes.data && this.tableType === ITableType.orders) {
            if (this.data.docHistories && this.data.docHistories.length) {
                this.tableRowData = [...this.data.docHistories];
                if (this.data.selectedRowId) {
                    const row = this.tableRowData?.find(t => t.docNumber === this.data.selectedRowId);
                    if (row) {
                        this.table.rowDetail.toggleExpandRow(row);
                    }
                    return;
                }
                this.tableRowData.forEach(r => {
                    r.active = true;
                    // r.bonus = r.bonus ?? (Math.random() > 0.8 ? true : false); // just testing bonus styling
                    r.name = r.docNumber ? `Заказ#${r.docNumber}` : 'Заказ в обработке';
                    r.status = r.docDate ? `Обработан` : 'Нет данных';
                });
            }
            if (this.data.loadingStatus) {
                this.tableStatus = this.data.loadingStatus;
            }
        }
    }

    onFooterPage(): void {}

    onSelectAllClick(event: any): void {
      event.target.checked ? this.selected = [...this.tableRowData] : this.selected = [];
    }

    valueChanged(data: { row: any, key: string }): void {
        data.row.changed = true;
        const isAlreadyChanged = this.changings.find(c => c.row.materialId === data.row.materialId && c.key === data.key);
        if (!isAlreadyChanged) {
            this.changings.push(data);
        }
    }

    onDataChange(row: any): void {
        row.changed = false;
        this.onSend.emit(row);
    }

    onDataEdit(row: any): void {
        this.onEdit.emit(row);
    }

    onDataShow(row: any): void {
        this.onShow.emit(row);
    }

    onDataDelete(row: any): void {
        this.onDelete.emit(row);
    }

    onDataActivate(row: any): void {
        this.onActivate.emit(row);
    }

    getRowClass(row: any): string {
        return row.className ? row.className : `${!row.active ? 'deactivated' : ''} ${row.succseed ? 'succseed' : ''} ${row.bonus ? 'bonus' : ''}`;
    }

    makeRowReadonly(row: any): void {
        row.readonly = true;
    }
    makeRowEditable(row: any): void {
        row.readonly = false;
    }

    toggleExpandRow(row: any, expandedValue: any): void {
        this.table.rowDetail.toggleExpandRow(row);
        if (!expandedValue) {
            this.onExpand.emit(row);
        }
    }
    onDetailToggle(): void {

    }
}
