import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/saleplan/saleplan.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { SalePlanService } from '@Mesh/core/services/api/saleplan/saleplan.service';
import { GetErrorType } from '@Mesh/core/models/APPmodels/errors';
import { loadBonus, loadPredictBonus } from '@Mesh/store/actions/saleplan/bonus.actions';

@Injectable()
export class SalePlanEffects {
  constructor(private _actions$: Actions, private salePlanService: SalePlanService, private readonly _store: Store<State>) {
  }

  salePlansLoad$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadSalePlans),
      switchMap(({ addressId }) => this.salePlanService.getSalePlanPlans(addressId).pipe(
        switchMap(salePlans => [
          fromActions.salePlansLoaded({ salePlans }),
          fromActions.loadSalePlanProgress({ addressIds: [addressId], planIds: salePlans.map(s => s.plan_id) }),
          loadBonus({ addressId, planIds: salePlans.map(s => s.plan_id) }),
          loadPredictBonus({ addressId, planIds: salePlans.map(s => s.plan_id) })
        ]),
        catchError(err => [fromActions.salePlansLoadError({ error: GetErrorType(err) })])
      ))
    )
  );

  loadSalePlanEnableRules$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadSalePlansEnableRules),
      switchMap(props => this.salePlanService.getEnableSalePlans().pipe(
        map(salePlansEnableRules => fromActions.salePlansEnableRulesLoaded({ salePlansEnableRules })),
        catchError(err => [fromActions.salePlansEnableRulesLoadError({ error: GetErrorType(err) })])
      ))
    )
  );

  salePlanEnable$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.enableSalePlan),
      switchMap(props => this.salePlanService.enableSalePlan(props.enableOutletGoal).pipe(
        map(salePlansEnableRule => fromActions.loadSalePlansEnableRules({ addressId: props.enableOutletGoal.addressId }))
      ))
    )
  );

  salePlanDisable$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.disableSalePlan),
      switchMap(props => this.salePlanService.disableSalePlan(props.id).pipe(
        map(() => fromActions.loadSalePlansEnableRules({ addressId: props.addressId }))
      ))
    )
  );

  loadSalePlanProgress$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadSalePlanProgress),
      switchMap(props => this.salePlanService.getSalePlanProgress(props).pipe(
        map(planProgress => fromActions.salePlanProgressLoaded({ planProgress })),
        catchError(err => [fromActions.salePlanProgressLoadError({ error: GetErrorType(err) })])
      ))
    )
  );

  loadSalePlanProgressConfigs$ = createEffect(
    () => this._actions$.pipe(
      ofType(fromActions.loadSalePlanProgressConfigs),
      switchMap(props => this.salePlanService.getSalePlanProgressConfigs(props).pipe(
        map(planProgressConfigs => fromActions.salePlanProgressConfigsLoaded({ planProgressConfigs })),
        catchError(err => [fromActions.salePlanProgressConfigsLoadError({ error: GetErrorType(err) })])
      ))
    )
  );
}

