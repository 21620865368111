import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUserData, IUserInfo } from '../../../models/user';
import { AUTH_URL } from '@Env/environment';
import { LoginInfo } from '@Mesh/core/models/loginInfo';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private get httpOptions(): Object {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return { headers } as Object;
  }

  constructor(private http: HttpClient) {
  }

  get isLoggedIn(): boolean {
    const accessTokenExpires = (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.accessTokenExpires;
    const refreshTokenExpires = (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.refreshTokenExpires;

    return accessTokenExpires && (moment.utc(accessTokenExpires).diff(moment.utc()) >= 5 * 1000 || moment.utc(refreshTokenExpires).diff(moment.utc()) >= 5 * 1000);
  }

  get isPreviewMode(): boolean {
    return !!JSON.parse(localStorage.getItem('isPreview'));
  }

  get user(): IUserData {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.user;
  }

  get accessToken(): string {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.accessToken;
  }

  get token(): string {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.accessToken;
  }

  get refreshToken(): string {
    return (JSON.parse(localStorage.getItem('userInfo')) as IUserInfo)?.refreshToken;
  }

  getToken(loginInfo: LoginInfo): Observable<IUserInfo> {
    return this.http.post(`${AUTH_URL}`, loginInfo, this.httpOptions).pipe(map((userInfo: any) => {
      return {
        accessToken: userInfo.access_token,
        refreshToken: userInfo.refresh_token,
        accessTokenExpires: userInfo.accessTokenExpires,
        refreshTokenExpires: userInfo.refreshTokenExpires,
        user: userInfo.user
      } as IUserInfo;
    }));
  }

  updateTokens(): Observable<IUserInfo> {
    return this.http.post(`${AUTH_URL}/refresh-token`, { refreshToken: this.refreshToken }, this.httpOptions).pipe(map((userInfo: any) => {
      const result = {
        accessToken: userInfo.access_token,
        refreshToken: userInfo.refresh_token,
        accessTokenExpires: userInfo.accessTokenExpires,
        refreshTokenExpires: userInfo.refreshTokenExpires,
        user: userInfo.user
      } as IUserInfo;

      console.log(result);
      return result;
    }));
  }

  logout(): void {
    localStorage.clear();
    window.location.assign('');
  }
}
