import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[iqlRandomGradient]'
})
export class RandomGradientDirective {
  @Input() gradients = [
    {
      'colors': ['#84A3E9', '#c5eccd']
    },
    {
      'colors': ['#A1FF8B', '#3F93FF']
    },
    {
      'colors': ['#338BC2', '#c0e2c8']
    },
    {
      'colors': ['#338BC2', '#c7b3b9']
    },
    {
      'colors': ['#8DE6E5', '#75AFF2']
    },
    {
      'colors': ['#73CCD8', '#2B6B9F']
    },
    {
      'colors': ['#6CACE4', '#8AC3EC']
    },
  ];

  @Input() rotation = 135;

  constructor(private el: ElementRef) {
    this.setBackground(`linear-gradient(${this.getGradient()})`);
  }

  getGradient(): string {
    return `${this.rotation}deg, ${this.gradients[Math.floor(Math.random() * (this.gradients.length - 1))].colors.join(', ')}`;
  }

  setBackground(bg: string): void {
    this.el.nativeElement.style.background = bg;
  }

}
