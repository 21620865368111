import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { AgentDivision, AgentDivisionCreatePayload, AgentDivisionSearchParams } from '../../../models/agent-division';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root'
})
export class AgentDivisionService {
  constructor(private readonly http: HttpClient) { }

  searchAgentDivision(searchAgentDivisionParams: Partial<AgentDivisionSearchParams>): Observable<IPageableList<AgentDivision>> {
    const params = objectToParams(searchAgentDivisionParams);
    return this.http.get<IPageableList<AgentDivision>>(`${USER_DISTRIBUTOR_URL}/v1/agent-division`, { params });
  }

  createAgentDivision(payload: AgentDivisionCreatePayload): Observable<AgentDivision> {
    return this.http.post<AgentDivision>(`${USER_DISTRIBUTOR_URL}/v1/agent-division`, payload);
  }

  updateAgentDivision(payload: AgentDivisionCreatePayload): Observable<AgentDivision> {
    return this.http.put<AgentDivision>(`${USER_DISTRIBUTOR_URL}/v1/agent-division`, payload);
  }

  removeAgentDivision(agentDivisionId: number): Observable<any> {
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/agent-division/${agentDivisionId}`);
  }
}
