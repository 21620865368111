import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProducts from '../../reducers/saleplan/product.reducer';

export const getProductsState = createFeatureSelector<fromProducts.State>('product');

export const getProducts = createSelector(
	getProductsState,
	fromProducts.getProducts
);
export const selectProductLoadingStatus = createSelector(
	getProductsState,
	fromProducts.getProductLoadingStatus
);
export const getProductsWithOrders = createSelector(
	getProductsState,
	fromProducts.getProductsWithOrders
);

export const selectAllProducts = createSelector(
	getProductsState,
	fromProducts.getAllProducts
);
export const selectTopLevelCategories = createSelector(
	getProductsState,
	fromProducts.getTopLevelCategories
);
export const selectCategories = createSelector(
	getProductsState,
	fromProducts.getCategories
);
export const selectManufacturers = createSelector(
	getProductsState,
	fromProducts.getManufacturers
);
export const selectBrands = createSelector(
	getProductsState,
	fromProducts.getBrands
);
export const selectAllProductsTotalCounts = createSelector(
	getProductsState,
	fromProducts.getAllProductsTotalCount
);