import { Action, createReducer, on } from '@ngrx/store';
import { City, Country, Region } from '../../core/models/location';
import * as fromActions from './location.actions';
export const locationFeatureKey = 'location';

export interface State {
  cities: Array<City>;
  regions: Array<Region>;
  countries: Array<Country>;
}

export const initialState: State = {
  cities: [],
  regions: [],
  countries: []
};

export const locationReducer = createReducer(
  initialState,
  on(fromActions.findCountriesSuccess, (state, { data }) =>
  ({
    ...state,
    countries: data,
  })),
  on(fromActions.resetCountries, (state) =>
  ({
    ...state,
    countries: [],
  })),
  on(fromActions.findRegionsSuccess, (state, { data }) =>
  ({
    ...state,
    regions: data,
  })),
  on(fromActions.resetRegions, (state) =>
  ({
    ...state,
    regions: [],
  })),
  on(fromActions.findCitiesSuccess, (state, { data }) =>
  ({
    ...state,
    cities: data,
  })),
  on(fromActions.resetCities, (state) =>
  ({
    ...state,
    cities: [],
  })),
);

export function reducer(state: State, action: Action): any {
  return locationReducer(state, action);
}
export const getCountries = (state: State): Country[] => state.countries;
export const getRegions = (state: State): Region[] => state.regions;
export const getCities = (state: State): City[] => state.cities;
