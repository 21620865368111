import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'iql-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {
  @Input('src') src: string;
  @Input('name') name: string;
  @Input('surname') surname: string;
  constructor() { }

}
