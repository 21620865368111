import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/task/task-progress.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { TasksService } from '@Mesh/core/services/api/task/tasks.service';

@Injectable()
export class TaskProgressEffects {
    constructor(private _actions$: Actions, private tasksService: TasksService) {
    }

    getTaskProgress$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.getTaskProgress),
            switchMap((props) => this.tasksService.getTasksProgress(props.taskId, props.clientIds)),
            map(tasksProgress => fromActions.populateTaskProgressEntity({ tasksProgress }))
        )
    );
}
