import { Injectable, isDevMode } from '@angular/core';
import * as feathersRx from 'feathers-reactive/dist/feathers-reactive';
import * as io from 'socket.io-client';
import { BehaviorSubject, from } from 'rxjs';
import { delay, retryWhen } from 'rxjs/operators';
import feathers from '@feathersjs/client';
import { NODE_URL } from '@Env/environment';
import { IUserData } from '../../models/user';

/**
 * Simple wrapper for feathers
 */
@Injectable({providedIn: 'root'})
export class FeathersService {
  private _feathers; // init socket.io
  private _socket;   // init feathers
  private _currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    this._feathers = feathers();
    this._socket = io(NODE_URL, {transports: ['websocket'], timeout: 5000, forceNew: true});
    this.connect();
  }

  get currentUserSubject(): BehaviorSubject<any> {
    return this._currentUserSubject;
  }

  set currentUserSubject(value: BehaviorSubject<any>) {
    this._currentUserSubject = value;
  }

  connect(): void {
    if (NODE_URL) {
      this._feathers
          .configure(feathers.socketio(this._socket, {timeout: 50000}))  // add socket.io plugin
          .configure(feathers.authentication({         // add authentication plugin
            storageKey: 'local_token',
            scheme: 'Bearer'
          }))
          .configure(feathersRx({                      // add feathers-reactive plugin
            idField: 'id'
          }));
    }
  }

  // expose services
  service(name: string): any {
    return this._feathers.service(name);
  }

  // expose authentication
  authenticate(token: any, user: IUserData): any {
    return from(this._feathers.authenticate({
      strategy: 'custom',
      accessToken: token,
      userId: user.id
    })).pipe(
        retryWhen(errors =>
            errors.pipe(
                delay(1000)
            )
        )
    ).subscribe((data: any) => {
      if (data && data.user) {
        this.currentUserSubject.next(data.user);
      }
    });
  }

  // expose logout
  logout(): any {
    // @ts-ignore
    if (isDevMode()) {
      return this._feathers.logout();
    }
  }
}
