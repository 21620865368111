import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'iql-received-read',
  templateUrl: './received-read.component.html',
  styleUrls: ['./received-read.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceivedReadComponent {
  @Input() received: boolean;
  @Input() read: boolean;
  @Input() white: boolean;
  constructor() { }

}
