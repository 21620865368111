export enum ErrorType {
    BadRequest = 'Ошибка! Формат отправленных данных отвергнут сервером',
    Unauthorized = 'Ошибка! Недостаточно прав для получения данных',
    NotFound = 'Ошибка! Сервер не нашел данных с указанными параметрами',
    Internal = 'Внутреняя ошибка сервера! Пожалуйста обратитесь к администратору',
    Unknown = 'Неизвестная ошибка! Пожалуйста обратитесь к администратору'
}

export function GetErrorType(error: any): ErrorType {
    const errType = error.status;
    if (!errType) {
        return;
    }
    switch (errType) {
        case 400: return ErrorType.BadRequest;
        case 402: return ErrorType.Unauthorized;
        case 404: return ErrorType.NotFound;
        case 500: return ErrorType.Internal;
        default: return ErrorType.Unknown;
    }
}
