import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'iql-chat-search-input',
  templateUrl: './chat-search-input.component.html',
  styleUrls: ['./chat-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatSearchInputComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;
  @Output() search = new EventEmitter<{ value: string }>();
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  u$ = new Subject;

  private _searchQuery = '';

  constructor(private cdr: ChangeDetectorRef) {
  }

  get searchQuery(): string {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    this._searchQuery = value;
    this.search$.next(value);
  }

  ngOnDestroy(): void {
    this.u$.next();
    this.u$.unsubscribe();
  }

  ngOnInit(): void {
    this.search$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        skip(1),
        takeUntil(this.u$)
    ).subscribe(value => {
      this.search.emit({value});
      this.cdr.detectChanges();
    });
  }

}
