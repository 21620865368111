import {Component, ChangeDetectionStrategy, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'iql-coefficient-item',
  templateUrl: './coefficient-item.component.html',
  styleUrls: ['./coefficient-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoefficientItemComponent {
  @Input()
  multiplier: number;

  @Input()
  percentageStep: number;

  @Input()
  hideRemoveIcon: boolean;

  @Output()
  public outputData: EventEmitter<{ multiplier: number; percentageStep: number }> = new EventEmitter<{ multiplier: number; percentageStep: number }>();

  @Output()
  public delete: EventEmitter<void> = new EventEmitter<void>();
}
