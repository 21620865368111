import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UsersService } from '../../core/services/api/users/users.service';
import * as userActions from './users.actions';

@Injectable()
export class UserEffects {

    findAllUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.findAllUsers),
            switchMap(({ searchUserParams }) =>
                this.userService.findAllUsers(searchUserParams).pipe(
                    map((users) => userActions.findAllUsersSuccess({ users: users.content })),
                    catchError((error) => of(userActions.findAllUsersFail({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private userService: UsersService
    ) { }
}
