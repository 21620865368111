import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'iql-task-status',
    templateUrl: './task-status.component.html',
    styleUrls: ['./task-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskStatusComponent {
    @Input() row: any;

    constructor() {
    }
}
