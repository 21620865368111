import { PipeTransform, Pipe } from '@angular/core';
import { AuthService } from '@Mesh/core/services/api/auth/auth.service';

@Pipe({name: 'token'})
export class TokenPipe implements PipeTransform {
  constructor(private authService: AuthService) {
  }

  transform(url: string): string {
    url += (url.split('?')[1] ? '&' : '?') + 'token=' + this.authService.accessToken;
    return url;
  }
}
