import { Notification, NotificationRequest, NotificationsData, NotificationToken, NotificationTokenRequest } from '@Mesh/core/models/notification';
import { createAction, props } from '@ngrx/store';

export const updatePushToken = createAction('[Notification] update token', props<{ params: NotificationTokenRequest }>());
export const updatePushTokenSuccess = createAction('[Notification] update token success', props<{ pushToken: NotificationToken }>());
export const loadNotifications = createAction('[Notification] get notifications', props<{ params: NotificationRequest }>());
export const loadNotificationsSuccess = createAction('[Notification] get notifications success', props<{ notifications: NotificationsData }>());
export const readNotification = createAction('[Notification] read notification', props<{ id: number }>());
export const readNotificationSuccess = createAction('[Notification] read notification success', props<{ notification: Notification }>());
export const goToNotification = createAction('[Notification] go to notification', props<{ notification: Notification }>());
export const goToNotificationSuccess = createAction('[Notification] go to notification success', props<{}>());
