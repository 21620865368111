import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { IPageableList } from '../../../models/pageable';
import { IUser, UsersSearchParams } from '../../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private readonly http: HttpClient) { }

  findAllUsers(searchUserParams: Partial<UsersSearchParams>): Observable<IPageableList<IUser>> {
    const params = objectToParams(searchUserParams);
    return this.http.get<IPageableList<IUser>>(`${USER_URL}/user`, { params });
  }
}
