import { createAction, props } from '@ngrx/store';
import { PlanConfig, PlanConfigItem } from '@Mesh/core/models/plan';

export const loadPlanConfigs= createAction('[Plan] Load plan configs');
export const planConfigsLoaded = createAction('[Plan] Plan configs loaded', props<{planConfigs: PlanConfig[]}>());

export const createPlanConfig = createAction('[Plan] Create plan configs', props<PlanConfig>());
export const planConfigCreated = createAction('[Plan] Plan configs created', props<{planConfig: PlanConfig}>());
export const planConfigCreateError = createAction('[Plan] Plan config create error');

export const deletePlanConfig = createAction('[Plan] Delete plan configs', props<{planId: number}>());
export const planConfigDeleted = createAction('[Plan] Plan configs deleted', props<{planId: number}>());
export const planConfigDeleteError = createAction('[Plan] Plan config delete error');

export const updatePlanConfig = createAction('[Plan] Update plan config', props<PlanConfig>());
export const planConfigUpdated = createAction('[Plan] Plan config updated', props<{planConfig: PlanConfig}>());
export const planConfigUpdateError = createAction('[Plan] Plan config update error');

export const createPlanConfigItems = createAction('[Plan] Create plan config items', props<{items: PlanConfigItem[]}>());
export const planConfigItemsCreated = createAction('[Plan] Plan config items created', props<{items: PlanConfigItem[]}>());
export const planConfigItemsCreateError = createAction('[Plan] Plan config item create error');