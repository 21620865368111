import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import moment from 'moment';


@Directive({
  selector: '[isActiveMonth]'
})
export class IsActiveMonthDirective implements OnInit {
  @Input('currentMonth') date: string;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.setBgColor();
  }

  setBgColor(): void {
    const thisMonth = moment().endOf('month');
    const targetMonth = moment(this.date).startOf('month');
    if (moment(thisMonth).isBefore(targetMonth)) {
      this.el.nativeElement.style.opacity = 0.5;
      this.el.nativeElement.style.cursor = 'default';
    }
  }
}
