import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { getClientsOutletsLookup, getClientsOutletsLookupByParam, populateClientsOutletsLookupEntity } from '@Mesh/store/actions/deprecated/clients-outlets-lookup.actions';
import { populateOutletsClientsLookupEntity } from '@Mesh/store/actions/deprecated/outlets-clients-lookup.actions';
import { IClientsLookup, IOutletsLookup } from '@Mesh/core/models/lookup';
import { AddressService } from '@Mesh/core/services/api/saleplan/address.service';

@Injectable()
export class ClientsOutletsLookupEffects {
    constructor(private _actions$: Actions, private addressService: AddressService) {
    }

    getClientsLookup$ = createEffect(
        () => this._actions$.pipe(
            ofType(getClientsOutletsLookup),
            switchMap(({ clientId }) => this.addressService.getClientsOutletsLookup(clientId)),
            switchMap(clientsOutletsLookup => this.populateStore(clientsOutletsLookup))
        )
    );

    getClientsLookupByParan$ = createEffect(
        () => this._actions$.pipe(
            ofType(getClientsOutletsLookupByParam),
            switchMap(({ clientIds }) => this.addressService.getClientsOutletsLookupByClientIds(clientIds)),
            switchMap(clientsOutletsLookup => this.populateStore(clientsOutletsLookup))
        )
    );

    private populateStore(clientsOutletsLookup: any): any[] {

      const clientsLookup: IClientsLookup[] = [];
      const outletsLookup: IOutletsLookup[] = [];
      for (let i = 0; i < clientsOutletsLookup.length; i++) {
        const e = clientsOutletsLookup[i];

        let client = clientsLookup.find(c => c.id === e.clientId);
        if (!client) {
          client = { id: e.clientId, name: e.clientId === 200016763 ? 'Фора ООО' : e.nameClient || 'Нет имени', outletIds: [] };
          clientsLookup.push(client);
        }
        client.outletIds.push(e.addressId);

        let outlet = outletsLookup.find(o => o.id === e.addressId);
        if (!outlet) {
          outlet = { id: e.addressId, name: `${e.street} ${e.house}`, clientId: e.clientId, segmentName: e.segmentName };
          outletsLookup.push(outlet);
        }
      }
      return [populateClientsOutletsLookupEntity({ clientsLookup }), populateOutletsClientsLookupEntity({ outletsLookup })];
    }
}
