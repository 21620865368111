export enum LoadingStatus {
    NotLoaded = 'Not Loaded',
    Loading = 'Loading',
    Loaded = 'Loaded',
    Error = 'Error',
    Complete = 'Complete'
}
export enum PostingStatus {
    Initial = 'Initial',
    Posting = 'Posting',
    Posted = 'Posted',
    Error = 'Error'
}

export class Loadable<T> {
    constructor( readonly value: T, readonly loadingStatus: LoadingStatus, readonly query?: any) {
    }
}

export namespace Loading {
    export class NotLoaded<T> {
        readonly loadingStatus = LoadingStatus.NotLoaded;
    }

    export class BeingLoaded<T> {
        readonly loadingStatus = LoadingStatus.Loading;
    }

    export class Errored<T> {
        readonly loadingStatus = LoadingStatus.Error;
    }

    export class Loaded<T> {
        readonly loadingStatus = LoadingStatus.Loaded;
    }

    export class Completed<T> {
        readonly loadingStatus = LoadingStatus.Complete;
    }
}

export default Loading;

export function generateToSet(arr: any[], prop: string): any[] {
    return arr.filter((item, pos, _arr) =>
      // tslint:disable-next-line:triple-equals
      _arr.findIndex(item2 => item[prop] == item2[prop]) == pos);
}
