import {createAction, props} from '@ngrx/store';
import {IAutoOrder} from '@Mesh/pages/auto-orders/create-auto-order-types';
import {IAutoOrderQueryParams} from '@Mesh/core/models/query-params';
import {IResponse} from '@Mesh/core/models/IResponse';
import {BonusConfiguration} from '@Mesh/core/models/bonus/bonus-configuration';

export const getAutoOrderList = createAction('[Auto Order] getAutoOrderList', props<{ params: IAutoOrderQueryParams }>());
export const getMotivationPrograms = createAction('[Auto Order] getAutoOrderList');
export const getMotivationProgramsSuccess = createAction('[Auto Order] getMotivationProgramsSuccess', props<{ motivationPrograms: BonusConfiguration[] }>());
export const toggleLoading = createAction('[Auto Order] toggleLoading', props<{ loading: boolean }>());
export const getAutoOrdersSuccess = createAction('[Auto Order] getAutoOrdersSuccess', props<{ autoOrders: IResponse<IAutoOrder[]> }>());
export const createAutoOrdersSuccess = createAction('[Auto Order] createAutoOrdersSuccess');
export const createAutoOrder = createAction('[Auto Order] createAutoOrder', props<{ autoOrder: IAutoOrder }>());
export const getAutoOrder = createAction('[Auto Order] getAutoOrder', props<{ id: number }>());
export const deleteAutoOrder = createAction('[Auto Order] deleteAutoOrder', props<{ id: number }>());
export const getAutoOrderSuccess = createAction('[Auto Order] getAutoOrderSuccess', props<{ autoOrder: IAutoOrder }>());
export const reset = createAction('[Auto Order] Reset');
