import { createAction, props } from '@ngrx/store';

import { CitiesCriteria, City, Region, RegionCriteria } from '@Mesh/core/models/location';
import { Plan } from '@Mesh/core/models/brand';
import { CategoryMain } from '@Mesh/core/models/tree';

export const loadApplicationSettings = createAction('[Application settings] load application settings');

export const loadCategories = createAction('[Application settings] load Categories tree');
export const categoriesLoaded = createAction('[Application settings] Categories tree loaded', props<{ categories: CategoryMain[] }>());

export const loadCategoriesMain = createAction('[Application settings] load main Categories');
export const categoriesMainLoaded = createAction('[Application settings] Categories main loaded', props<{ categories: CategoryMain[] }>());
export const loadPlans = createAction('[Application settings] load plans');
export const plansLoaded = createAction('[Application settings] Plans loaded', props<{ plans: Plan[] }>());
export const loadCities = createAction('[Location] Load cities', props<CitiesCriteria>());
export const citiesLoaded = createAction('[Location] Cities loaded', props<{ cities: City[] }>());
export const loadRegions = createAction('[Location] Load regions', props<RegionCriteria>());
export const regionsLoaded = createAction('[Location] Regions loaded', props<{ regions: Region[] }>());
