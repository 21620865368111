import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

enum Communities {
  'clan' = 'Клан',
  'alliance' = 'Альянс',
  'guild' = 'Гильдия',
  'all' = 'Общий чат',
}

enum CommunitiesRe {
  'clan' = 'клане',
  'alliance' = 'альянсе',
  'guild' = 'гильдие',
  'all' = 'общем чате',
}

@Component({
  selector: 'iql-chat-community',
  templateUrl: './chat-community.component.html',
  styleUrls: ['./chat-community.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatCommunityComponent {

  @Input() community;
  @Input() user;
  @Input() type;

  get name(): string {
    if (!this.type) { return ''; }
    return Communities[this.type];
  }

  get inCommunity(): boolean {
    if (['clan', 'alliance', 'guild'].includes(this.type) && this.user && this.user[this.type]) {
      return this.user[this.type].id;
    }
    return true;
  }

  get namere(): string {
    if (!this.type) { return ''; }
    return CommunitiesRe[this.type];
  }

  get firstMessage(): any {
    return this.community?.messages[0] || {};
  }

  get secondMessage(): any {
    return this.community?.messages[1] || {};
  }

}
