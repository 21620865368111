import { createReducer, Action, on } from '@ngrx/store';
import * as fromActions from "../../actions/saleplan/saleplan.actions";
import { PlanProgressConfig, SalePlan, SalePlanEnable } from '@Mesh/core/models/APImodels/saleplan/saleplans';

export interface State {
    planProgressConfigs: PlanProgressConfig[];
    salePlans: SalePlan[];
    salePlansEnableRules: SalePlanEnable[];
}

export const initialState: State = {
    planProgressConfigs: [],
    salePlans: [],
    salePlansEnableRules: [],
};

const saleplanReducer = createReducer(
    initialState,
    on(fromActions.loadSalePlans, (state) => ({ ...state, salePlans: [] })),
    on(fromActions.salePlansLoaded, (state, { salePlans }) => ({ ...state, salePlans })),
    on(fromActions.salePlansLoadError, (state) => ({ ...state, salePlans: [] })),

    on(fromActions.salePlanProgressLoaded, (state, { planProgress }) => {
        const salePlans = state.salePlans.map<SalePlan>((sp: SalePlan) => ({ ...sp, planProgress: planProgress.find(pg => pg.planId === sp.plan_id)}))
        return {...state, salePlans}
    }),

    on(fromActions.loadSalePlanProgressConfigs, (state) => ({ ...state, planProgressConfigs: [] })),
    on(fromActions.salePlanProgressConfigsLoaded, (state, { planProgressConfigs }) => ({ ...state, planProgressConfigs })),

    on(fromActions.loadSalePlansEnableRules, (state) => ({ ...state, planProgressConfigs: [] })),
    on(fromActions.salePlansEnableRulesLoaded, (state, { salePlansEnableRules }) => ({ ...state, salePlansEnableRules }))
);

export function reducer(state: State | undefined, action: Action) {
    return saleplanReducer(state, action);
}


export const getSalePlanPlans = (state: State) => state.salePlans;
export const getSalePlanEnableRules = (state: State) => state.salePlansEnableRules;
export const getPlanProgressConfigs = (state: State) => state.planProgressConfigs;
