import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { Comment } from '@Mesh/core/models/comment';
import { User } from '@Mesh/core/models/user';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ChatUserInfo } from '@Mesh/core/models/chat-user-info';
import { FORWARDED_MESSAGE } from '../chat-dialog.component';
import { Gallery, ImageItem } from '@ngx-gallery/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModuleTypes } from '@Mesh/shared/modules/chat/chat-dialog/chat-dialog.service';
import { TokenPipe } from '@Mesh/shared/modules/token/token.pipe';
import { IStockInfo } from '@Mesh/core/models/task';
import { IMAGES_URL, SALEPLAN_IMAGES_URL } from '@Env/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'iql-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  FORWARDED_MESSAGE = FORWARDED_MESSAGE;
  private _message: Comment;
  @Input() mine: boolean;
  @Input() isEditing: boolean;
  private _stocks: IStockInfo;
  imagesUrl = IMAGES_URL;
  saleplanImgUrl = SALEPLAN_IMAGES_URL;
  @ViewChild(ContextMenuComponent, {static: false}) contextMenu: ContextMenuComponent;

  images;
  videos = [];
  otherFiles;
  imagesQuote;
  videosQuote;
  imagesLoadedCount = 0;
  recognizedCigarettes$: Observable<any>;
  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() forward = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() quote = new EventEmitter();
  @Output() quoteClick = new EventEmitter();
  @Output() imagesLoaded = new EventEmitter();
  @Output() loaded = new EventEmitter();
  @Output() approve = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() ticket = new EventEmitter();

  @ViewChild('basicMenu', {static: false}) otherMenu: ContextMenuComponent;
  @ViewChild('triggerMenu', {static: false}) triggerMenu: ElementRef<HTMLElement>;
  @ViewChild('element', {static: false}) element: ElementRef<HTMLElement>;
  @ViewChild('video', {static: false}) video: ElementRef<HTMLVideoElement>;
  galleryId;
  materialId = [];

  constructor(public chatService: ChatService,
              private cdr: ChangeDetectorRef,
              private contextMenuService: ContextMenuService,
              public domSanitizer: DomSanitizer,
              public tokenSafePipe: TokenPipe,
              private zone: NgZone,
              public gallery: Gallery ) {
  }

  get edited(): Date {
    const {editedAt} = this._message;
    return editedAt;
  }

  approveTask(): void {
    this.approve.emit(this.message);
  }

  cancelTask(): void {
    this.cancel.emit(this.message);
  }

  closeTicket(): void {
    this.ticket.emit(this.message);
  }

  get message(): Comment {
    return this._message;
  }

  get stocks(): IStockInfo {
    return this._stocks;
  }

  ngAfterViewInit(): void {
    this.loaded.emit(this.message);
    //console.log(this.images);
    this.imagesLoaded.emit(this.message.id);

    this.zone.runOutsideAngular(() => {
      if (this.video && this.video.nativeElement) {
        this.imageLoaded();
        this.video.nativeElement.src = '' + this.video.nativeElement.src;
      }
      if (this.message && this.images) {
        const lightboxGalleryRef = this.gallery.ref('lightbox' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.images.map(item =>
            new ImageItem({src: this.tokenSafePipe.transform(item.original), thumb: this.tokenSafePipe.transform(item.url)})
        );

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(items);
      }
      if (this.message && this.imagesQuote) {
        const lightboxGalleryRef = this.gallery.ref('lightbox-qoute' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const items = this.imagesQuote.map(item =>
            new ImageItem({src: this.tokenSafePipe.transform(item.original), thumb: this.tokenSafePipe.transform(item.url)})
        );

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(items);
      }
      if (this.message?.taskStep?.imageUrl) {
        const lightboxGalleryRef = this.gallery.ref('lightbox-task-step' + this.message.id);

        // (Optional) Set custom gallery config to this lightbox
        lightboxGalleryRef.setConfig({
          thumb: false,
        });

        const url = this.tokenSafePipe.transform(this.message?.taskStep?.imageUrl);

        const items = [
          new ImageItem({src: url, thumb: url})
        ];

        // 3. Load the items into the lightbox
        lightboxGalleryRef.load(items);
      }
    });
  }

  get imagesCount(): number {
    return (this.images ? this.images.length : 0) + (this.imagesQuote ? this.imagesQuote.length : 0) + (this.videos ? this.videos.length : 0);
  }

  imageLoaded(): void {
    this.imagesLoadedCount++;
    if (this.imagesLoadedCount === this.imagesCount) {
      this.imagesLoaded.emit(this.message.id);
    }
  }

  get empty(): boolean {
    return this.message?.attached?.uploads?.length === 0 && (this.message.text === '' || this.message.text === FORWARDED_MESSAGE || this.message.text === '[Пересланное сообщение]') && this.message?.attached?.messages?.length === 0;
  }

  ngAfterViewChecked(): void {
    //console.log('parent - 4');
  }

  @Input()
  set message(value: Comment) {
    this._message = value;

    if (this._message.taskStep) {
      if (this._message.answer === 'image-media' &&
      (this._message.taskStep.type.name === 'PHOTO_MONITORING' ||
        (
        this._message.taskStep.type.name === 'SEND_PHOTO' &&
        this._message.taskStep.additionalType === 'CALCULATE_REST_OF_PRODUCT'
        )
      ) && value.taskStep.stepSuccess.recognitionResult && value.taskStep.stepSuccess.recognitionResult.images) {
        value.taskStep.stepSuccess.recognitionResult.images.forEach(image => {
            image.imagePositions.forEach(coord => this.materialId.push(coord.materialId));
        });
        this.chatService.materialId = this.materialId;
        this.chatService.addressId = this._message.addressId;
        this.getProducts(this.materialId);
        this.chatService.recognitionResult(this._message.taskStep.stepSuccess.id).subscribe(data => {
          this._message.taskStep.stepSuccess.recognitionResult.id = data.images[0].id;
        });
      }
      this.chatService.recommendedOrderConfigId = this._message.taskStep.recommendedOrderConfigId;
      this.chatService.stepId = this._message.taskStep.id;
    }

    const imageExts = ['svg', 'jpeg', 'jpg', 'png', 'gif'];
    const videoExts = ['avi', 'mp4', 'webm', 'mov', 'ogg', '3gp', 'wmv', 'qt'];
    const audioExts = ['weba', 'wav', 'pgg'];
    this.images = this._message?.attached?.uploads?.filter(item => [...imageExts].indexOf(item.ext) !== -1);
    // if (this.images.length > 0) {
    //   this.chatService.cigarettesShowcaseImg = this.images[0].url;
    // }
    //console.log('value.id:', this._message.attached?.messages[0]?.message?.attached?.uploads);
    this.imagesQuote = this._message.attached?.messages[0]?.message?.attached?.uploads?.filter(item => [...imageExts].indexOf(item.ext) !== -1);
    this.videosQuote = this._message.attached?.messages[0]?.message?.attached?.uploads?.filter(item => [...videoExts].indexOf(item.ext) !== -1);
    this.videos = this._message?.attached?.uploads?.filter(item => [...videoExts].indexOf(item.ext) !== -1);
    this.otherFiles = this._message?.attached?.uploads?.filter(item => [...videoExts, ...imageExts, ...audioExts].indexOf(item.ext) === -1);

    this.cdr.detectChanges();
  }

  @Input()
  set stocks(value: IStockInfo) {
    this._stocks = value;
    this.cdr.detectChanges();
  }
  getProducts(materialIds: number[]): void {
    this.recognizedCigarettes$  = this.chatService.recognizedProducts(materialIds, this._message.addressId);
  }

  editMessage(): void {
    this.edit.emit(this._message);
  }

  removeMessage(): void {
    this.remove.emit(this._message);
  }

  forwardMessage(): void {
    this.forward.emit(this._message);
  }

  copyMessage(): void {
    if (this._message.text) {
      const inp = document.createElement('input');
      document.body.appendChild(inp);
      inp.value = this._message.text;
      inp.select();
      document.execCommand('copy', false);
      inp.remove();
    }
  }

  quoteMessage(): void {
    this.quote.emit(this._message);
  }

  showContextMenu($event: MouseEvent | any, right: boolean): void {
    //console.log('press');
    const event = {...$event};
    const el: HTMLElement = this.element.nativeElement;
    const clientRect = el.getBoundingClientRect();
    let posx = $event.pageX, posy = $event.pageY;
    if ($event.changedTouches) {
      posx = $event.changedTouches[0].pageX;
      posy = $event.changedTouches[0].pageY;
    }
    if ($event.targetTouches) {
      posx = $event.targetTouches[0].pageX;
      posy = $event.targetTouches[0].pageY;
    }
    //console.log('menu:', $event);
    event.clientX = posx;
    event.clientY = posy;
    this.contextMenuService.show.next({
      contextMenu: this.otherMenu,
      event: event,
      item: $event.target
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  ngOnInit(): void {
    this.imagesLoadedCount = 0;
  }

  openMenu($event: any): void {
    const el: HTMLElement = this.triggerMenu.nativeElement;
    //console.log($event.clientX);
  }

  get audios(): any {
    return this._message?.attached?.uploads?.reverse().filter(item => ['weba', 'wav'].indexOf(item.ext) !== -1);
  }

  get files(): any {
    return this._message?.attached?.uploads?.reverse().filter(item => ['svg', 'jpeg', 'jpg', 'png', 'gif', 'webm', 'weba'].indexOf(item.ext) === -1);
  }

  get hasContent(): boolean {
    return (this.message.text && this.message.text !== '' && this.message.text !== FORWARDED_MESSAGE && this.message.text !== '[Пересланное сообщение]') || (this.files && this.files.length > 0) || (this.audios && this.audios.length > 0);
  }

  get oneMark(): boolean {
    const {readAt, createdAt} = this._message;
    return !createdAt || !readAt;
  }

  get twoMarks(): boolean {
    const {readAt, createdAt} = this._message;
    return !!(createdAt && readAt);
  }


  getName(user: User | ChatUserInfo): string {
    return `${user.surname ? user.surname : ''} ${user.surname ? user.name?.charAt(0).toUpperCase() : user.name}`;
  }

  get isMessageFromChat(): boolean {
    /*if (this.message.attached?.messages?.length > 0) {
      for (const message of this.message.attached.messages) {
        if (message?.message?.user.id !== this.message.replyUser?.id &&
          message?.message?.user.id !== this.message.user?.id
        ) {
          return false;
        }
      }
    }*/
    return true;
  }

  get stepIndex(): number {
    return this.message?.task?.step?.findIndex(step => this.message?.taskStep?.id === step.id) + 1;
  }

  get isTaskMessage(): boolean {
    return this.message.type === 'task-comments';
  }

  get isModuleMessage(): boolean {
    return ModuleTypes.indexOf(this.message.type) !== -1;
  }

  toggleLike(): void {
    if (this._message.liked) {
      this._message.liked = false;
      this._message.likes--;
    } else {
      this._message.liked = true;
      this._message.likes++;
    }
    this.cdr.detectChanges();
    this.chatService.toggleLike(this._message.type, this._message.typeId, this._message.id);
  }

  acceptResult(): void {
    this.chatService.acceptResult().subscribe(data => {
    });
  }
  cancelResult(): void {
    this.chatService.cancelResult().subscribe(data => {
    });
  }
}
