import { createAction, props } from '@ngrx/store';
import { DocumentsData } from '@Mesh/core/models/document';
import { DocRequestParams } from '@Mesh/core/services/api/document/document.service';

enum DocActionsEnum {
  loadDocuments = '[Document] load documents',
  loadDocumentsSuccess = '[Document] load documents success',
  loadDocumentsError = '[Document] load documents error',
}

const loadDocuments = createAction(DocActionsEnum.loadDocuments, props<DocRequestParams>());
const loadDocumentsSuccess = createAction(DocActionsEnum.loadDocumentsSuccess, props<{ data: DocumentsData }>());
const loadDocumentsError = createAction(DocActionsEnum.loadDocumentsError);


export const docAction = {
  loadDocuments,
  loadDocumentsSuccess,
  loadDocumentsError,
};

