export enum OutletActionsType {
    TIME_PRIORITY = 'TIME_PRIORITY',
    VISIT_WITH_TIME = 'VISIT_WITH_TIME',
    VISIT_COMPLETED = 'VISIT_COMPLETED',
    VISIT_WITHOUT_TIME = 'VISIT_WITHOUT_TIME',
    CONTACT_OUTLET = 'CONTACT_OUTLET',
    VISIT_ONLINE = 'VISIT_ONLINE',
    VISIT_ONLINE_WITH_TIME = 'VISIT_ONLINE_WITH_TIME',
    VISIT_STARTED = 'VISIT_STARTED',
    VISIT_CANCELED = 'VISIT_CANCELED',
    VISIT_MISSED = 'VISIT_MISSED',
    VISIT_COMPLETED_PARTIALLY = 'VISIT_COMPLETED_PARTIALLY',
    VISIT_STARTED_NOT_FINISHED = 'VISIT_STARTED_NOT_FINISHED'
}

export const OutletActionsTypeMap = {
    [OutletActionsType.TIME_PRIORITY]: {
        type: OutletActionsType.TIME_PRIORITY,
        icon: 'info_icon.svg',
        color: '#0066FF',
        label: 'Приоритет по времени'
    },
    [OutletActionsType.CONTACT_OUTLET]: {
        type: OutletActionsType.CONTACT_OUTLET,
        icon: 'phone_icon.svg',
        color: '#48DBFB',
        label: 'Связаться с ТТ'
    },
    [OutletActionsType.VISIT_WITH_TIME]: {
        type: OutletActionsType.VISIT_WITH_TIME,
        icon: 'timer_icon.svg',
        color: '#00D0B3',
        label: 'Визит со временем'
    },
    [OutletActionsType.VISIT_WITHOUT_TIME]: {
        type: OutletActionsType.VISIT_WITHOUT_TIME,
        icon: 'no_time_icon.svg',
        color: '#8E44AD',
        label: 'Визит без времени'
    },
    [OutletActionsType.VISIT_ONLINE]: {
        type: OutletActionsType.VISIT_ONLINE,
        icon: 'market_icon.svg',
        color: '#FF9F43',
        label: 'Онлайн визит без времени'
    },
    [OutletActionsType.VISIT_ONLINE_WITH_TIME]: {
        type: OutletActionsType.VISIT_ONLINE_WITH_TIME,
        icon: 'market_time_icon.svg',
        color: '#FF9F43',
        label: 'Онлайн визит со временем'
    },
    [OutletActionsType.VISIT_STARTED]: {
        type: OutletActionsType.VISIT_STARTED,
        icon: 'not_over_icon.svg',
        color: '#2ECC71',
        label: 'Визит не закончен'
    },
    [OutletActionsType.VISIT_CANCELED]: {
        type: OutletActionsType.VISIT_CANCELED,
        icon: 'canceled_icon.svg',
        color: '#FFD952',
        label: 'Визит отменен'
    },
    [OutletActionsType.VISIT_STARTED_NOT_FINISHED]: {
        type: OutletActionsType.VISIT_STARTED_NOT_FINISHED,
        icon: 'not_over_icon.svg',
        color: '#0066FF',
        label: 'Визит не закончен'
    },
    [OutletActionsType.VISIT_COMPLETED]: {
        type: OutletActionsType.VISIT_COMPLETED,
        icon: 'success_icon.svg',
        color: '#2ECC71',
        label: 'Визит завершен'
    },
    [OutletActionsType.VISIT_COMPLETED_PARTIALLY]: {
        type: OutletActionsType.VISIT_COMPLETED_PARTIALLY,
        icon: 'partial_success_icon.svg',
        color: '#009432',
        label: 'Визит завершен частично'
    },
    [OutletActionsType.VISIT_MISSED]: {
        type: OutletActionsType.VISIT_MISSED,
        icon: 'exclude_icon.svg',
        color: '#FF5254',
        label: 'Визит пропущен'
    },
    default: {
        type: 'unknown',
        icon: 'info_icon.svg',
        color: '#0066FF',
        label: 'Неизветсный тип'
    },
};
