import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL, USER_URL } from '@Env/environment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IClientsOutletsResponse } from '@Mesh/core/models/lookup';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  readonly connectionString = `${USER_URL}/address`;

  constructor(private readonly http: HttpClient) {
  }

  getClientsOutletsLookup(clientId: number): Observable<IClientsOutletsResponse[]> {
    const options: any = {
      size: 9999,
      clientId,
    };
    return this.http.get(`${this.connectionString}`, { params: <HttpParams>options }).pipe(map((result: any) => {
      return result.content.map(o => {
        return {
          clientId: o.clientId,
          addressId: o.addressId,
          street: o.streetName,
          house: o.house,
          segmentName: o.segmentName
        };
      });
    }));
  }

  getClientsOutletsLookupByClientIds(clientIds: number[]): Observable<IClientsOutletsResponse[]> {
    const obs = [];
    clientIds.forEach(id => obs.push(this.http.get(`${this.connectionString}?clientId=${id}`)));
    return forkJoin(obs).pipe(
      map((ress: any[]) => {
        const arr = [];
        ress.forEach(res => {
          res.content.forEach(o => {
            arr.push({
              clientId: o.clientId,
              addressId: o.addressId,
              street: o.street,
              house: o.house,
              segmentName: o.segmentName
            });
          });
        });
        return arr;
      }
      ));
  }

  getClientsOutletLookup(addressId: number): Observable<IClientsOutletsResponse> {
    return this.http.get<IClientsOutletsResponse>(`${this.connectionString}/${addressId}`);
  }
}
