import { NotificationsService } from '@Mesh/core/services/api/notification/notifications.service';
import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/notification/notification.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pluck, switchMap } from 'rxjs/operators';

@Injectable()
export class NotificationEffects {
  constructor(private actions: Actions, private notificationService: NotificationsService) {
  }

  updateNotificationToken$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.updatePushToken),
      pluck('params'),
      switchMap(params => this.notificationService.updateToken(params)),
      switchMap(pushToken => [fromActions.updatePushTokenSuccess({ pushToken })])
    )
  );

  getNotifications$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.loadNotifications),
      pluck('params'),
      switchMap(params => this.notificationService.getNotifications(params)),
      switchMap(notifications => [fromActions.loadNotificationsSuccess({ notifications })])
    )
  );

  goToNotification$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.goToNotification),
      pluck('notification'),
      switchMap(notification => this.notificationService.goToNotification(notification)),
      switchMap(() => [fromActions.goToNotificationSuccess({})])
    )
  );

  readNotification$ = createEffect(
    () => this.actions.pipe(
      ofType(fromActions.readNotification),
      pluck('id'),
      switchMap(id => this.notificationService.readNotification(id)),
      switchMap(notification => [fromActions.readNotificationSuccess({ notification })])
    )
  );
}
