import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'iql-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnChanges {

  @Input() list: any[];
  @Input() idKey: string;
  @Input() idActiveKey: string;
  @Input() label: string;
  @Output() activeElementChanged = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idActiveKey) {
      const element = changes.idActiveKey.currentValue;
      if (element) {
      } else {
        //clearSelect();
      }
    }
  }

}
