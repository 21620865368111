import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RandomGradientDirective } from './random-gradient.directive';



@NgModule({
  declarations: [RandomGradientDirective],
  imports: [
    CommonModule
  ],
  exports: [RandomGradientDirective]
})
export class RandomGradientModule { }
