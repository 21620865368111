import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { PgSelectComponent } from '@Mesh/@pages/components/select/select.component';

@Component({
  selector: 'iql-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnChanges {
  @Input() selectedItemIds: string | number | string[] | number[];
  @Input() options: any[];
  @Input() width: number;
  @Input() label: string;
  @Input() isExtra: boolean;
  @Input() idKey = 'id';
  @Input() labelKey: string | string[] = 'name';
  @Input() labelKeys: string[];
  @Input() placeholder = 'Выберите значение';
  @Input() isMultiSelect = false;
  @Input() isInvalid = false;
  @Input() disabled = false;
  @Input() showSearch = false;
  @Output() selectedItemIdChanged = new EventEmitter<string | number>();
  @Output() scrollToBottom = new EventEmitter<boolean>();
  @Output() onSearchChange = new EventEmitter<string>();

  @ViewChild('select', { static: false }) select: PgSelectComponent;

  onScrollToBottom(event: boolean): void {
    this.scrollToBottom.emit(event);
  }

  searchChange(event: string): void {
    this.onSearchChange.emit(event);
  }

  onSelectedItemIdChange(value: string): void {
    this.selectedItemIdChanged.emit(value);
  }

  clearSelect(): void {
    this.select.clearSelect();
  }

  ngOnChanges(): void {
    if (!this.options?.length && this.selectedItemIds === null) {
      this.select?.clearSelect();
    }
  }
}
