export enum TableColumnType {
    text = 'text',
    textIn = 'textIn',
    textPair = 'textPair',
    multiText = 'multiText',
    checkBox = 'checkBox',
    counter = 'counter',
    planProgress = 'plan-progress',
    valueChangeable= 'value-changeable',
    textImage = 'text-image',
    textImageKey = 'text-image-key',
    textLink = 'text-link',
    textCardImage = 'text-card-image',
    textList = 'text-list',
    arrayTextLink = 'array-text-link',
    plan = 'plan',
    planDouble = 'plan-double',
    date = 'date',
    status = 'status',
    progress = 'progress',
    targetDate = 'target-date',
    buttons = 'buttons',
    editButton = 'editButton',
    textButton = 'textButton',
    catalogButton = 'catalogButton',
    catalogButtons = 'catalogButtons',
    taskProgressImg = 'taskProgressImg',
    plansButtons = 'plansButtons',
    innerButtons = 'innerButtons',
    countDown = 'countDown',
    download = 'download',
    activeTasks = 'activeTasks',
    history = 'history',
    outletCount = 'outletCount',
    expand = 'expand',
    toggle = 'toggle',
    textImageToggle = 'textImageToggle',
    statusIcon = 'statusIcon',
    basketAndCount = 'basketAndCount',
    buttonSubmit = 'buttonSubmit',
    permissionButtons = 'permissionButtons',
    phone = 'phone',
    textWithSubtext = 'textWithSubtext',
    actionButton = 'actionButton',
    employeeButtons = 'employeeButtons',
    previewAndDownload = 'previewAndDownloadButtons',
    bonus = 'bonus',
    bonusMaterial = 'bonusMaterial',
    userBonusConfig = 'userBonusConfig',
    bonusExternal = 'bonusExternal',
    createEditBonusButton = 'createEditBonusButton',
    viewDetails = 'viewDetails',
    runStatus = 'runStatus',
    files = 'files'
}

export interface TableColumn {
    type: TableColumnType;
    key?: string;
    headerTemplate?: any;
    subKey?: string;
    label?: string;
    value?: any;
    sortable?: boolean;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    class?: string;
    subClass?: string;
    numbered?: boolean;
    wrapClass?: string;
    headerClass?: string;
    infoToggleIconClass?: string;
    subName?: string;
    img?: string;
    localImg?: boolean;
    primary?: boolean;
    link?: string;
    in?: object;
    cloud?: boolean;
    multiText?: string[];
    progressLevelKey?: string;
    onClick?: () => void;
    textDetails?: boolean;
    textDetailsLink?: string;
}
