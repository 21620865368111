import { Deserializable } from '@Mesh/core/models/deserializable';
import { Permission } from '../../pages/employees/types/permission.type';
import { Client } from './client';

interface AdditionsType {
  id: number | null;
  name: string | null;
}

export class User implements Deserializable {
  id?: number;
  clientId?: number;
  role?: string;
  username?: string;
  status?: any;
  type?: string;
  name: string;
  surname: string;
  avatar?: string;
  division?: AdditionsType;
  position?: AdditionsType;
  client?: Client;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get short_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name.charAt(0));
    }
    if (this.surname) {
      result.push(this.surname.charAt(0));
    }
    return result.length ? result.join(' ') : '';
  }

  get full_name(): string {
    const result = [];
    if (this.name) {
      result.push(this.name);
    }
    if (this.surname) {
      result.push(this.surname);
    }
    return result.length ? result.join(' ') : '';
  }
}

export interface IUserInfo {
  accessTokenExpires: number;
  refreshTokenExpires: number;
  accessToken: string;
  refreshToken: string;
  user: IUserData;
}

export interface IUserData {
  id: number;
  name: string;
  phone: string;
  status: string;
  type: string;
  username: string;
  avatar: string;
  email: string;
  possibleClientId: number;
  possibleEmail: string;
  possiblePhone: string;
  position: string;
  client: string;
  permissions: string[];
  addresses: string;
}

export interface UsersSearchParams {
  clientId: number;
  addressId: number[];
  userIds: number[];
  schoolExternalIds: string[];
  namePattern: string;
  phonePattern: string;
  extendedFormat: boolean;
  withPermissions: boolean;
  status: string[];
  type: string[];
  shouldMaskOwnerPhone: boolean;
  size: number;
  page: number;
  sort: string;
  direction: string;
}

export interface IUser {
  birthday: string;
  username: string;
  avatar: string;
  email: string;
  client: Client;
  permissions: Permission[];
  addresses: any;
  id: number;
  name: string;
  surname: string;
  phone: string;
  status: string;
  type: string;
  position: string;
  login: string;
  sex: string;
  schoolExternalId: string;
}

export enum UserStatusType {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  INACTIVE = 'INACTIVE'
}

