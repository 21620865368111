import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@Env/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable()
export class LanguageService {
    private translate: TranslateService;

    constructor(translate: TranslateService) {
        this.translate = translate;
    }

    setup(language: string = 'ru'): void {
        const languages = environment.translation.supportedLanguages;
        this.translate.addLangs(languages);

        this.translate.setDefaultLang(environment.translation.languages[environment.translation.default]);

        if (languages.includes(language)) {
            this.translate.use(environment.translation.languages[language]);
        }
    }
}

const httpLoaderFactory = (http: HttpClient) => {
    return new TranslateHttpLoader(http, '', '');
};

export const translateLoaderProvider = {
    provide: TranslateLoader,
    useFactory: httpLoaderFactory,
    deps: [HttpClient]
};
