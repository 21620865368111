import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DataService } from '../services/api/deprecated/data.service';

@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {
    constructor(private readonly dataService: DataService) {
    }

    transform(url: string): Observable<string> {
        return new Observable<string>((observer) => {
            // This is a tiny blank image
            observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
            // The next and error callbacks from the observer
            const { next, error } = observer;

            const sub = this.dataService.getImage(url).subscribe(response => {
                const reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onloadend = function () {
                    observer.next(reader.result as string);
                };
                sub.unsubscribe();
            });
            return {unsubscribe() {}};
        });
    }
}

