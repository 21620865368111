import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ChatService } from '@Mesh/shared/modules/chat/chat.service';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { SALEPLAN_IMAGES_URL } from '@Env/environment';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { Product } from '@Mesh/core/models/product';

@Component({
    selector: 'iql-product-recognition',
    templateUrl: './product-recognition.component.html',
    styleUrls: ['./product-recognition.component.scss'],
    animations: [
        trigger('productRecognitionLeft', [
            state(
                'open',
                style({
                    opacity: '*',
                    right: '46vw',
                })
            ),
            state(
                'closed',
                style({
                    opacity: 0,
                    right: '0',
                })
            ),
            transition('open => closed', [animate('0.7s')]),
            transition('closed => open', [animate('0.5s')]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRecognitionComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    recognizeToggle = false;
    cigarettes = [];
    allCigarettes = [];
    recognizedCigarettes = [];
    unrecognizedCigarettes = [];
    activeItem;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    cropperInfo;
    indicated = [];
    ratioW = 1;
    ratioH = 1;
    aspectRatio: number = 1;
    h;
    w;
    r;
    @ViewChild('cigarettesShowcaseImg', { static: false })
    cigarettesShowcaseImg: any;

    @ViewChild('backgroundImage', { static: false })
    backgroundImage: ElementRef;

    @ViewChild('imageCropper', { read: ElementRef, static: false }) imageCropper: ElementRef<HTMLElement>;

    previewWidth = 650;
    previewHeight = window.innerHeight;
    acceptedPositions = [];
    IMAGES_URL = SALEPLAN_IMAGES_URL;
    isProductSelected = false;
    selectedProduct;
    isLoading = false;
    destroy$ = new Subject();
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    private dataSub$: Subscription;
    sourceImageUrl: string;
    brandIcons = {
        PMI: 'pmi.svg',
        JTI: 'jti.svg',
        ITG: 'itg.svg',
        DT: 'dt.svg',
    };

    constructor(
        public chatService: ChatService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.chatService.recognizedProducts().subscribe((data) => {
            this.allCigarettes = data.content;
            this.cigarettes = [...this.allCigarettes];
        });
        this.chatService
            .recognizedProducts(
                this.chatService.materialId,
                this.chatService.addressId
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.recognizedCigarettes = data.content;
            });
    }

    getImageUrl(item: any) {
        var imageUrl = item.productInfo?.imageUrl || item.url;
        if (imageUrl) {
            return this.IMAGES_URL + '/300/' + imageUrl;
        }
    }

    getBrandImageUrl(event, item: any) {
        (event.target as HTMLImageElement).src =
            '/assets/img/plans/' +
            (this.brandIcons[item.manufacturerNameId]
                ? this.brandIcons[item.manufacturerNameId]
                : 'top_marki.svg');
    }

    ngAfterViewInit(): void {
        if (this.searchInput && this.searchInput.nativeElement) {
            this.dataSub$ = fromEvent(this.searchInput.nativeElement, 'input')
                .pipe(
                    map(
                        (event: KeyboardEvent) =>
                            (event.target as HTMLInputElement).value
                    )
                )
                .subscribe((value) => this.onSearch(value));
        }
        // if(this.imageCropper?.nativeElement && this.backgroundImage?.nativeElement) {
        //     this.imageCropper.nativeElement.style.height = this.backgroundImage.nativeElement.style.height + 'px';
        //     this.imageCropper.nativeElement.style.width = this.backgroundImage.nativeElement.style.width  + 'px';
        // }
    }

    onSearch(value) {
        if (value) {
            this.cigarettes = this.allCigarettes.filter(
                (cigarette) =>
                    cigarette.materialName
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    (cigarette.manufacturerName &&
                        cigarette.manufacturerName.toLowerCase() ==
                            value.toLowerCase())
            );
        } else {
            this.cigarettes = [...this.allCigarettes];
        }
        this.cdr.detectChanges();
    }

    recognize(item: string): void {
        this.isProductSelected = true;
        this.recognizeToggle = true;
        this.activeItem = item;
        this.selectedProduct = item;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
        this.cropperInfo = event;
        console.log(this.cropperInfo);
    }

    resizeCropper(event): void {
        this.ratioW = this.imageCropper.nativeElement.offsetWidth / this.backgroundImage.nativeElement.offsetWidth;
    }

    calcRatio(val: any): void {
        this.ratioW = val.w / this.previewWidth;
        this.ratioH = val.h / this.previewHeight;
    }

    donePosition(): void {
        this.recognizeToggle = false;
        this.isProductSelected = false;
        this.indicated.push(this.activeItem.id);
        this.recognizedCigarettes.push(this.selectedProduct);
        this.cigarettes = this.cigarettes.filter(
            (cigarette) => cigarette.id !== this.selectedProduct.id
        );
    }

    acceptPosition(): void {
        this.isLoading = true;

        const coordinates = {
            image: this.activeItem.url,
            xmin: this.cropperInfo.imagePosition.x1,
            ymin: this.cropperInfo.imagePosition.y1,
            xmax: this.cropperInfo.imagePosition.x2,
            ymax: this.cropperInfo.imagePosition.y2,
            materialId: this.activeItem.materialId,
            name: this.activeItem.materialName,
        };

        this.chatService.acceptRecognition(coordinates).subscribe((data) => {
            this.isLoading = false;
            this.calcAcceptedPositionSize();
        });
    }

    calcAcceptedPositionSize(): void {
        this.acceptedPositions.push({
            name: this.activeItem.materialName,
            width:
                this.cropperInfo.cropperPosition.x2  -
                this.cropperInfo.cropperPosition.x1 ,
            height:
                this.cropperInfo.cropperPosition.y2  -
                this.cropperInfo.cropperPosition.y1 ,
            top: this.cropperInfo.cropperPosition.y1+ 4,
            left: this.cropperInfo.cropperPosition.x1 + 4,
        });
    }

    positionMissing(): void {
        this.recognizeToggle = false;
        this.isProductSelected = false;
        this.cigarettes = this.cigarettes.filter(
            (cigarette) => cigarette.id !== this.selectedProduct.id
        );
        this.unrecognizedCigarettes.push(this.selectedProduct);
    }

    zoomOut(): void {
        // this.scale -= 0.1;
        // this.transform = {
        //   ...this.transform,
        //   scale: this.scale,
        // };
    }

    zoomIn(): void {
        // this.scale += 0.1;
        // this.transform = {
        //   ...this.transform,
        //   scale: this.scale,
        // };
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
