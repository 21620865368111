import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any): number {
    return this.localeString(value);
  }

  missingOneDecimalCheck(nStr) {
    nStr += '';
    const x = nStr;
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += '';
    const x = nStr;
    if (!x) return true;
    return false;
  }

  localeString(nStr) {
    if (nStr === '' || nStr === '0') return '';
    let x, x1, rgx, y1, y2;
    nStr += '';
    x = Math.ceil(nStr);
    x1 = String(x);
    rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }

    if (x1.indexOf(',') !== -1) {
      y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

      y2 = x1;
      x = y2[0];
    } else {
      x = x1;
      if (this.missingAllDecimalsCheck(x)) return (x += '');
    }

    return x;
  }
}
