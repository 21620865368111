import { Pipe, PipeTransform } from '@angular/core';
import { transformPhone } from '@Mesh/shared/helpers/phone-helpers';

const transformedPhoneLength = 18;

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phone: string, hide: boolean = false): string {
    return transformPhone(phone);
  }

}
