import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSalePlan from '../../reducers/saleplan/saleplan.reducers';

export const getPlansState = createFeatureSelector<fromSalePlan.State>('saleplan');

export const selectSalePlans = createSelector(
	getPlansState,
	fromSalePlan.getSalePlanPlans
);
export const selectSalePlansEnableRules = createSelector(
	getPlansState,
	fromSalePlan.getSalePlanEnableRules
);
export const selectPlanConfigs = createSelector(
	getPlansState,
	fromSalePlan.getPlanProgressConfigs
);