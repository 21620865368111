import { BonusConfigCategory, BonusConfiguration } from '../../../models/bonus/bonus-configuration';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SALEPLAN_URL } from '@Env/environment';
import { Observable, of } from 'rxjs';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';
import { BonusClient } from '@Mesh/core/models/bonus/bonus-client';
import { BonusOutlets } from '@Mesh/core/models/bonus/bonus-outlets';
import { BonusConfigurationRuleRequest } from '@Mesh/core/models/bonus/bonus-configuration-rule-request';
import { GoalIdType, INewGoal } from '../../../../pages/goals/components/create-goals/createGoalTypes';

@Injectable({
  providedIn: 'root'
})
export class BonusService {
  readonly connectionString = `${SALEPLAN_URL}/v1/bonus`;

  constructor(private readonly http: HttpClient) {
  }

  getBonus({ addressId, planIds, date }: { addressId: number, planIds: number[], date?: string }): Observable<Bonus[]> {
    if (!addressId) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('addressId', `${addressId}`);
    params = params.append('showOffPlan', 'true');
    if (date) {
      params = params.append('bonusDate', `${date}`);
    }
    if (planIds) {
      planIds.forEach(p => params = params.append('planIds', `${p}`));
    }
    return this.http.get<Bonus[]>(`${this.connectionString}`, { params });
  }

  getBonusConfiguration({ addressId, planId, strategy }: { addressId?: number[], planId?: number[], strategy?: string }): Observable<BonusConfiguration[]> {

    let params = new HttpParams();
    if (addressId) {
      addressId.forEach(a => params = params.append('addressId', `${a}`));
    }
    if (planId) {
      planId.forEach(p => params = params.append('planId', `${p}`));
    }
    if (strategy) {
      params = params.append('strategy', `${strategy}`);
    }
    return this.http.get<BonusConfiguration[]>(`${this.connectionString}/configuration`, { params });
  }

  getBonusConfigurationById(bonusConfigId: number): Observable<BonusConfiguration> {
    return this.http.get<BonusConfiguration>(`${this.connectionString}/configuration/${bonusConfigId}`);
  }


  getBonusConfigCategory(): Observable<BonusConfigCategory[]> {
      return of(
        [
          {
            id: null,
            name: 'Все'
          },
          {
            id: 1,
            name: 'Табак'
          },
          {
            id: 2,
            name: 'RRP'
          },
          {
            id: 3,
            name: 'Напитки'
          },
          {
            id: 4,
            name: 'Другое'
          },
        ] as BonusConfigCategory[]
      );
  }

  getPredictBonus({ addressId, planIds }: { addressId: number, planIds: number[], date?: string }): Observable<Bonus[]> {
    if (!addressId) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('showOffPlan', 'true');
    params = params.append('addressId', `${addressId}`);

    if (planIds) {
      planIds.forEach(p => params = params.append('planIds', `${p}`));
    }
    return this.http.get<Bonus[]>(`${this.connectionString}/predict`, { params });
  }

  getPredictBonusClient(clientId: number): Observable<BonusClient> {
    const params = new HttpParams().append('clientId', `${clientId}`);
    return this.http.get<BonusClient>(`${this.connectionString}/client/predict`, { params });
  }

  getBonusOutlets(addressId: number): Observable<BonusOutlets[]> {
    const params = new HttpParams().append('addressId', `${addressId}`);
    return this.http.get<BonusOutlets[]>(`${this.connectionString}`, { params });
  }

  getPredictBonusOutlets(addressId: number): Observable<BonusOutlets[]> {
    const params = new HttpParams().append('addressId', `${addressId}`);
    return this.http.get<BonusOutlets[]>(`${this.connectionString}/predict`, { params });
  }

  toggleBonusConfigurationRule(request: BonusConfigurationRuleRequest[]): Observable<any> {
    return this.http.post<any>(`${this.connectionString}/configuration/rules`, request);
  }

  createBonusConfigurationByType(strategy: GoalIdType, data: INewGoal): Observable<BonusConfiguration> {
    return this.http.post<BonusConfiguration>(`${this.connectionString}/configuration/${strategy}`, data);
  }
}
