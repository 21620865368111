import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';
import { select } from '@ngrx/store';
import { getApplicationSettingsLoadingStatus, getCategoriesValue } from '@Mesh/store/selectors/startup/application-settings.selectors';
import { loadStepStatuses } from '@Mesh/store/actions/task/tasks.actions';
import { selectSelectedOutletId } from '@Mesh/store/selectors/deprecated/outlets.selectors';
import { getCurrentTask } from '@Mesh/store/selectors/task/tasks.selectors';
import { ActivatedRoute, Params } from '@angular/router';
import { getTaskProgress } from '@Mesh/store/actions/task/task-progress.actions';

@Component({
  selector: 'iql-condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CondensedComponent extends RootLayout implements OnInit {
  applicationSettingsState$ = this.store.pipe(select(getApplicationSettingsLoadingStatus));
  selectSelectedOutletId$ = this.store.pipe(select(selectSelectedOutletId));
  getCurrentTask$ = this.store.pipe(select(getCurrentTask));
  menuLinks: any[];

  selectedOutletId;
  currentTask;

  private getParams(route: ActivatedRoute): Params {
    let params = route.snapshot.params;
    params = { ...route.snapshot.queryParams, ...params };
    if (route.children) {
      for (const r of route.children) {
        params = { ...this.getParams(r), ...params };
      }
    }
    return params;
  }

  getMenuLinks(catalogList?: any): any {
    const links = [
      {
        label: 'Цели',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'goals',
        submenu: [
          {
            label: 'Каталог',
            routerLink: '/goals/list',
            iconType: 'svg',
            iconName: 'list'
          },
          {
            label: 'Конфигурации целей',
            routerLink: '/goals/configurations',
            iconType: 'svg',
            iconName: 'settings'
          },
          {
            label: 'Создание цели',
            routerLink: '/goals/create',
            iconType: 'svg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Контракты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'contracts',
        submenu: [
          {
            label: 'Список контрактов',
            routerLink: '/contracts/list',
            iconType: 'svg',
            iconName: 'list'
          },
          {
            label: 'Создание контракта',
            routerLink: '/contracts/create',
            iconType: 'svg',
            iconName: 'plus'
          },
          {
            label: 'Заявки на контракты',
            routerLink: '/contracts/requests',
            iconType: 'svg',
            iconName: 'contracts-reguest'
          },
          {
            label: 'Шаблоны контракта',
            routerLink: '/contracts/templates',
            iconType: 'svg',
            iconName: 'settings'
          },
        ]
      },
      {
        label: 'Маршруты и визиты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'pg',
        iconName: 'routes',
        submenu: [
          {
            label: 'Назначение маршрутов',
            routerLink: '/routes-and-visits/route-assignment',
            iconType: 'svg',
            iconName: 'route_assignment'
          },
          {
            label: 'Массовые активности',
            routerLink: '/routes-and-visits/mass-activities',
            iconType: 'svg',
            iconName: 'mass_activities'
          },
          {
            label: 'Отчетность визитов',
            routerLink: '/routes-and-visits/visit-reporting',
            iconType: 'svg',
            iconName: 'visit_reporting'
          },
          {
            label: 'Назначение маршрутов NEW',
            routerLink: '/routes-and-visits-v2/route-assignment',
            iconType: 'svg',
            iconName: 'route_assignment'
          },
        ]
      },
      {
        label: 'Поставщики',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'vendors',
        submenu: [
          {
            label: 'Список поставщиков',
            routerLink: '/vendors',
            iconType: 'svg',
            iconName: 'settings'
          },
          {
            label: 'Создание поставщика',
            routerLink: '/vendors/create',
            iconType: 'svg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Дистрибьюторы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'distributor',
        submenu: [
          {
            label: 'Список дистрибьюторов',
            routerLink: '/distributors/list',
            iconType: 'svg',
            iconName: 'list'
          },
          {
            label: 'Создание дистрибьютора',
            routerLink: '/distributors/create',
            iconType: 'svg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Клиенты',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'client',
        submenu: [
          {
            label: 'Клиенты',
            routerLink: '/clients',
            iconType: 'svg',
            iconName: 'client',
          },
          {
            label: 'Торговые точки',
            routerLink: '/clients/outlets',
            iconType: 'svg',
            iconName: 'outlet',
          },
          {
            label: 'Территории',
            routerLink: '/territories',
            iconType: 'svg',
            iconName: 'territories'
          },
          {
            label: 'Заявки на добавление',
            routerLink: '/clients/request',
            iconType: 'svg',
            iconName: 'list'
          },
        ]
      },
      {
        label: 'Пользователи',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'users',
        submenu: [
          {
            label: 'Пользователи',
            routerLink: '/users/list',
            iconType: 'svg',
            iconName: 'users'
          },
          {
            label: 'Должности',
            routerLink: '/users/positions',
            iconType: 'svg',
            iconName: 'position-icon'
          },
          {
            label: 'Подразделения',
            routerLink: '/users/subdivisions',
            iconType: 'svg',
            iconName: 'subdivision-icon'
          }
        ]
      },
      {
        label: 'Задания',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tasks',
        submenu: [
          {
            label: 'Активные задания',
            routerLink: '/tasks',
            iconType: 'svg',
            iconName: 'active-tasks'
          },
          {
            label: 'Конфигурации заданий',
            routerLink: '/tasks/configurations-tasks',
            iconType: 'svg',
            iconName: 'settings'
          },
          {
            label: 'Конфигурации шагов',
            routerLink: '/tasks/configurations-steps',
            iconType: 'svg',
            iconName: 'settings'
          },
          {
            label: 'Создание задания',
            routerLink: '/tasks/create',
            iconType: 'svg',
            iconName: 'plus'
          },
        ]
      },
      {
        label: 'Информация о компании',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'company-info',
        submenu: [
          {
            label: 'Личные данные',
            routerLink: '/company-information/company-info',
            iconType: 'svg',
            iconName: 'personal-data'
          },
          {
            label: 'Каталог товаров',
            routerLink: '/company-information/product-catalog',
            iconType: 'svg',
            iconName: 'product-categories'
          },
          {
            label: 'Прайс-листы',
            routerLink: '/company-information/price-lists',
            iconType: 'svg',
            iconName: 'price-lists'
          },
          {
            label: 'Склады',
            routerLink: '/company-information/warehouses',
            iconType: 'svg',
            iconName: 'warehouses'
          },
        ]
      },
      // {
      //   label: 'Авто-заказы',
      //   toggle: 'close',
      //   mToggle: 'close',
      //   iconType: 'svg',
      //   iconName: 'auto-orders',
      //   submenu: [
      //     {
      //       label: 'Конфигурации авто-заказов',
      //       routerLink: '/auto-orders/configurations',
      //       iconType: 'svg',
      //       iconName: 'settings'
      //     },
      //     {
      //       label: 'Создание авто-заказов',
      //       routerLink: '/auto-orders/create',
      //       iconType: 'svg',
      //       iconName: 'plus'
      //     },
      //   ]
      // },
      {
        label: 'Заказы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'orders',
        submenu: [
          {
            label: 'Активные заказы',
            routerLink: '/orders',
            iconType: 'svg',
            iconName: 'list'
          },
          {
            label: 'История заказов',
            routerLink: '/orders/history',
            iconType: 'svg',
            iconName: 'plus'
          },
          {
            label: 'Конфигурации авто-заказов',
            routerLink: '/auto-orders/configurations',
            iconType: 'svg',
            iconName: 'settings'
          },
          {
            label: 'Создание авто-заказов',
            routerLink: '/auto-orders/create',
            iconType: 'svg',
            iconName: 'plus'
          },
        ]
      },
      // {
      //   label: 'Мин. сумма заказа',
      //   toggle: 'close',
      //   mToggle: 'close',
      //   iconType: 'svg',
      //   iconName: 'order-price',
      //   submenu: [
      //     {
      //       label: 'Правила мин. суммы заказа',
      //       routerLink: '/min-order-amount/configurations',
      //       iconType: 'svg',
      //       iconName: 'settings'
      //     },
      //     {
      //       label: 'Создание мин. суммы заказа',
      //       routerLink: '/min-order-amount/create',
      //       iconType: 'svg',
      //       iconName: 'plus'
      //     },
      //   ]
      // },
      {
        label: 'Оборудование',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'equipment',
        submenu: [
          {
            label: 'Категории',
            routerLink: '/equipment/category',
            iconType: 'svg',
            iconName: 'list'
          },
          {
            label: 'Оборудование',
            routerLink: '/equipment',
            iconType: 'svg',
            iconName: 'equipment'
          },
          {
            label: 'Оборудование на торговых точках',
            routerLink: '/equipment/list-outlets',
            iconType: 'svg',
            iconName: 'sale-equipment'
          },
          {
            label: 'Заявки по работам с оборудованием',
            routerLink: '/equipment/request',
            iconType: 'svg',
            iconName: 'request-equipment'
          },
          {
            label: 'Проверка оборудования',
            routerLink: '/equipment/check',
            iconType: 'svg',
            iconName: 'check-equipment'
          },
        ]
      },
      {
        label: 'ТМЦ материалы',
        toggle: 'close',
        mToggle: 'close',
        iconType: 'svg',
        iconName: 'tmc-material',
        submenu: [
          {
            label: 'Каталог ТМЦ',
            routerLink: '/tmc-materials/catalog',
            iconType: 'svg',
            iconName: 'list'
          },
          {
            label: 'Перемещения ТМЦ',
            routerLink: '/tmc-materials/transfer',
            iconType: 'svg',
            iconName: 'arrow'
          },
        ]
      },
      {
        label: 'Конфигурации полей',
        routerLink: '/custom-fields',
        iconType: 'svg',
        iconName: 'settings'
      },
      {
        label: 'Статистика',
        routerLink: '/statistics',
        iconType: 'svg',
        iconName: 'statistics'
      },

      // {
      //   label: 'Производители',
      //   toggle: 'close',
      //   mToggle: 'close',
      //   iconType: 'pg',
      //   iconName: 'manufacturer',
      //   submenu: [
      //     {
      //       label: 'Конфигурация производителей',
      //       routerLink: '/manufacturers/configurations',
      //       iconType: 'pg',
      //       iconName: 'settings'
      //     },
      //     {
      //       label: 'Добавление производителя',
      //       routerLink: '/manufacturers/create',
      //       iconType: 'pg',
      //       iconName: 'plus'
      //     },
      //   ]
      // },

      // {
      //   label: 'Клиенты',
      //   routerLink: '/clients',
      //   iconType: 'pg',
      //   iconName: 'people'
      // },
      // {
      //   label: 'Активные Заказы',
      //   routerLink: '/clients/1/outlets/1/orders',
      //   iconType: 'pg',
      //   iconName: 'history'
      // },

    ];
    return links;
  }

  ngOnInit(): void {
    this.menuLinks = this.getMenuLinks();
    this.selectSelectedOutletId$.subscribe((selectedOutletId) => {
      this.selectedOutletId = selectedOutletId;
    });
    this.getCurrentTask$.subscribe((currentTask) => {
      this.currentTask = currentTask;
    });
    this.feathersService.service('tasks').on('shouldUpdate', ({ taskOutletClientId, clientId, id }) => {
      const params = this.getParams(this.route);
      if (+params.taskOutletClientId === taskOutletClientId && this.currentTask && this.currentTask.id === id) {
        this.store.dispatch(getTaskProgress({ clientIds: [clientId], taskId: id }));
      }
    });
    this.feathersService.service('task-steps').on('shouldUpdate', ({ taskOutletClientId, clientId, id }) => {
      const params = this.getParams(this.route);
      if (this.selectedOutletId === taskOutletClientId && +params.clientId === clientId) {
        this.store.dispatch(loadStepStatuses({ taskOutletClientId, stepId: [id] }));
      }
    });
    // this.store.select(getCategoriesValue).subscribe(categories => {
    //   const productsSubmenu = categories.map(category => ({
    //     label: category.categoryName,
    //     routerLink: `/catalog`,
    //     queryParams: { parId: category.categoryNameId },
    //     submenu: category.categories.map(subCategory => ({
    //       label: subCategory.categoryName,
    //       routerLink: `/catalog`,
    //       queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId },
    //       submenu: subCategory.manufacturers.map(manufacturer => ({
    //         label: manufacturer.manufacturerName,
    //         routerLink: `/catalog`,
    //         queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId, manId: manufacturer.manufacturerNameId },
    //         submenu: manufacturer.brands.map(brand => ({
    //           label: brand.brandName,
    //           routerLink: `/catalog`,
    //           queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId, manId: manufacturer.manufacturerNameId, brandId: brand.brandNameId },
    //           submenu: brand.brandFamilies.map(brandFamily => ({
    //             label: brandFamily.brandFamilyName,
    //             routerLink: `/catalog`,
    //             queryParams: { parId: category.categoryNameId, catId: subCategory.categoryNameId, manId: manufacturer.manufacturerNameId, brandId: brand.brandNameId, brandFId: brandFamily.brandFamilyNameId },
    //           }))
    //         }))
    //       }))
    //     }))
    //   }));
    //   this.menuLinks = this.getMenuLinks(productsSubmenu);
    // });
  }
}

