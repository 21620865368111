import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit } from '@angular/core';
import { PlanProgressConfig } from '@Mesh/core/models/APImodels/saleplan/saleplans';
import { ProductsData, PlanProgress, Order } from '@Mesh/core/models/product';
import { AuthService } from '@Mesh/core/services/api/auth/auth.service';
import { IMAGE_URL, IMAGES_URL } from '@Env/environment';
import { Category } from '@Mesh/core/models/category';
import { Progress } from '@Mesh/core/models/progress';
import { ITableType } from '../models/table';
import { Brand } from '@Mesh/core/models/brand';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'iql-table-v2-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnChanges, AfterViewInit, OnDestroy {
  readonly url = IMAGE_URL;
  readonly basePath = IMAGES_URL;

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  @Input() tableType: ITableType;
  @Input() titleName: string;
  @Input() orders: Order[];
  @Input() isHistoryEnabled = false;
  @Input() planProgress: PlanProgress = {} as PlanProgress;
  @Input() plan: PlanProgressConfig;
  @Input() brand: Brand;
  @Input() products: ProductsData;
  @Input() brandId: number;
  @Input() progress: Progress;
  @Input() categories: Category[];
  @Input() categoryId: number;
  @Input() changings: [];
  @Input() links: {path: string, params: any, name: string}[] = [];
  @Input() isOutletExist: boolean;
  @Input() placeholderText: string;
  @Input() isPlanEnabled: boolean;
  @Input() oldPlans: boolean;
  @Input() plans: PlanProgressConfig[];

  @Output() onButtonClick = new EventEmitter();
  @Output() onBasketEvent = new EventEmitter();
  @Output() onDateChange = new EventEmitter();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onTypeTask = new EventEmitter<'all' | 'visits' | 'tutorials'>();
  @Output() onTypeStep = new EventEmitter<'all' | 'monitoring' | 'scrap' | 'form' | 'tutorial' | 'free'>();

  TableType = ITableType;
  categoryName: string;
  basketCount = 0;
  price = 0;
  company = null;
  date = new Date();
  currentMonth = this.date.getMonth();

  isPreviewMode = false;

  typeTask: 'all' | 'visits' | 'tutorials' = 'all';
  typeStep: 'all' | 'monitoring' | 'scrap' | 'form' | 'tutorial' | 'free' = 'all';

  private dataSub$: Subscription;

  constructor(private readonly auth: AuthService) {
    this.isPreviewMode = auth.isPreviewMode;
  }

  ngAfterViewInit(): void {
    if (this.searchInput && this.searchInput.nativeElement) {
      this.dataSub$ = fromEvent(this.searchInput.nativeElement, 'input')
        .pipe(
          debounceTime(500),
          map((event: KeyboardEvent) => (event.target as HTMLInputElement).value)
        ).subscribe(value => this.onSearch.emit(value));
    }
  }

  setTypeTask(type: 'all' | 'visits' | 'tutorials'): void {
    this.typeTask = type;
    this.onTypeTask.emit(type);
  }

  setTypeStep(type: 'all' | 'monitoring' | 'scrap' | 'form' | 'tutorial' | 'free'): void {
    this.typeStep = type;
    this.onTypeStep.emit(type);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories && this.tableType === ITableType.products) {
      this.categoryName = this.categories.find(c => c.id === this.categoryId).categoryName;
    }

    if (changes.orders) {
      if  (changes.orders.currentValue && changes.orders.currentValue.length) {
        const orders = changes.orders.currentValue as Order[];
        this.basketCount = orders.reduce((s, e) => s + e.quant, 0);
      }
    }
    if (changes.planProgress) {
      const planProgress: PlanProgress = changes.planProgress.currentValue ? changes.planProgress.currentValue : {} as PlanProgress;
      planProgress.exceedSalePlan = planProgress?.exceedSalePlan;
      planProgress.needToSalePlan = planProgress?.needToSalePlan;
      planProgress.salePlanQuant = planProgress?.salePlanQuant;
    }
    if (changes.plan && changes.plan.currentValue) {}
  }
  onBasketClick(): void {
    this.onBasketEvent.emit();
  }
  onPlanChangeMonth(toNext: boolean): void {
    this.currentMonth += toNext ? 1 : -1;
    this.date.setMonth(this.currentMonth);
    this.date = new Date(this.date);
    this.onDateChange.emit(this.date);
  }

  ngOnDestroy(): void {
    if (this.dataSub$ && !this.dataSub$.closed) {
      this.dataSub$.unsubscribe();
    }
  }
}
