import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { USER_URL } from '@Env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IPageableList } from '../../../models/pageable';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { AgentBusinessDay, AgentBusinessDaysSearchParams, AgentVisit, AgentVisitCreatePayload, AgentVisitRemoveParams, AgentVisitSearchParams } from '../../../models/agent';

@Injectable({
    providedIn: 'root'
})

export class AgentService {
    constructor(private readonly http: HttpClient) { }

    searchAgentVisits(searchAddressParams: Partial<AgentVisitSearchParams>): Observable<IPageableList<AgentVisit>> {
        const params = objectToParams(searchAddressParams);
        return this.http.get<IPageableList<AgentVisit>>(`${USER_URL}/agent-visit-plan`, { params });
    }

    saveAgentVisit(payload: AgentVisitCreatePayload[]): Observable<Array<AgentVisit>> {
        return this.http.post<Array<AgentVisit>>(`${USER_URL}/agent-visit-plan`, payload);
    }

    removeAgentVisits(payload: AgentVisitRemoveParams): Observable<any> {
        const params = objectToParams(payload);
        return this.http.delete<any>(`${USER_URL}/agent-visit-plan`, { params });
    }

    searchAgentBusinessDays(searchBusinessDaysParams: Partial<AgentBusinessDaysSearchParams>): Observable<Array<AgentBusinessDay>> {
        const params = objectToParams(searchBusinessDaysParams);
        return this.http.get<Array<AgentBusinessDay>>(`${USER_URL}/user-schedule`, { params });
    }
}
