import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Outlet, OutletAddressResponse, OutletData, OutletFilters } from '@Mesh/core/models/outlet';
import { Action, createReducer, on } from '@ngrx/store';
import {
  outletListLoaded,
  populateOutletEntity,
  setOutletFilters,
  setSelectedOutlet,
  setSelectedOutlets,
  setSelectedTaskOutletClient,
  findAllOutletsAddressSuccess,
  getAllOutletsAddressSuccess,
  getAllOutletsAddressError,
  resetAllOutletsAddress,
  getAllOutletsAddress,
  resetOutletsAddress,
  resetFilterOutletsAddress,
  getFilterOutletsAddressError,
  getFilterOutletsAddressSuccess,
  getFilterOutletsAddress
} from '@Mesh/store/actions/deprecated/outlets.actions';
import { TaskProgress } from '@Mesh/core/models/task';

export const outletFeatureKey = 'outlets';

export interface State extends EntityState<Outlet> {
  selectedId: number;
  selectTaskOutletClient: TaskProgress;
  selectedOutletIds: number[];
  filters: OutletFilters;
  outletData: OutletData;
  outletsAddress: OutletAddressResponse[];
  allOutletsAddress: OutletAddressResponse[];
  filterOutletsAddress: OutletAddressResponse[];
  loadingAllOutletsAddress: boolean;
  loadingFilterOutletsAddress: boolean;
}

export const adapter: EntityAdapter<Outlet> = createEntityAdapter<Outlet>({
  selectId: outlet => outlet.addressId,
  sortComparer: (o1, o2) => o1.street.localeCompare(o2.street)
});

export const initialState: State = adapter.getInitialState({
  selectedId: undefined,
  selectTaskOutletClient: undefined,
  selectedOutletIds: [],
  filters: {
    new: true,
    moderation: true,
    history: false,
    city: undefined,
    region: undefined,
    outletId: undefined
  },
  outletData: null,
  outletsAddress: [],
  allOutletsAddress: [],
  filterOutletsAddress: [],
  loadingAllOutletsAddress: false,
  loadingFilterOutletsAddress: false,
});

const outletsReducer = createReducer(
  initialState,
  on(populateOutletEntity, (state, { outlets }) => adapter.addAll(outlets, { ...state, selectedId: undefined })),
  on(setSelectedOutlets, (state, { ids }) => {
    return {
      ...state,
      selectedOutletIds: ids
    };
  }),
  on(setSelectedOutlet, (state, { id }) => {
    return {
      ...state,
      selectedId: id
    };
  }),
  on(setSelectedTaskOutletClient, (state, { taskProgress }) => {
    return {
      ...state,
      selectTaskOutletClient: taskProgress
    };
  }),
  on(setOutletFilters, (state, { filters }) => {
    return {
      ...state,
      filters
    };
  }),
  on(outletListLoaded, (state, { outletData }) => {
    return {
      ...state,
      outletData
    };
  }),

  on(findAllOutletsAddressSuccess, (state, { outletsAddress }) => {
    return {
      ...state,
      outletsAddress: [...state.outletsAddress, ...outletsAddress]
    };
  }),

  on(resetOutletsAddress, (state) => {
    return {
      ...state,
      outletsAddress: []
    };
  }),

  on(getAllOutletsAddress, (state) => {
    return {
      ...state,
      loadingAllOutletsAddress: true,
    };
  }),
  on(getAllOutletsAddressSuccess, (state, { outletsAddress }) => {
    return {
      ...state,
      allOutletsAddress: [...state.allOutletsAddress, ...outletsAddress],
      loadingAllOutletsAddress: false,
    };
  }),
  on(getAllOutletsAddressError, (state) => {
    return {
      ...state,
      allOutletsAddress: [...state.allOutletsAddress],
      loadingAllOutletsAddress: false,
    };
  }),
  on(getFilterOutletsAddress, (state) => {
    return {
      ...state,
      loadingFilterOutletsAddress: true,
    };
  }),
  on(getFilterOutletsAddressSuccess, (state, { outletsAddress }) => {
    return {
      ...state,
      filterOutletsAddress: [...state.filterOutletsAddress, ...outletsAddress],
      loadingFilterOutletsAddress: false,
    };
  }),
  on(getFilterOutletsAddressError, (state) => {
    return {
      ...state,
      loadingFilterOutletsAddress: false,
    };
  }),
  on(resetAllOutletsAddress, (state) => {
    return {
      ...state,
      allOutletsAddress: [],
      loadingAllOutletsAddress: false,
    };
  }),
  on(resetFilterOutletsAddress, (state) => {
    return {
      ...state,
      filterOutletsAddress: [],
      loadingFilterOutletsAddress: false,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return outletsReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const _selectOutletIds = selectIds;
export const _selectOutletEntities = selectEntities;
export const _selectAllOutlets = selectAll;

export const getOutletData = (state: State) => state.outletData;
export const getAllOutletsAddressData = (state: State) => state.allOutletsAddress;
export const getFilterOutletsAddressData = (state: State) => state.filterOutletsAddress;
export const getLoadingOutletsAddressData = (state: State) => state.loadingAllOutletsAddress;