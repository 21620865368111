import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/api/auth/auth.service';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {
  constructor(private auth: AuthService) {
  }

  transform(url: string): string {
    return `${url}?token=${this.auth.accessToken}`;
  }
}

