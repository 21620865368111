import { Client } from './client';

export interface Task {
    taskId?: number;
    addressId?: number;
    id: number;
    name: string;
    cost: number;
    active: boolean;
    imageUrl: string;
    dateStart: string;
    dateEnd: string;
    step: TaskStep[];
    dataOfCreation: string;
    comment: string;
    progressStatus?: string;
    clientId?: number;
    progress: number;
    reward: string;
}

export interface TaskClient {
    clientId: number;
    addressId: number;
    status: string;
    cost: number;
    userId: number;
    id: number;
    taskId: number;
    active: boolean;
    name: string;
    imageUrl: string;
    dateTimeStart: Date;
    dateTimeEnd: Date;
    dateOfCreation: Date;
    comment: string;
    step: TaskStep[];
    countStep: number;
    notOverdue: boolean;
    taskStatus: string;
    assignedByUserId: number;
    mentorUserId: number;
    agentVisitId: number;
    assignedOrMentorByOwn: boolean;
}
export interface CriteriaSearchTask {
    id: number;
    active: boolean;
    clientId: number;
    addressId: number;
    dateStart: string;
    dateEnd: string;
    notOverdue: boolean;
    pageNumber: number;
    pageSize: number;
}
export interface TaskData {
    content: Task[];
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        },
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    number: number;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}

export interface ITaskAssignment {
    clientsOutletsToAdd: { clientId: number, addressId: number, segmentName?: string, dateTimeStart?: string, dateTimeEnd?: string }[];
    clientsOutletsToRemove: { clientId: number, addressId: number, segmentName?: string, dateTimeStart?: string, dateTimeEnd?: string }[];
}

export class ITaskOutlet {
    addressId: number;
    clientId?: number;
    userId?: number;
}

export interface TaskOutletAddress {
    id: number;
    taskId: number;
    clientId: number;
    addressId: number;
    userId: number;
    status: string;
    taskCost: number;
    dateTimeStart: Date;
    dateTimeEnd: Date;
}

export interface TaskOutletAssignPayload {
    clientId: number;
    addressId: number;
    userId?: number;
    assignerUserId?: number;
    mentorUserId?: number;
    agentVisitId?: number;
    segmentName?: string;
    dateTimeStart?: string;
    dateTimeEnd?: string;
}

export interface TaskOutletAssign {
    id: number;
    taskId: number;
    clientId: number;
    addressId: number;
    userId: number;
    status: string;
    taskCost: number;
    dateTimeStart: Date;
    dateTimeEnd: Date;
    assignedByUserId: number;
    mentorUserId: number;
}

export enum PermissionType {
    onlyAddress = 'ONLY_ADDRESS',
    onlyAgent = 'ONLY_AGENT',
    addressAndAgent = 'ADDRESS_AND_AGENT'
}

export enum CreateStepTrainingType {
    video = 'VIDEO',
    article = 'ARTICLE',
    exam = 'EXAM',
    book = 'BOOK',
    course = 'COURSE',
    film = 'FILM',
    podcast = 'PODCAST',
    scorm = 'SCORM',
}
export class TaskInfo {
    id?: number;
    name: string;
    description: string;
    cost: number;
    active: boolean;
    imageUrl: string;
    dateStart: string;
    dateEnd: string;
    step: TaskStep[];
    dataOfCreation: string;
    autoAssignment: boolean;
    autoAssignmentType: CreateGoalAutoAssignType;
    autoAssignmentDayCount: number;
    recommendedOrderConfigId?: number;
    permissionType?: string;
    comment: string;
    required: boolean;

    constructor(
        active = false,
        autoAssignment = false,
        autoAssignmentType = null,
        autoAssignmentDayCount = null,
        dateStart = new Date().toISOString(),
        dateEnd = new Date().toISOString(),
        dataOfCreation = new Date().toISOString(),
        description = '',
        step = []
    ) {
        this.id = null;
        this.active = active;
        this.cost = 500;
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
        this.imageUrl = '';
        this.name = '';
        this.step = step;
        this.dataOfCreation = dataOfCreation;
        this.comment = '';
        this.description = description;
        this.autoAssignment = autoAssignment;
        this.autoAssignmentType = autoAssignmentType;
        this.autoAssignmentDayCount = autoAssignmentDayCount;
        this.required = true;
    }
}
export interface RequiredProductTransferList {
    productId: number;
    quantity: number;
}
export class TaskStep {
    id?: number;
    name: string;
    cost: number;
    taskId: number;
    step: number;
    status: string;
    progressStatus: string;
    imageUrls: AttachmentDetails[];
    videoUrls: AttachmentDetails[];
    description: string;
    comment: string;
    question: string;
    reply: string[];
    requiredCountMedia: number;
    minOrderAmount: number;
    type: {
        name: StepType;
    };
    additionalType?: string;
    recommendedOrderConfigId: number;
    needModeration: boolean;
    enableRecommendedOrder: boolean;
    userBonusStrategy: CreateGoalBonusStrategyType;
    userBonusValue: number;
    requiredCorrectPercent: number;
    questions: IQuestion[];
    saveTemplate?: boolean;
    moduleType: CreateStepTrainingType;
    moduleId: number;
    required: boolean;
    requiredQuantity: number;
    requiredProductTransferList: RequiredProductTransferList[];

    constructor(taskId: number = 0) {
        this.id = null;
        this.name = '';
        this.cost = 0;
        this.taskId = taskId;
        this.step = 0;
        this.status = undefined;
        this.imageUrls = [];
        this.videoUrls = [];
        this.description = '';
        this.comment = '';
        this.progressStatus = undefined;
        this.question = undefined;
        this.reply = [];
        this.requiredCountMedia = 1;
        this.type = { name: StepType.sendPhoto };
        this.additionalType = null;
        this.recommendedOrderConfigId = undefined;
        this.needModeration = false;
        this.enableRecommendedOrder = false;
        this.userBonusStrategy = CreateGoalBonusStrategyType.disable;
        this.userBonusValue = null;
        this.questions = [];
        this.saveTemplate = false;
        this.minOrderAmount = 0;
        this.moduleType = null;
        this.moduleId = 0;
        this.required = true;
        this.requiredQuantity = null;
        this.requiredProductTransferList = [];
    }


}

export interface IQuestion {
    type: CreateStepQuestionType;
    question: string;
    image: string;
    possibleAnswers: IPossibleAnswer[];
}

export interface IPossibleAnswer {
    answer: string;
    image: string;
    correct: boolean;
}

export class AttachmentDetails {
    id?: number;
    url: string;
    sort: number;
}

export class TaskStepStatusResponse {
    id: number;
    taskId: number;
    clientId: number;
    addressId: number;
    stepId: number;
    status: string;
    images: string[];
    acceptedImages: string[];
    comment: string;
    answer: boolean;

    updatedAt: Date;
}

export class TaskClientData {
    content: Client[];
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        },
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    last: boolean;
    number: number;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    size: number;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}

export class TaskProgress {
    taskId: number;
    taskOutletClientId: number;
    addressId: number;
    clientId: number;
    name: string;
    stepCount: number;

    expired: boolean;
    preformed: boolean;

    statusCounts: IStatusInfo[];

    lastSettingCheckingOrApprovedStatusAt: string;
}

export interface IStatusInfo {
    status: TaskStatusApi;
    stepCount: number;
}

export interface IStockInfoResponse {
    materialId: number;
    quantity: number;
    registerAt: string;
    addressId: number;
}

export interface IStockInfo {
    planName: string;
    date: string;

    productsInfo: {
        materialId: number;
        imageUrl: string;
        quantity: number;
        unit: string;
    }[];
}

export enum TaskStatusApi {
    new = 'NEW',
    accepted = 'ACCEPTED',
    notAccepted = 'NOT_ACCEPTED',
    checking = 'CHECKING'
}

// export enum TaskStatus {
//     new,
//     notFinished,
//     succeed,
//     failed
// }

export enum StepType {
    sendPhoto = 'SEND_PHOTO',
    giveAnswer = 'GIVE_ARBITRARY_ANSWER',
    selectOption = 'SELECT_OPTION',
    calculateStock = 'CALCULATE_REST_OF_PRODUCT',
    photoMonitoring = 'PHOTO_MONITORING',
    autoRecommendedOrder = 'AUTO_RECOMMENDED_ORDER',
    giveArbitraryAnswer = 'GIVE_ARBITRARY_ANSWER',
    freeForm = 'FREE_FORM',
    questioning = 'QUESTIONING',
    makeOrder = 'MAKE_AN_ORDER',
    training = 'TRAINING',
    locationConfirmation = 'LOCATION_CONFIRMATION',
    tmcTransfer = 'TMC_TRANSFER',
    equipmentCheck = 'EQUIPMENT_CHECK', // Проверка оборудования
    equipmentRequisition = 'EQUIPMENT_REQUISITION' // Заявка по оборудованию
}

export enum CreateGoalAutoAssignType {
    beforeVisit = 'BEFORE_VISIT'
}

export enum CreateGoalBonusStrategyType {
    fixed = 'FIXED',
    dynamic = 'DYNAMIC',
    disable = 'DISABLE',
}


export enum CreateStepQuestionType {
    single = 'SINGLE',
    plural = 'PLURAL',
    text = 'TEXT',
}

export enum AdditionalSendPhotoType {
    photoCalculateRestOfProduct = 'PHOTO_CALCULATE_REST_OF_PRODUCT',
    photoCheck = 'PHOTO_CHECK',
    photoSign = 'PHOTO_SIGN',
    photoAddress = 'PHOTO_ADDRESS',
    photoFacade = 'PHOTO_FACADE',
    photoEquip = 'PHOTO_EQUIP',
}


export enum AdditionalEquipmentRequisitionType {
    EQUIPMENT_INSTALL = 'EQUIPMENT_INSTALL', // (Установка)
    EQUIPMENT_DEINSTALL = 'EQUIPMENT_DEINSTALL', // (Демонтаж)
    EQUIPMENT_MOVE = 'EQUIPMENT_MOVE', // (Перемещение)
}

export interface MetaTradeAgent {
    current_page: number;
    from: number;
    last_page: number;
    links: Array<{ url: string, label: string, active: boolean }>;
    length: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
}

export interface ResponseTradeAgent {
    data: Array<TradeAgent>;
    links: { first: string; last: string; next: string; prev: string; };
    meta: MetaTradeAgent;
}

export interface CriteriaTradeAgent {
    name?: string;
    position_id?: number;
    division_id?: number;
    only_retail?: boolean;
    ids?: Array<number>;
    logins?: Array<string>;
    page?: number;
    per_page?: number;
}

export interface CriteriaClientTask {
    clientId?: number;
    addressId?: number;
    userId?: number;
    notOverdue?: boolean;
    taskStatuses?: string[];
    onlyActual?: boolean;
    assignedOrMentorByOwn?: boolean;
    startDate?: string;
    endDate?: string;
    agentVisitIds?: number[];
    pageNumber: number;
    pageSize: number;
}

export interface CriteriaSearchLearnModule {
    phrase?: string;  //  Поисковая фраза
    tag_id?: any; // Идентификатор тега
    teacher_id?: any; // Идентификатор преподавателя
    status?: string; // Статус Enum: "start" "waiting" "end"
    moduleType: string; // Тип модуля Enum: "article" "book" "video" "scorm" "course" "exam" "podcast" "films"
    complexity?: number;
    page?: number;
}

export interface TradeAgent {
    alliance?: string;
    avatar: string;
    bio?: string;
    birth_place: string;
    birthday_date: string;
    can_upgrade: boolean;
    character?: string;
    characterHide: boolean;
    characterMain: boolean;
    clan?: string;
    cloth_size: string;
    count_tasks: number;
    current_task_level_id: number;
    current_task_level_name: string;
    date_next_grade: string;
    division_id?: number;
    earned_for_month: number;
    email: string;
    emodji: string;
    employment_date: string;
    facebook?: string;
    foot_size: number;
    grade: number;
    guild?: string;
    id: number;
    instagram?: string;
    iqretail_id: number;
    kpi: number;
    last_point?: string;
    login: string;
    must_change_password: boolean;
    name: string;
    next_grade?: string;
    num_tasks: number;
    passed_next_grade: boolean;
    patronymic: string;
    phone: string;
    points?: string;
    position: string;
    position_id: number;
    rank?: string;
    rank_alliance?: string;
    rank_clan?: string;
    rank_guild?: string;
    rank_shop?: string;
    score: number;
    sex: string;
    shop?: string;
    surname: string;
    tasks_done_count: number;
    total: number;
    tour: number;
    until_next_grade: number;
    vkontakte?: string;
}

export interface Division {
    id: number;
    name: string;
}

export interface Position {
    id: number;
    name: string;
}

export interface TaskTemplate {
    id: number;
    name: string;
    body: TaskTemplateBody;
}

export interface StepTemplate {
    id: number;
    name: string;
    taskTemplateId: number;
    body: TaskStep;
    step: number;
}

export interface TaskTemplateBody {
    name: string;
    cost: number;
    active: boolean;
    imageUrl: string;
    dateStart: string;
    dateEnd: string;
    dataOfCreation: string;
    comment: string;
    autoAssignment: boolean;
    autoAssignmentType: any;
    autoAssignmentDayCount: number;
    permissionType: string;
    required: boolean;
}

export interface TaskTemplatePayload {
    name: string;
    body: TaskTemplateBody;
}
export interface StepTemplatePayload {
    name: string;
    taskTemplateId: number;
    body: TaskStep;
}

export interface TaskVisitCreatePayload {
    visitDate: Date;
    taskTemplateId: number;
    userId: number;
    addressId: number;
    permissionType: string;
    timeVisitType: string;
    startVisitTime: { hour: number, minute: number };
    endVisitTime: { hour: number, minute: number };
    comment: string;
    steps: StepTemplate[];
    clientId: number;
    byAddress: boolean;
    visitId: number;
}

export const TraningTypesList = [
    {
        id: CreateStepTrainingType.article,
        name: 'Статьи',
    },
    {
        name: 'Книги',
        id: CreateStepTrainingType.book,
    },
    {
        name: 'Курсы',
        id: CreateStepTrainingType.scorm
    },
    {
        id: CreateStepTrainingType.course,
        name: 'Программы обучения',
    },
    {
        id: CreateStepTrainingType.exam,
        name: 'Тесты',
    },
    {
        id: CreateStepTrainingType.video,
        name: 'Видео',
    },
    {
        id: CreateStepTrainingType.podcast,
        name: 'Подкасты',
    },
];

export const StepTypeMap = {
    [StepType.questioning]: {
        title: 'Анкетирование',
        type: StepType.questioning,
        image: 'assets/img/task-step/questionnaire.png',
        icon: 'assets/img/task-step/icons/anketa-icon.svg',
        color: '#00D0B3',
    },
    [StepType.freeForm]: {
        title: 'Свободная форма',
        type: StepType.freeForm,
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#8E44AD',
    },
    [StepType.calculateStock]: {
        title: 'Остатки',
        type: StepType.calculateStock,
        image: 'assets/img/task-step/leftovers.png',
        icon: 'assets/img/task-step/icons/boxes-icon.svg',
        color: '#FFA84C',
    },
    [StepType.photoMonitoring]: {
        title: 'Фото-мониторинг стеллажей',
        type: StepType.photoMonitoring,
        image: 'assets/img/task-step/photo-monitoring.png',
        icon: 'assets/img/task-step/icons/photo-monitoring-icon.svg',
        color: '#0066FF',
    },
    [StepType.sendPhoto]: {
        title: 'Сделать фото',
        type: StepType.sendPhoto,
        image: 'assets/img/task-step/make-photo.png',
        icon: 'assets/img/task-step/icons/photo-icon.svg',
        color: '#FF6852',
    },
    [StepType.autoRecommendedOrder]: {
        title: 'Авто-заказ',
        type: StepType.autoRecommendedOrder,
        image: 'assets/img/task-step/auto-order.png',
        icon: 'assets/img/task-step/icons/order-icon.svg',
        color: '#2ECC71',
    },
    [StepType.makeOrder]: {
        title: 'Сделать заказ',
        type: StepType.makeOrder,
        image: 'assets/img/task-step/auto-order.png',
        icon: 'assets/img/task-step/icons/order-icon.svg',
        color: '#2ECC71',
    },
    [StepType.training]: {
        title: 'Обучение',
        type: StepType.training,
        image: 'assets/img/task-step/questionnaire.png',
        icon: 'assets/img/task-step/icons/education-icon.svg',
        color: '#00E0FF',
    },
    [StepType.locationConfirmation]: {
        title: 'Подтверждение местоположения',
        type: StepType.locationConfirmation,
        image: 'assets/img/task-step/location-confirmation.png',
        icon: 'assets/img/task-step/icons/photo-icon.svg',
        color: '#0ABC31',
    },
    [StepType.tmcTransfer]: {
        title: 'Перемещение ТМЦ',
        type: StepType.tmcTransfer,
        image: 'assets/img/task-step/location-confirmation.png',
        icon: 'assets/img/task-step/icons/arrows-icon.svg',
        color: '#FF5254',
    },
    [StepType.equipmentCheck]: {
        title: 'Проверка оборудования',
        type: StepType.equipmentCheck,
        image: 'assets/img/task-step/location-confirmation.png',
        icon: 'assets/img/task-step/icons/rack-icon.svg',
        color: '#3DC1D3',
    },
    [StepType.equipmentRequisition]: {
        title: 'Заявка по оборудованию',
        type: StepType.equipmentCheck,
        image: 'assets/img/task-step/location-confirmation.png',
        icon: 'assets/img/task-step/icons/application-icon.svg',
        color: '#FFD952',
    },
    unknown: {
        title: 'Неизвестный тип',
        type: 'unknown',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
};

export const SendPhotoTypeMap = {
    [AdditionalSendPhotoType.photoCalculateRestOfProduct]: {
        type: AdditionalSendPhotoType.photoCalculateRestOfProduct,
        title: 'Фото остатков',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoCheck]: {
        type: AdditionalSendPhotoType.photoCheck,
        title: 'Фото чека',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoSign]: {
        type: AdditionalSendPhotoType.photoSign,
        title: 'Фото режимной выставки',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoAddress]: {
        type: AdditionalSendPhotoType.photoAddress,
        title: 'Фото адреса',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoFacade]: {
        type: AdditionalSendPhotoType.photoFacade,
        title: 'Фото фасада',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
    [AdditionalSendPhotoType.photoEquip]: {
        type: AdditionalSendPhotoType.photoEquip,
        title: 'Фото оборудования',
        image: 'assets/img/task-step/freeform-specification.png',
        icon: 'assets/img/task-step/icons/file-icon.svg',
        color: '#0066FF',
    },
};

export enum TaskStatus {
    NEW = 'NEW',
    ACCEPTED = 'ACCEPTED',
    FAILED = 'FAILED',
    SKIPPED = 'SKIPPED',
    PROGRESS = 'PROGRESS',
    UNKNOWN = 'UNKNOWN'
}

export enum TaskStepStatus {
    NEW = 'NEW',
    ACCEPTED = 'ACCEPTED',
    NOT_ACCEPTED = 'NOT_ACCEPTED',
    CHECKING = 'CHECKING',
    UNKNOWN = 'UNKNOWN'
}

export const TaskStatusMap = {
    [TaskStatus.NEW]: {
        type: TaskStatus.NEW,
        title: 'Новый',
        color: '#FFD600'
    },
    [TaskStatus.ACCEPTED]: {
        type: TaskStatus.ACCEPTED,
        title: 'Выполненно',
        color: '#00D0B3'
    },
    [TaskStatus.FAILED]: {
        type: TaskStatus.FAILED,
        title: 'Провалено',
        color: '#FF5254'
    },
    [TaskStatus.PROGRESS]: {
        type: TaskStatus.PROGRESS,
        title: 'В процессе',
        color: '#166FFF'
    },
    [TaskStatus.SKIPPED]: {
        type: TaskStatus.SKIPPED,
        title: 'Просрочены',
        color: '#FF8C4D'
    },
    [TaskStatus.UNKNOWN]: {
        type: TaskStatus.UNKNOWN,
        title: 'Неизветсно',
        color: '#FF5254'
    },
};

export const PermissionTypeMap = {
    [PermissionType.onlyAgent]:
        { id: PermissionType.onlyAgent, name: 'Только торговые агенты' },
    [PermissionType.onlyAddress]:
        { id: PermissionType.onlyAddress, name: 'Только сотрудники торговой точки' },
    [PermissionType.addressAndAgent]:
        { id: PermissionType.addressAndAgent, name: 'Все (торговые агенты и сотрудники торговой точки)' }
};


export const TaskStepStatusMap = {
    [TaskStepStatus.NEW]: {
        type: TaskStepStatus.NEW,
        title: 'Новый',
        color: '#166FFF'
    },
    [TaskStepStatus.ACCEPTED]: {
        type: TaskStepStatus.ACCEPTED,
        title: 'Выполненно',
        color: '#00D0B3'
    },
    [TaskStepStatus.CHECKING]: {
        type: TaskStepStatus.CHECKING,
        title: 'Провалено',
        color: '#FF5254'
    },
    [TaskStepStatus.NOT_ACCEPTED]: {
        type: TaskStepStatus.NOT_ACCEPTED,
        title: 'Провалено',
        color: '#FF5254'
    },
    [TaskStatus.UNKNOWN]: {
        type: TaskStatus.UNKNOWN,
        title: 'Неизветсно',
        color: '#FF5254'
    },
};


export interface TaskAssignByTemplatesOutletDateStepTemplate {
    taskStepTemplateId: number;
    required: boolean;
}

export interface TaskAssignByTemplatesOutletDate {
    dateStart: string;
    dateEnd: string;
}

export interface TaskAssignByTemplatesOutlet {
    clientId: number;
    addressId: number;
}
export interface TaskAssignByTemplatesAddressFilter {
    territoryUserId: number;
    withUsers: boolean;
    externalIds: string[];
    clientIds: number[];
    address: string;
    countryIds: number[];
    regionIds: number[];
    cityIds: number[];
    segment: string;
    visitUserIds: number[];
    visitDateFrom: string;
    notInIds: number[];
    visitDateTo: string;
    pageSize: number;
    pageNumber: number;
    sort: string[];
    distributorIds: number[];
}

export interface AssignByTemplatesAndOutletFilterPayload {
    taskTemplateId: number;
    stepTemplates: TaskAssignByTemplatesOutletDateStepTemplate[];
    dates: TaskAssignByTemplatesOutletDate[];
    addressFilter: TaskAssignByTemplatesAddressFilter;
}
export interface TaskAssignByTemplatesPayload {
    taskTemplateId: number;
    stepTemplates: TaskAssignByTemplatesOutletDateStepTemplate[];
    dates: TaskAssignByTemplatesOutletDate[];
    outlets: TaskAssignByTemplatesOutlet[];
}
export interface  TaskAssignByTemplatesStepTemplate {
    taskStepTemplateId: number
    required: boolean
    stepTemplate: StepTemplate
  }
export interface CriteriaTaskAssignByTemplates {
    pageNumber: number;
    pageSize: number;
}
export interface TaskAssignByTemplates {
    id: number
    disabled: boolean
    status: string
    taskTemplateId: number
    taskTemplate: TaskTemplate
    stepTemplates: TaskAssignByTemplatesStepTemplate[]
    dates: TaskAssignByTemplatesOutletDate[]
    addressFilter: TaskAssignByTemplatesAddressFilter
}
