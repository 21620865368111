import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimesPipe } from '@Mesh/shared/modules/times/times.pipe';

@NgModule({
  declarations: [
      TimesPipe
  ],
  exports: [
      TimesPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
      TimesPipe
  ]
})
export class TimesModule { }
