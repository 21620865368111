import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBonus from '../../reducers/saleplan/bonus.reducers';

export const getPlansState = createFeatureSelector<fromBonus.State>('bonus');

export const selectBonus = createSelector(
  getPlansState,
  fromBonus.getBonus
);

export const selectBonusLoadingStatus = createSelector(
  getPlansState,
  fromBonus.getBonusLoadingStatus
);

export const selectPredictBonus = createSelector(
  getPlansState,
  fromBonus.getPredictBonus
);

export const selectCurrentConfiguration = createSelector(
  getPlansState,
  fromBonus.getCurrentConfiguration
);

export const selectConfigurations = createSelector(
  getPlansState,
  fromBonus.getBonusConfigurations
);

export const selectBonusConfigCategory = createSelector(
  getPlansState,
  fromBonus.getBonusConfigCategory
);

export const selectPredictBonusLoadingStatus = createSelector(
  getPlansState,
  fromBonus.getPredictBonusLoadingStatus
);

export const salePlanBonusSelectors = {
  selectPredictBonusClient: createSelector(getPlansState, fromBonus.getPredictBonusClient),
  selectPredictBonusClientLoadingStatus: createSelector(getPlansState, fromBonus.getPredictBonusClientLoadingStatus),

  selectBonusOutlets: createSelector(getPlansState, fromBonus.getBonusOutlets),
  selectBonusOutletsLoadingStatus: createSelector(getPlansState, fromBonus.getBonusOutletsLoadingStatus),

  selectPredictBonusOutlets: createSelector(getPlansState, fromBonus.getPredictBonusOutlets),
  selectPredictBonusOutletsLoadingStatus: createSelector(getPlansState, fromBonus.getPredictBonusOutletsLoadingStatus),
};
