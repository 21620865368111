import { of } from 'rxjs';
import { openSupportChat } from '../../../../store/actions/chat/chat.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MESSAGING_URL } from '@Env/environment';
import { Notification, NotificationRequest, NotificationsData, NotificationToken, NotificationTokenRequest } from '@Mesh/core/models/notification';
import { HttpClient, HttpParams } from '@angular/common/http';
import { State } from '@Mesh/store/reducers';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly connectionString = MESSAGING_URL;

  constructor(private readonly http: HttpClient,
              private router: Router,
              private store: Store<State>) {
  }

  updateToken(request: NotificationTokenRequest): Observable<NotificationToken> {
    return this.http
      .post(`${MESSAGING_URL}/token`, request)
      .pipe(map((token: any) => {
        return token as NotificationToken;
      }));
  }

  readNotification(id: number): Observable<Notification> {
    return this.http
      .put(`${MESSAGING_URL}/notifications/read/${id}`, {})
      .pipe(map((notification: any) => {
        return notification as Notification;
      }));
  }

  goToNotification(notification: Notification): Observable<boolean> {
    switch (notification.entityType) {
      case 'support':
        this.store.dispatch(
          openSupportChat({
            userId: notification.payload.sentBy,
            addressId: notification.payload.addressId,
            clientId: notification.payload.clientId,
          })
        );
        break;
    }
    return of(true);
  }

  getNotifications(request: NotificationRequest): Observable<NotificationsData> {
    let params = new HttpParams();
    const keys = Object.keys(request);
    keys.forEach((k) => {
      params = params.append(k, `${request[k]}`);
    });
    return this.http
      .get(`${MESSAGING_URL}/notifications/by-params`, { params })
      .pipe(map((notifications: any) => {
        return notifications as NotificationsData;
      }));
  }
}
