import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { USER_DISTRIBUTOR_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { AgentPositionSearchParams, AgentPosition, AgentPositionCreatePayload } from '../../../models/agent-position';
import { IPageableList } from '../../../models/pageable';

@Injectable({
  providedIn: 'root'
})
export class AgentPositionService {
  constructor(private readonly http: HttpClient) { }

  searchAgentPosition(searchAgentPositionParams: Partial<AgentPositionSearchParams>): Observable<IPageableList<AgentPosition>> {
    const params = objectToParams(searchAgentPositionParams);
    return this.http.get<IPageableList<AgentPosition>>(`${USER_DISTRIBUTOR_URL}/v1/agent-position`, { params });
  }

  createAgentPosition(payload: AgentPositionCreatePayload): Observable<AgentPosition> {
    return this.http.post<AgentPosition>(`${USER_DISTRIBUTOR_URL}/v1/agent-position`, payload);
  }

  updateAgentPosition(payload: AgentPositionCreatePayload): Observable<AgentPosition> {
    return this.http.put<AgentPosition>(`${USER_DISTRIBUTOR_URL}/v1/agent-position`, payload);
  }

  removeAgentPosition(agentPositionId: number): Observable<any> {
    return this.http.delete<any>(`${USER_DISTRIBUTOR_URL}/v1/agent-position/${agentPositionId}`);
  }
}
