import { createAction, props } from '@ngrx/store';
import { Client, ClientFilterCriteria, ClientInfo, ClientsData } from '@Mesh/core/models/client';

export const loadClientsByParams = createAction('[Client] Load clients by params', props<ClientFilterCriteria>());
export const clientsByParamsLoaded = createAction('[Client] Clients by params loaded', props<{ clients: any[] }>());

export const loadDataClients = createAction('[Client] Load clients data', props<ClientFilterCriteria>());
export const clientsDataLoaded = createAction('[Client] Clients data loaded', props<{ clientsData: ClientsData }>());

export const loadClientsError = createAction('[Client] Error with load clients');
export const loadCurrentClient = createAction('[Client] Load current client', props<{ clientId: number }>());
export const currentClientLoaded = createAction('[Client] current client Loaded', props<{ client: Client }>());

export const loadClientInfo = createAction('[Client] load client info', props<{ clientId: number }>());
export const clientInfoLoaded = createAction('[Client] clientInfoLoaded', props<{ clientInfo: ClientInfo }>());
export const createClientInfo = createAction('[Client] create client info', props<{ clientInfo: ClientInfo }>());
export const clientInfoCreated = createAction('[Client] clientInfoCreated', props<{ clientInfo: ClientInfo }>());
export const updateClientInfo = createAction('[Client] update client info', props<{ clientInfo: ClientInfo }>());
export const clientInfoUpdated = createAction('[Client] clientInfoUpdated', props<{ clientInfo: ClientInfo }>());

export const loadBonusClient = createAction('[Client] Load bonus client', props<{ clientId: number }>());
export const loadBonusClientSuccess = createAction('[Client] clientInfoUpdated', props<{ data: { clientId: number, account: number } }>());
