import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ExcelParseService {

  constructor() { }

  transformExcelToJson(file: File): Observable<unknown[]> {
    return new Observable((observer) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer = fileReader.result as any;
        const data = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        observer.next(XLSX.utils.sheet_to_json(worksheet, {raw: false}));
        observer.complete();
      };
      fileReader.readAsArrayBuffer(file);
    });
  }
}
