import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotification from '../../reducers/notification/notification.reducers';

export const getNotificationState =
  createFeatureSelector<fromNotification.State>('notification');

export const getPushToken = createSelector(
  getNotificationState,
  fromNotification.getPushToken
);

export const getNotifications = createSelector(
  getNotificationState,
  fromNotification.getNotifications
);

export const getReadNotificationStatus = createSelector(
  getNotificationState,
  fromNotification.readNotificationStatus
);
