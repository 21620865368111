import { createReducer, Action, on } from '@ngrx/store';
import * as fromActions from '../../actions/breadcrumb/breadcrumb.actions';

export interface State {
  additionals: Map<string, string>;
}

export const initialState: State = {
  additionals: null,
};

const breadcrumbReducer = createReducer(
  initialState,
  on(fromActions.addBreadcrumbAdditional, (state, {key, val}) => ({...state, additionals: {...state.additionals, [key]: val}})),
);

export function reducer(state: State | undefined, action: Action) {
  return breadcrumbReducer(state, action);
}

export const getAdditionals = (state: State) => state.additionals;
