import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PgOptionComponent } from './option.component';
import { OptionPipe } from './option.pipe';
import { PgSelectComponent } from './select.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [CommonModule, FormsModule, OverlayModule, InfiniteScrollModule],
    declarations: [OptionPipe, PgOptionComponent, PgSelectComponent],
    exports: [OptionPipe, PgOptionComponent, PgSelectComponent]
})
export class PgSelectModule {
}
