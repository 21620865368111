
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class QuickviewService {

  constructor(private http: HttpClient) { }

  // Get from the API
  getNotes(): Observable<any> {
    return this.http.get('assets/data/notes.json');
  }

}
