import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'iql-blank-layouts',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlankComponent {
  @ViewChild('root', { static: false }) root;
  constructor() {}
}
