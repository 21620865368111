import { EnableOutletGoal, PlanProgress, PlanProgressConfig, SalePlan, SalePlanEnable } from '@Mesh/core/models/APImodels/saleplan/saleplans';
import { ErrorType } from '@Mesh/core/models/APPmodels/errors';
import { createAction, props } from '@ngrx/store';

export const loadSalePlans = createAction('[SalePlan] Load SalePlans', props<{ addressId: number }>());
export const salePlansLoaded = createAction('[SalePlan] Sale plan loaded', props<{ salePlans: SalePlan[]}>());
export const salePlansLoadError = createAction('[SalePlan] Sale plan load error', props<{ error: ErrorType }>());

export const enableSalePlan = createAction('[SalePlan] Sale Plan enable', props<{ enableOutletGoal: EnableOutletGoal }>());
export const disableSalePlan = createAction('[SalePlan] Sale Plan disable', props<{ id?: number, addressId?: number }>());

export const loadSalePlansEnableRules = createAction('[SalePlan] Load SalePlans Enable Rules', props<{ addressId: number }>());
export const salePlansEnableRulesLoaded = createAction('[SalePlan] Sale plan Enable Rules loaded', props<{ salePlansEnableRules: SalePlanEnable[]}>());
export const salePlansEnableRulesLoadError = createAction('[SalePlan] Sale plan Enable Rules load error', props<{ error: ErrorType }>());

export const loadSalePlanProgress = createAction('[SalePlan] Load sale plan progress ', props<{ addressIds: number[], planIds: number[] }>());
export const salePlanProgressLoaded = createAction('[SalePlan] Sale plan progress  loaded', props<{ planProgress: PlanProgress[]}>());
export const salePlanProgressLoadError = createAction('[SalePlan] Sale plan progress  load error', props<{ error: ErrorType }>());

export const loadSalePlanProgressConfigs = createAction('[SalePlan] Load sale plan progress configs', props<{ addressIds: number[], planId?: number }>());
export const salePlanProgressConfigsLoaded = createAction('[SalePlan] Sale plan progress configs loaded', props<{ planProgressConfigs: PlanProgressConfig[]}>());
export const salePlanProgressConfigsLoadError = createAction('[SalePlan] Sale plan progress configs load error', props<{ error: ErrorType }>());