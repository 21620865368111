import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IClientsLookup } from '@Mesh/core/models/lookup';
import { populateClientsOutletsLookupEntity } from '@Mesh/store/actions/deprecated/clients-outlets-lookup.actions';

export const clientsOutletsLookupFeatureKey = 'clientsOutletsLookup';

export interface State extends EntityState<IClientsLookup> {
}

export const adapter: EntityAdapter<IClientsLookup> = createEntityAdapter<IClientsLookup>({
    sortComparer: (a: IClientsLookup, b: IClientsLookup) => a.name?.localeCompare(b.name)
});

export const initialState: State = adapter.getInitialState();

const clientsOutletsLookupReducer = createReducer(
    initialState,
    on(populateClientsOutletsLookupEntity, (state, { clientsLookup }) => adapter.addAll(clientsLookup, state))
);

export function reducer(state: State | undefined, action: Action) {
    return clientsOutletsLookupReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const _selectClientsOutletsLookupIds = selectIds;
export const _selectClientsOutletsLookupEntities = selectEntities;
export const _selectClientsOutletsLookup = selectAll;
