import { Injectable } from '@angular/core';
import * as fromActions from '../../actions/deprecated/plans.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMapTo, map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { PlanConfigItem } from '@Mesh/core/models/plan';
import { PlansService } from '@Mesh/core/services/api/deprecated/plans.service';

@Injectable()
export class PlansEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly plansService: PlansService
    ) {
    }

    loadPlanConfigs$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.loadPlanConfigs),
            switchMapTo(this.plansService.getPlanConfigs()),
            map(planConfigs => fromActions.planConfigsLoaded({ planConfigs }))
        )
    );
    createPlanConfig$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createPlanConfig),
            switchMap(planConfig => {
                return this.plansService.createPlanConfig(planConfig).pipe(
                    map(planConfig => fromActions.planConfigCreated({ planConfig })),
                    catchError(() => [fromActions.planConfigCreateError()]),
                )
            }),
        )
    );
    updatePlanConfig$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.updatePlanConfig),
            switchMap(planConfig => {
                return this.plansService.updatePlanConfig(planConfig).pipe(
                    map(planConfig => fromActions.planConfigUpdated({ planConfig })),
                    catchError(() => [fromActions.planConfigUpdateError()]),
                )
            }),
        )
    );
    deletePlanConfig$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.deletePlanConfig),
            switchMap(({ planId }) => {
                return this.plansService.deletePlanConfig(planId).pipe(
                    map(() => fromActions.planConfigDeleted({ planId })),
                    catchError(() => [fromActions.planConfigDeleteError()]),
                )
            }),
        )
    );
    createPlanConfigItems$ = createEffect(
        () => this._actions$.pipe(
            ofType(fromActions.createPlanConfigItems),
            mergeMap(({ items }) => {
                const container = [];
                items.forEach(i => i.id ? container.push(this.plansService.updatePlanConfigItem(items[0].planId, i.id, i)) : container.push(this.plansService.createPlanConfigItem(i.planId, i)));
                return combineLatest(container).pipe(map((items: PlanConfigItem[]) => fromActions.planConfigItemsCreated({ items })));
            }),
            catchError(() => [fromActions.planConfigItemsCreateError()]),
        )
    );

}
