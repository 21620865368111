import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IOutletsLookup } from '@Mesh/core/models/lookup';
import { populateOutletsClientsLookupEntity } from '@Mesh/store/actions/deprecated/outlets-clients-lookup.actions';

export const outletsClientsLookupFeatureKey = 'outletsClientsLookup';

export interface State extends EntityState<IOutletsLookup> {
}

export const adapter: EntityAdapter<IOutletsLookup> = createEntityAdapter<IOutletsLookup>({
    sortComparer: (a: IOutletsLookup, b: IOutletsLookup) => a.name?.localeCompare(b.name)
});

export const initialState: State = adapter.getInitialState();

const outletsClientsLookupReducer = createReducer(
    initialState,
    on(populateOutletsClientsLookupEntity, (state, { outletsLookup }) => adapter.addAll(outletsLookup, state))
);

export function reducer(state: State | undefined, action: Action) {
    return outletsClientsLookupReducer(state, action);
}

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
export const _selectOutletsClientsLookupIds = selectIds;
export const _selectOutletsClientsLookupEntities = selectEntities;
export const _selectOutletsClientsLookup = selectAll;
