import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Bonus } from '@Mesh/core/models/APImodels/saleplan/bonus';

@Component({
  selector: 'pg-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnChanges {
  private _value: number;
  private _value2: any;

  @Input() type: string;
  @Input() color: string;
  @Input() thick: boolean;
  @Input() indeterminate: boolean;
  @Input() bonus: Bonus = null;
  @Input() progressLevelvalue: {maximum: number, currentLevel: number} = null;

  @Input() predictBonus: Bonus = null;
  @Input() predictValue: number = 0;

  @Input() set value(value: number) {
    if (this.type === 'circle') {
      this._value = (value / 100) * 360;
      if (this.value >= 50) {
        this._value2 = true;
      }
    } else {
        this._value = value;
    }
  }

  get value() {
    return this._value;
  }
  get value2() {
    return this._value2;
  }

  mainColor = '#000000';
  points: number;

  private convertColor(col: number): string {
    switch (col) {
      case 1: return '#FF544E';
      case 2: return '#FF704D';
      case 3: return '#FF8C4D';
      case 4: return '#FFA84C';
      case 5: return '#FFC54C';
      case 6: return '#FFE04B';
      case 7: return '#D7E753';
      case 8: return '#AFEE5C';
      case 9: return '#86F465';
      case 10: return '#5EFB6D';
      case 11: return '#39A7FF';
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.bonus && changes.bonus.currentValue) {
      const bonus = <Bonus>changes.bonus.currentValue;
      this.points = bonus.currentBonus;
      if (bonus.executedPercent > 100 && bonus.executedPercent <= 110) {
        this._value = bonus.executedPercent - 10;
      }
      if (bonus.executedPercent > 110 && bonus.executedPercent <= 120) {
        this._value = bonus.executedPercent - 20;
      }
      if (bonus.executedPercent > 120) {
        this._value = 100;
      }
    }
    if (changes.color) {
      this.mainColor = this.convertColor(changes.color?.currentValue);
    }
  }
}
