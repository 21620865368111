import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SALEPLAN_URL } from '../../../../../environments/environment';
import { objectToParams } from '../../../../shared/helpers/object.helpers';
import { IPageableList } from '../../../models/pageable';
import { ProductTransferUserSearchParams, ProductTransferUser, ProductTransferTransactionsSearchParams, ProductTransferTransactions, ProductTransferPayload } from '../../../models/product-transfer';

@Injectable({
  providedIn: 'root'
})
export class ProductTransferService {
  constructor(private readonly http: HttpClient) { }

  searchProductTransferUser(searchProductTransferUserParams: Partial<ProductTransferUserSearchParams>): Observable<IPageableList<ProductTransferUser>> {
    const params = objectToParams(searchProductTransferUserParams);
    return this.http.get<IPageableList<ProductTransferUser>>(`${SALEPLAN_URL}/v1/productTransfer/user`, { params });
  }

  searchProductTransferTransactions(searchProductTransferParams: Partial<ProductTransferTransactionsSearchParams>): Observable<IPageableList<ProductTransferTransactions>> {
    const params = objectToParams(searchProductTransferParams);
    return this.http.get<IPageableList<ProductTransferTransactions>>(`${SALEPLAN_URL}/v1/productTransfer/transfer/transactions`, { params });
  }

  createProductTransfer(payload: ProductTransferPayload): Observable<Array<ProductTransferTransactions>> {
    return this.http.post<Array<ProductTransferTransactions>>(`${SALEPLAN_URL}/v1/productTransfer/transfer`, payload);
  }

}
