import { createAction, props } from '@ngrx/store';
import { AgentVisit, AgentVisitCreatePayload, AgentVisitEditPayload, OptionalAgentVisitSearchParams } from '../../../core/models/agent';
import { ITaskOutlet, StepTemplate, TaskOutletAssignPayload, TaskStep, TaskVisitCreatePayload } from '../../../core/models/task';

export const findAgentVisits = createAction('[Agent] Find Agent Visits', props<{ agentVisitPlanSearchParams?: OptionalAgentVisitSearchParams }>());
export const findAgentVisitSuccess = createAction('[Agent] Find Agent Visits Success', props<{ agentVisits: AgentVisit[] }>());
export const findAgentVisitsFailure = createAction('[Agent] Find Agent Visits Failure', props<{ error: any }>());


export const createAgentVisit = createAction('[Agent] Create Agent Visits', props<{ taskId: number, payload: Partial<TaskVisitCreatePayload> }>());
export const createAgentVisitSuccess = createAction('[Agent] Create Agent Visits Success', props<{ data: AgentVisit[] }>());
export const createAgentVisitFailure = createAction('[Agent] Create Agent Visits Failure', props<{ error: any }>());

export const editAgentVisit = createAction('[Agent] Edit Agent Visits', props<{ payload: Partial<TaskVisitCreatePayload> }>());
export const editAgentVisitSuccess = createAction('[Agent] Edit Agent Visits Success', props<{ data: AgentVisit }>());

export const removeAgentVisit = createAction('[Agent] Remove Agent Visit', props<{ agentVisitPlanIds: number[] }>());
export const removeAgentVisitSuccess = createAction('[Agent] Remove Agent Visit Success', props<{ data: any }>());
export const removeAgentVisitFailure = createAction('[Agent] Remove Agent Visit Failure', props<{ error: any }>());

export const createVisit = createAction('[Agent] Create Task Agent Visits', props<{ payload: TaskVisitCreatePayload }>());
export const createVisitAssignTaskAndAdddress = createAction('[Agent] Create Visit Assign Task And Adddress ', props<{ taskTemplateId: number, taskId: number, payload: Partial<TaskVisitCreatePayload> }>());
export const createVisitSuccess = createAction('[Agent] Create Task Agent Visits Success', props<{ data: any }>());
export const createVisitFailure = createAction('[Agent] Create Task Agent Visits Failure', props<{ error: any }>());
export const createVisitReset = createAction('[Agent] Create Task Agent Visits Reset');

export const createStepByTemplate = createAction('[Task] Create Step By Template', props<{ templateId: number, taskId: number, payload: StepTemplate[] }>());
export const createStepByTemplateSuccess = createAction('[Task] Create Step By Template Success', props<{ data: TaskStep[] }>());
export const createStepByTemplateFailure = createAction('[Task] Create Step By Template Failure', props<{ error: any }>());

export const createTaskAddress = createAction('[Task] Create Task Address', props<{ taskId: number, payload: ITaskOutlet[] }>());
export const createTaskAddressSuccess = createAction('[Task] Create Task Address Success', props<{ data: any }>());
export const createTaskAddressFailure = createAction('[Task] Create Task Address Failure', props<{ error: any }>());

export const assignTask = createAction('[Task] Assign Task', props<{ taskId: number, payload: Partial<TaskVisitCreatePayload> }>());
export const assignTaskSuccess = createAction('[Task] Assign Task Success', props<{ data: any }>());
export const assignTaskFailure = createAction('[Task] Assign Task Failure', props<{ error: any }>());

export const assignTaskAndVisitSuccess = createAction('[Agent] Assign Task And Visit', props<{ taskId: number, payload: Partial<TaskVisitCreatePayload>, visits: AgentVisit[] }>());
