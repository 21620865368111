import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './distro.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GetErrorType } from '@Mesh/core/models/APPmodels/errors';
import { Store } from '@ngrx/store';
import { State } from '@Mesh/store/reducers';
import { DistroService } from '@Mesh/pages/distro/distro.service';
import { Router } from '@angular/router';
import { DistributorService } from '../../../core/services/api/distributor/distributor.service';

@Injectable()
export class DistroEffects {
  constructor(private actions$: Actions,
    private distroService: DistroService,
    private distributorService: DistributorService,
    private readonly store: Store<State>,
    private router: Router) {
  }

  updateDistributor$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.updateDistributor),
      switchMap(({ distributor }) => this.distributorService.updateDistributor(distributor).pipe(
        map(distro => {
          this.router.navigate(['/', 'distributors', distro.id, 'categories']);
          return fromActions.updateDistributorSuccess({ distributor: distro });
        }),
        catchError(err => [fromActions.updateDistributorError({ error: GetErrorType(err) })])
      ))
    )
  );

  createDistributor$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.createDistributor),
      switchMap(({ distributor }) => this.distributorService.createDistributor(distributor).pipe(
        map(distro => {
          this.router.navigate(['/', 'distributors', distro.id, 'categories']);
          return fromActions.createDistributorSuccess({ distributor: distro });
        }),
        catchError(err => [fromActions.updateDistributorError({ error: GetErrorType(err) })])
      ))
    )
  );

  findOneDistributor$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.findOneDistributor),
      switchMap(({ id }) => this.distributorService.searchDistributor({ distrIds: [id] })
        .pipe(
          map(data => fromActions.findOneDistributorSuccess({ distributor: data.content[0] })),
          catchError(err => ([fromActions.findOneDistributorError(err)]))
        )
      )
    ));

  findAllDistributor$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.findAllDistributors),
      switchMap(({ params }) => this.distributorService.searchDistributor(params)
        .pipe(
          map(data => fromActions.findAllDistributorsSuccess({ distributors: data.content })),
          catchError(err => ([fromActions.findAllDistributorsError(err)]))
        )
      )
    ));

  deleteDistributor$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.deleteDistributor),
      switchMap(({ id }) => this.distributorService.removeDistributor({ id })
        .pipe(
          map(() => fromActions.deleteDistributorSuccess({ id })),
          catchError((error) => ([fromActions.deleteDistributorError({ error })]))
        )
      )
    ));
}
