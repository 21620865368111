import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'iql-chat-audio',
  templateUrl: './chat-audio.component.html',
  styleUrls: ['./chat-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatAudioComponent implements OnInit, AfterViewInit {
  wave: WaveSurfer = null;
  private _audioBlob = null;
  private _audioUrl = null;
  paused = true;

  @ViewChild('waveform', {static: false}) waveform: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {
  }

  @Input()
  set audioBlob(value: any) {
    this._audioBlob = value;

    if (this.wave && this._audioBlob) {
      this.wave.loadBlob(this._audioBlob);
    }
  }

  @Input()
  set audioUrl(value: any) {
    this._audioUrl = value;

    if (this.wave && this._audioUrl) {
      this.wave.load(this._audioUrl);
    }
  }

  ngOnInit(): void {
  }

  toggle(): void {
    this.wave.playPause();
  }

  ngAfterViewInit(): void {
    this.wave = WaveSurfer.create({
      container: this.waveform.nativeElement,
      waveColor: '#D9DCFF',
      progressColor: '#4353FF',
      cursorColor: '#4353FF',
      barWidth: 2,
      barHeight: 1,
      height: 50,
      barGap: null
    });

    if (this._audioUrl) {
      this.wave.load(this._audioUrl);
    }

    if (this._audioBlob) {
      this.wave.loadBlob(this._audioBlob);
    }

    this.wave.on('play', () => {
      this.paused = false;
      this.cdr.detectChanges();
    });

    this.wave.on('pause', () => {
      this.paused = true;
      this.cdr.detectChanges();
    });

    this.wave.on('finish', () => {
      this.paused = true;
      this.cdr.detectChanges();
      this.wave.seekTo(0);
    });
  }

}
