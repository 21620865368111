import { createAction, props } from '@ngrx/store';
import { Outlet, OutletFilters, OutletData, OutletFilterCriteria, OutletSearchAddressParams, OutletAddressResponse, OutletAllAddressParams } from '@Mesh/core/models/outlet';
import { TaskProgress } from '@Mesh/core/models/task';

enum OutletActionTypes {
    LoadOutlets = '[Outlets] Load Outlets',
    PopulateOutlets = '[Outlets] Populate Outlet Entity',
    SetSelectedOutlet = '[Outlets] Set SelectedOutlet',
    SetSelectedTaskOutletClient = '[Outlets] Set SelectedTaskOutletCLient',
    SetOutletFilters = '[Outlets] Set Outlet Filters',
    LoadOutletList = '[Outlets] Get Outlet list',
    OutletListLoaded = '[Outlets] Outlet list loaded',
    LoadClientOutletIds = '[Outlets] Load client selected outlets',
    LoadTaskOutletIds = '[Outlets] Load task selected outlet Ids',
    SetSelectedOutlets = '[Outlets] Set selected outlets',
    FindAllOutletsAddress = '[Outlets] Find all outlets address',
    findAllOutletsAddressSuccess = '[Outlets] Find all outlets address succes',
    FindAllOutletsAddressError = '[Outlets] Find all outlets address error',
    ResetOutletsAddress = '[Outlets] Reset outlets address',
    LoadAllOutletsAddress = '[Outlets] Load all outlets address',
    LoadAllOutletsAddressSucces = '[Outlets] Load all outlets address succes',
    LoadAllOutletsAddressError = '[Outlets] Load all outlets address error',
    ResetAllOutletsAddress = '[Outlets] Reset all outlets address',
    LoadFilterOutletsAddress = '[Outlets] Load filter outlets address',
    LoadFilterOutletsAddressSucces = '[Outlets] Load filter outlets address succes',
    LoadFilterOutletsAddressError = '[Outlets] Load filter outlets address error',
    ResetFilterOutletsAddress = '[Outlets] Reset filter outlets address',
    SetLoadingAllOutletsAddress = '[Outlets] Set Loading all outlets address',
    LoadOneOutletsAddress = '[Outlets] Load one outlets address'
}

export const getOutlets = createAction(OutletActionTypes.LoadOutlets, props<{ clientId?: number, pageSize?: number }>());
export const getClientOutletIds = createAction(OutletActionTypes.LoadClientOutletIds, props<{ taskId: number; clientId: number }>());
export const getTaskOutletIds = createAction(OutletActionTypes.LoadTaskOutletIds, props<{ taskId: number }>());
export const populateOutletEntity = createAction(OutletActionTypes.PopulateOutlets, props<{ outlets: Outlet[] }>());
export const setSelectedOutlet = createAction(OutletActionTypes.SetSelectedOutlet, props<{ id: number }>());
export const setSelectedTaskOutletClient = createAction(OutletActionTypes.SetSelectedTaskOutletClient, props<{ taskProgress: TaskProgress }>());
export const setSelectedOutlets = createAction(OutletActionTypes.SetSelectedOutlets, props<{ ids: number[] }>());
export const setOutletFilters = createAction(OutletActionTypes.SetOutletFilters, props<{ filters: OutletFilters }>());
export const loadOutletList = createAction(OutletActionTypes.LoadOutletList, props<OutletFilterCriteria>());
export const outletListLoaded = createAction(OutletActionTypes.OutletListLoaded, props<{ outletData: OutletData }>());

export const findAllOutletsAddress = createAction(OutletActionTypes.FindAllOutletsAddress, props<{ searchAddressParams: Partial<OutletSearchAddressParams> }>());
export const findAllOutletsAddressSuccess = createAction(OutletActionTypes.findAllOutletsAddressSuccess, props<{ outletsAddress: OutletAddressResponse[] }>());
export const findAllOutletsAddressError = createAction(OutletActionTypes.FindAllOutletsAddressError);
export const resetOutletsAddress = createAction(OutletActionTypes.ResetOutletsAddress);

export const getAllOutletsAddress = createAction(OutletActionTypes.LoadAllOutletsAddress, props<{ searchAddressParams: Partial<OutletAllAddressParams> }>());
export const getAllOutletsAddressSuccess = createAction(OutletActionTypes.LoadAllOutletsAddressSucces, props<{ outletsAddress: OutletAddressResponse[] }>());
export const getAllOutletsAddressError = createAction(OutletActionTypes.LoadAllOutletsAddressError);
export const resetAllOutletsAddress = createAction(OutletActionTypes.ResetAllOutletsAddress);


export const getFilterOutletsAddress = createAction(OutletActionTypes.LoadFilterOutletsAddress, props<{ searchAddressParams: Partial<OutletAllAddressParams> }>());
export const getFilterOutletsAddressSuccess = createAction(OutletActionTypes.LoadFilterOutletsAddressSucces, props<{ outletsAddress: OutletAddressResponse[] }>());
export const getFilterOutletsAddressError = createAction(OutletActionTypes.LoadFilterOutletsAddressError);
export const resetFilterOutletsAddress = createAction(OutletActionTypes.ResetFilterOutletsAddress);

export const getOneOutletsAddress = createAction(OutletActionTypes.LoadOneOutletsAddress, props<{ searchAddressParams: Partial<OutletSearchAddressParams> }>());
