import { FeathersService } from '@Mesh/core/services/chat/feathers.service';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { ChatService } from './chat.service';
import { User } from '@Mesh/core/models/user';
import { ChatDialogService, ChatDialogType } from './chat-dialog/chat-dialog.service';
import { filter, mergeMap, takeUntil, throttleTime } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SPINNER } from 'ngx-ui-loader';
import { UserService } from '@Mesh/core/services/chat/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'iql-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('productRecognition', [
      state('open', style({
        opacity: '*',
        bottom: '15px'
      })),
      state('closed', style({
        opacity: 0,
        bottom: '-56px'
      })),
      transition('open => closed', [
        animate('0.7s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked {
  u$ = new Subject;
  dialog: { type?: string, typeId?: any, taskId?: any, task?: any, outlet?: any, approve?: any, cancel?: any, taskStep?: any, addressId?: number, clientId?: number, stepId?: number, sentBy?: number };
  current_user: User;
  userToChat = null;
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentUser$;
  search;
  clan$;
  all$;
  spinner = SPINNER;
  newChat;


  private _record: User | any;

  constructor(public chatService: ChatService,
              private chatDialogService: ChatDialogService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private feathersService: FeathersService
  ) {
  }

  set searchUsers(value: string) {
    this.search$.next(value);

    this.cdr.detectChanges();
  }

  get record(): User | any {
    return this._record;
  }

  set record(value: User| any) {
    this._record = value;

    this.chatDialogService.record = value;

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.u$.next();
    this.u$.unsubscribe();
  }

  openCreateChatView(): void {
    this.newChat = true;
  }

  ngAfterViewChecked(): void {
  }

  ngOnInit(): void {
    this.currentUser$ = this.feathersService.currentUserSubject;
    this.search$.pipe(takeUntil(this.u$), throttleTime(300)).subscribe(value => {
      this.search = value;
    });

    this.currentUser$
      .pipe(
        takeUntil(this.u$)
      ).subscribe(user => {
      this.current_user = user;
      this.all$ = this.chatService.getChatCommunity('all');
      this.cdr.detectChanges();
    });

    const getDialog = (params) => {
      this.dialog = {};
      if (params['action'] === 'chat') {
        switch (params['action_type']) {
          case 'task-comments':
            this.dialog = {
              type: params['action_type'],
              taskId: params['task_id'],
              typeId: params['action_id'],
              addressId: params['address_sap_id'],
              stepId: params['step_id'],
              clientId: params['client_sap_id'],
              sentBy: params['sent_by'],
              approve: params['approve'],
              cancel: params['cancel']
            };

            break;
          case 'support':
            this.dialog = {
              type: params['action_type'],
              typeId: params['action_id'],
              addressId: params['address_sap_id'],
              clientId: params['client_sap_id'],
              sentBy: params['sent_by'],
            };
            break;
        }
        const observables = [];
        if (this.dialog.sentBy) {
          observables.push(this.chatService.getUser(this.dialog.sentBy));
        } else if (this.dialog.clientId) {
          observables.push(this.chatService.getUserByClientId(this.dialog.clientId));
        }
        observables.push(
          this.dialog.type === 'task-comments' && this.dialog.taskId ? this.chatService.getTaskById(this.dialog.taskId) : of(null),
          this.dialog.stepId ? this.chatService.getTaskStep(this.dialog.stepId) : of(null),
          this.dialog.addressId ? this.chatService.getOutletByAddressId(this.dialog.addressId) : of(null)
        );
        return forkJoin(observables);
      }
      return of([]);
    };

    this.route.queryParamMap.pipe(
      takeUntil(this.u$),
      mergeMap((paramsMap): Observable<any[]> => {
        const params = paramsMap['params'];
        return getDialog(params);
      })
    ).subscribe(([user, task, taskStep, outlet]) => {
      console.log('user-from:', user);
      this.dialog.task = task;
      this.dialog.taskStep = taskStep;
      this.dialog.outlet = outlet;
      this.record = user;
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .pipe(
        takeUntil(this.u$),
        mergeMap((): Observable<any[]> => {
          const params = { ...this.route.snapshot.params, ...this.route.snapshot.queryParams };
          return getDialog(params);
        })
      )
      .subscribe(([user, task, taskStep, outlet]) => {
        console.log('user-from:', user);
        this.dialog.task = task;
        this.dialog.taskStep = taskStep;

        this.dialog.outlet = outlet;
        this.record = user;
      });
  }

  onBack(): void {
    const queryParams = {
      action: 'chat',
      action_type: null,
      action_id: null,
      step_id: null,
      task_id: null,
      address_sap_id: null,
      client_sap_id: null,
      approve: null,
      cancel: null,
      sent_by: null
    };
    this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge'
        });
  }

  openChat(record: { dialog?: any, type?: ChatDialogType, info? }): void {
    console.log('record:', record);
    if (record && record.dialog) {
      const { id: current_user_id } = this.chatService.user, { id: reply_user_id = current_user_id } = record.dialog.message.replyUser || {};
      this.dialog = record.dialog;
      this.record = current_user_id !== reply_user_id ? record.dialog.message.replyUser : record.dialog.message.user;
    } else {
      this.dialog = null;
      this.record = record;
    }
    this.cdr.detectChanges();
  }
}
