import { createAction, props } from '@ngrx/store';
import { TaskProgress } from '@Mesh/core/models/task';

enum TaskProgressActionTypes {
    LoadTaskProgress = '[TaskProgress] Load TaskProgress',
    PopulateTaskProgress = '[TaskProgress] Populate TaskProgress Entity'
}

export const getTaskProgress = createAction(TaskProgressActionTypes.LoadTaskProgress, props<{ clientIds: number[], taskId: number }>());
export const populateTaskProgressEntity = createAction(TaskProgressActionTypes.PopulateTaskProgress, props<{ tasksProgress: TaskProgress[] }>());
