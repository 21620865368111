import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenPipe } from '@Mesh/shared/modules/token/token.pipe';

@NgModule({
  declarations: [
    TokenPipe
  ],
  exports: [
    TokenPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
      TokenPipe
  ]
})
export class TokenModule {
}
