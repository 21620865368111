import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../../reducers/breadcrumb/breadcrumb.reducers';

export const getBreadcrumbState = createFeatureSelector<fromAuth.State>('breadcrumb');


export const getAdditionals = createSelector(
  getBreadcrumbState,
  fromAuth.getAdditionals
);
