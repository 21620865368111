import { readNotificationSuccess } from './../../actions/notification/notification.actions';
import { PostingStatus } from './../../../core/models/external/loadable';
import { NotificationsData, NotificationToken } from '../../../core/models/notification';
import { createReducer, Action, on } from '@ngrx/store';
import * as fromActions from '../../actions/notification/notification.actions';

export interface State {
  pushToken: NotificationToken;
  notifications: NotificationsData;
  readNotificationStatus: PostingStatus;
}

export const initialState: State = {
  pushToken: null,
  notifications: null,
  readNotificationStatus: PostingStatus.Initial,
};

const notificationReducer = createReducer(
  initialState,
  on(fromActions.updatePushTokenSuccess, (state, { pushToken }) => ({ ...state, pushToken })),
  on(fromActions.loadNotificationsSuccess, (state, { notifications }) => ({ ...state, notifications })),
  on(fromActions.readNotification, (state, { id }) => ({ ...state, readNotificationStatus: PostingStatus.Posting })),
  on(fromActions.readNotificationSuccess, (state, { notification }) => ({ ...state, readNotificationStatus: PostingStatus.Posted })),
);

export function reducer(state: State | undefined, action: Action) {
  return notificationReducer(state, action);
}

export const getPushToken = (state: State) => state.pushToken;
export const getNotifications = (state: State) => state.notifications;
export const readNotificationStatus = (state: State) => state.readNotificationStatus;
