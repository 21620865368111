import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './location.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LocationService } from '../../core/services/api/location/location.service';

@Injectable()
export class LocationEffects {
  constructor(private actions$: Actions,
    private locationService: LocationService) {
  }

  findCountries$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.findCountries),
      switchMap(({ params }) => this.locationService.searchCountry(params)
        .pipe(
          map(data => fromActions.findCountriesSuccess({ data: data.content })),
          catchError(err => ([fromActions.findCountriesError(err)]))
        )
      )
    ));

  findRegions$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.findRegions),
      switchMap(({ params }) => this.locationService.searchRegion(params)
        .pipe(
          map(data => fromActions.findRegionsSuccess({ data: data.content })),
          catchError(err => ([fromActions.findRegionsError(err)]))
        )
      )
    ));

  findCities$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.findCities),
      switchMap(({ params }) => this.locationService.searchCity(params)
        .pipe(
          map(data => fromActions.findCitiesSuccess({ data: data.content })),
          catchError(err => ([fromActions.findCitiesError(err)]))
        )
      )
    ));
}
