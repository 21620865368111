import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'iql-table-value-changeable',
    templateUrl: './value-changeable.component.html',
    styleUrls: ['./value-changeable.component.scss']
})
export class ValueChangeableComponent {
    @Input() row: any;
    @Input() key: string;
    @Input() value = 1;
    @Output() valueChanged = new EventEmitter<any>();
    @ViewChild('edit', { static: false }) edit: ElementRef<HTMLInputElement>;

    onValueChange(row: any, key: string): void {
        this.valueChanged.emit({ row, key });
    }

    onClick(): void {
        if (this.row[this.key] === 0) {
            this.edit.nativeElement.value = '';
        }
    }

    constructor() {
    }
}
