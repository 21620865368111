import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {CoefficientType} from '@Mesh/pages/goals/components/create-goals/createGoalTypes';

@Component({
  selector: 'iql-badge-label',
  templateUrl: './badge-label.component.html',
  styleUrls: ['./badge-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeLabelComponent {
  @Input()
  label: CoefficientType;
}
