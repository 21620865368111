import { Deserializable } from './deserializable';
import { User } from '@Mesh/core/models/user';
import { ChatUserInfo } from './chat-user-info';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

export class Message implements Deserializable {
  id: number;
  text: string;
  user: ChatUserInfo;
  replyUser?: ChatUserInfo;
  type: string | null;
  typeId?: number | null;
  readAt?: Date | null;
  task?: any | null;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  userSubject: BehaviorSubject<User>;
  replyUserSubject: BehaviorSubject<User>;
  participants?: number[];
  totalUnread?: number;
  // link?: string;
  // teacher?: ChatUserInfo;

  deserialize(input: any): this {
    Object.assign(this, input);
    if (this.user) {
      this.user = new ChatUserInfo().deserialize(this.user);
      const tempUser = new User().deserialize({
        id: input.user.id
      });
      this.userSubject = new BehaviorSubject<User>(tempUser);
    }
    if (this.replyUser) {
      const tempReplyUser = new User().deserialize({
        id: input.replyUser.id
      });
      this.replyUserSubject = new BehaviorSubject<User>(tempReplyUser);
    }
    this.createdAt = new Date(input.createdAt || Date.now());
    this.updatedAt = new Date(input.updatedAt || Date.now());
    return this;
  }

  get createdAtFromNow(): string {
    if (this.createdAt) {
      return moment(this.createdAt).fromNow();
    }
  }
}
