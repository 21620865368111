import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DataService } from "@Mesh/core/services/api/deprecated/data.service";
import * as fromActions from "../../actions/deprecated/outlets.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { uniqBy } from "lodash-es";
import { TasksService } from "@Mesh/core/services/api/task/tasks.service";
import { getClientsOutletsLookupByParam } from "@Mesh/store/actions/deprecated/clients-outlets-lookup.actions";
import { OutletService } from "../../../core/services/api/outlet/outlet.service";

@Injectable()
export class OutletsEffects {
    constructor(
        private _actions$: Actions,
        private dataService: DataService,
        private outletService: OutletService,
        private tasksService: TasksService
    ) {}

    getOutlets$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.getOutlets),
            switchMap(({ clientId, pageSize }) => this.dataService.getOutlets(clientId, pageSize)),
            map((outlets) => fromActions.populateOutletEntity({ outlets }))
        )
    );

    loadOutletList$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.loadOutletList),
            switchMap((param) =>
                this.outletService
                    .getOutlets(param)
                    .pipe(map((outletData) => fromActions.outletListLoaded({ outletData })))
            )
        )
    );

    getClientOutletIds$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.getClientOutletIds),
            switchMap(({ taskId, clientId }) => {
                return this.tasksService.getTaskOutletsByClientId(taskId, clientId);
            }),
            map((ids) => fromActions.setSelectedOutlets({ ids }))
        )
    );

    getTaskOutletIds$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.getTaskOutletIds),
            switchMap(({ taskId }) => {
                return this.tasksService.getTaskOutletIds(taskId);
            }),
            switchMap((objs) => [
                fromActions.setSelectedOutlets({
                    ids: uniqBy(
                        objs.map(({ addressId }) => addressId),
                        "addressId"
                    ),
                }),
                getClientsOutletsLookupByParam({
                    clientIds: uniqBy(
                        objs.map(({ clientId }) => clientId),
                        "clientId"
                    ),
                }),
            ])
        )
    );

    loadSearchAddressClient$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.findAllOutletsAddress),
            switchMap(({ searchAddressParams }) =>
                this.outletService.searchAddressesClient(searchAddressParams).pipe(
                    map((data) => fromActions.findAllOutletsAddressSuccess({ outletsAddress: data.content })),
                    catchError((err) => [fromActions.findAllOutletsAddressError()])
                )
            )
        )
    );

    loadAllAddressOutlets$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.getAllOutletsAddress),
            switchMap(({ searchAddressParams }) =>
                this.outletService.getAllAddressByParameters(searchAddressParams).pipe(
                    map((data) => fromActions.getAllOutletsAddressSuccess({ outletsAddress: data.content })),
                    catchError((err) => [fromActions.getAllOutletsAddressError()])
                )
            )
        )
    );
    loadFilterAddressOutlets$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.getFilterOutletsAddress),
            switchMap(({ searchAddressParams }) =>
                this.outletService.getAllAddressByParameters(searchAddressParams).pipe(
                    map((data) => fromActions.getFilterOutletsAddressSuccess({ outletsAddress: data.content })),
                    catchError((err) => [fromActions.getFilterOutletsAddressError()])
                )
            )
        )
    );
    loadOneAddressOutlets$ = createEffect(() =>
        this._actions$.pipe(
            ofType(fromActions.getOneOutletsAddress),
            switchMap(({ searchAddressParams }) =>
                this.outletService.searchAddressesClient(searchAddressParams).pipe(
                    map((data) => fromActions.getAllOutletsAddressSuccess({ outletsAddress: data.content })),
                    catchError((err) => [fromActions.getAllOutletsAddressError()])
                )
            )
        )
    );
}
