import { Action, createReducer, on } from '@ngrx/store';
import * as userActions from './users.actions';
import { IUser } from '../../core/models/user';
import {LoadingStatus} from '@Mesh/core/models/external/loadable';

export interface UsersState {
    users: Array<IUser>;
    usersLoadingStatus: LoadingStatus;
}

export const initialstate: UsersState = {
    users: [],
    usersLoadingStatus: LoadingStatus.NotLoaded
};

export const featureKey = 'users';

const userReducer = createReducer(
    initialstate,
    on(userActions.findAllUsersSuccess, (state, { users }) =>
    ({
        ...state,
        users:  [...state.users, ...users]
    })),
    on(userActions.resetAllUsers, (state) =>
    ({
        ...state,
        users:  []
    })),
);

export function reducer(state: UsersState, action: Action): UsersState {
    return userReducer(state, action);
}

export const getUsersData = (state: UsersState): IUser[] => state.users;
