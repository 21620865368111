import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/api/auth/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { State } from '@Mesh/store/reducers';
import { Store } from '@ngrx/store';
import { refreshTokenSuccess } from '@Mesh/store/actions/auth/auth.actions';
import { IUserInfo } from '@Mesh/core/models/user';
import { DADATA_TOKEN } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store: Store<State>) {
  }

  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private hardcodeToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5zZmEtc3RhZ2UtdjIucmV0YWlsLmlxbC5ydSIsImlhdCI6MTY2MTM1Nzk5OSwiZXhwIjozNTU0Nzc1MTE5LCJuYmYiOjE2NjEzNTc5OTksImp0aSI6ImptYnVubGpLcEFlamNKR3YiLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsInBlcl9udW1iZXIiOiI4OTkyMCIsImV4dGVybmFsX2lkIjpudWxsfQ.rMl4wF8wH-sn7_BU20cQkhF6VxuiI6b6LSBnfRay_WaeE4wkEH7JKiNzEqRaCumBoIJhPiysf-JEw2z80RxQEA';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authenticatedRequest = request;
    const token = this.authService.accessToken;

    if (request.url.indexOf('dadata.ru') !== -1) {
      authenticatedRequest = this.addTokenDadateHeader(request, DADATA_TOKEN);
      authenticatedRequest = this.addContentTypeJson(authenticatedRequest);

      return next.handle(authenticatedRequest).pipe(
        catchError(error => {
          return throwError(error);
        }));
    }

    if (token != null && !request.url.endsWith('api/v1/refresh-token')
      && (!request.url.endsWith('api/v1/token') || request.url.indexOf('notification') !== -1)
      && !request.url.endsWith('upload-media') && !request.url.endsWith('search/learn')) {
      authenticatedRequest = this.addTokenHeader(request, token);
    }

    if (request.url.endsWith('search/learn')) {
      authenticatedRequest = this.addTokenHeader(request, this.hardcodeToken);
    }

    authenticatedRequest = this.addContentTypeJson(authenticatedRequest);

    return next.handle(authenticatedRequest).pipe(
      catchError(error => {
        if (error.status === 401) {
          return this.handleUnauthorizedError(authenticatedRequest, next);
        }

        return throwError(error);
      }));
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authService.updateTokens().pipe(
        switchMap((userInfo: IUserInfo) => {
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          this.store.dispatch(refreshTokenSuccess({ userInfo: userInfo }));
          this.isRefreshing = false;

          this.accessTokenSubject.next(userInfo.accessToken);

          return next.handle(this.addTokenHeader(request, userInfo.accessToken));
        }),
        catchError((err) => {
          this.isRefreshing = false;

          this.authService.logout();
          return throwError(err);
        })
      );
    }

    return this.accessTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private addTokenDadateHeader(request: HttpRequest<any>, token: string): any {
    return request.clone({ setHeaders: { Authorization: `Token ${token}` } });
  }

  private addContentTypeJson(request: HttpRequest<any>): any {
    if (!request.url.endsWith('upload-media')
      && !request.url.endsWith('api/v1/content/doc')
      && !request.url.endsWith('api/v1/content/image')
      && !request.url.includes('/api/v1/import/create/')) {
      return request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    return request.clone({ headers: request.headers.delete('Content-Type') });
  }
}
