import { Component, ChangeDetectionStrategy, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ProductMedia } from '@Mesh/core/models/product';
import { IMAGE_URL, IMAGES_URL } from '@Env/environment';

@Component({
  selector: 'iql-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnChanges {
  readonly url = IMAGE_URL;
  readonly basePath = IMAGES_URL;

  @Input() materialId: number;
  @Input() imageList: ProductMedia[];
  @Input() readState = true;
  @Output() elementDeleteEvent = new EventEmitter<any>();
  @Output() elementCreateEvent = new EventEmitter<ProductMedia>();


  mainImage: ProductMedia;
  currentIndex = 0;
  maxIndex: number;

  constructor() { }

  ngOnChanges(): void {
    this.mainImage = this.imageList && this.imageList.length ? this.imageList[0] : null;
    this.currentIndex = 0;
    this.maxIndex = this.imageList && this.imageList.length ? this.imageList.length - 1 : null;
  }

  onSliderLeft(): void {
    this.currentIndex = this.currentIndex === 0 ? this.currentIndex : this.currentIndex - 1;
    this.mainImage = this.imageList[this.currentIndex];
  }
  onSliderRight(): void {
    this.currentIndex = this.currentIndex === this.maxIndex ? this.currentIndex : this.currentIndex + 1;
    this.mainImage = this.imageList[this.currentIndex];
  }
  onDeleteItem(): void {
    this.imageList = this.imageList.filter((i, index) => index !== this.currentIndex);
    this.elementDeleteEvent.emit({deletedIndex: this.currentIndex, deletedId: this.mainImage?.id});
    this.mainImage = this.imageList.length ? this.imageList[0] : null;
    this.currentIndex = 0;
  }
  onEditItem(): void {

  }
  onUploadNewImage(change: any): void {
    if (change.file && change.file.response) {
      const newImage: ProductMedia = {
        url: change.file.response.relativeUrl,
        sort: 0,
        materialId: this.materialId
      };
      this.currentIndex = this.mainImage ? this.currentIndex + 1 : 0;
      this.mainImage = newImage;
      //this.imageList.push(newImage);
      this.elementCreateEvent.emit(newImage);
    }
  }
}
