import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@Mesh/core/models/user';
import { Observable, of, Subject } from 'rxjs';
import { FeathersService } from '@Mesh/core/services/chat/feathers.service';

@Injectable({providedIn: 'root'})
export class UserService {
  users: User[] = [];

  private _user: User = null;

  constructor(private http: HttpClient,
              private feathersService: FeathersService
  ) {
  }

  // private statusSource = new BehaviorSubject(1);
  onResponse$ = new Subject();

  user(fresh: boolean): Observable<User> {
    if (this.feathersService.currentUserSubject.value) {
      return of(this.feathersService.currentUserSubject.value);
    }
  }
}
