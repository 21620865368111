import { Action, createReducer, on } from '@ngrx/store';
import { AgentVisit } from '../../../core/models/agent';
import { PostingStatus } from '../../../core/models/external/loadable';
import * as fromActions from '../../actions/agent/agent.actions';
import { HttpErrorResponse } from '@angular/common/http';

export const agentFeatureKey = 'agent';

export interface State {
  agentVisits: AgentVisit[];
  creatingAgentVisitsStatus: PostingStatus;
  creatingAgentVisitsError: HttpErrorResponse;
}

export const initialState: State = {
  agentVisits: null,
  creatingAgentVisitsStatus: null,
  creatingAgentVisitsError: null
};

export const agentReducer = createReducer(
  initialState,
  on(fromActions.findAgentVisitSuccess, (state, { agentVisits }) => ({ ...state, agentVisits })),
  on(fromActions.createAgentVisit, (state) => ({
    ...state,
    creatingAgentVisitsStatus: PostingStatus.Initial,
    creatingAgentVisitsError: null
  })),
  on(fromActions.editAgentVisit, (state) => ({
    ...state,
    creatingAgentVisitsStatus: PostingStatus.Initial,
    creatingAgentVisitsError: null
  })),
  on(fromActions.editAgentVisitSuccess, (state, { data }) => {
    return {
      ...state,
      creatingAgentVisitsStatus: PostingStatus.Posted,
      agentVisits: state.agentVisits.map((item) => item.id === data.id ? { ...item, ...data } : item),
    };
  }),
  on(fromActions.createAgentVisitSuccess, (state, { data }) => {
    return {
      ...state,
      creatingAgentVisitsStatus: PostingStatus.Posted,
      agentVisits: [...state.agentVisits, ...data],
    };
  }),
  on(fromActions.createAgentVisitFailure, (state, error) => {
    return {
      ...state,
      creatingAgentVisitsStatus: PostingStatus.Error,
      creatingAgentVisitsError: error
    };
  }),
  on(fromActions.createVisitReset, (state) => {
    return {
      ...state,
      creatingAgentVisitsStatus: PostingStatus.Initial,
      creatingAgentVisitsError: null
    };
  }),
  on(fromActions.removeAgentVisitSuccess, (state, { data }) => {
    return {
      ...state,
      agentVisits: state.agentVisits.filter(item => item.id !== data[0]),
    };
  }),
);

export function reducer(state: State, action: Action): any {
  return agentReducer(state, action);
}

export const getAgentVisits = (state: State) => state.agentVisits;
export const getCreatingAgentVisitsStatus = (state: State) => state.creatingAgentVisitsStatus;
export const getCreatingAgentVisitsError = (state: State) => state.creatingAgentVisitsError;