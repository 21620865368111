import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {
  lang = 'ru';

  constructor() {
    moment.locale(this.lang);
  }

  transform(date: string | Date | moment.Moment, format: string = 'L LT', utc: boolean = true): any {
    if (!date) {
      return '';
    }
    return utc ? moment.utc(date).local().format(format) : moment(date).format(format);
  }
}
