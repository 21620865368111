import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryMain, IQLCategory } from '@Mesh/core/models/tree';
import { HttpClient } from '@angular/common/http';
import { IMAGE_URL, SALEPLAN_IMAGES_URL, SALEPLAN_URL } from '@Env/environment';
import { faCaretSquareDown, faCaretSquareLeft, faEdit } from '@fortawesome/free-regular-svg-icons';
import { Brand } from '@Mesh/core/models/brand';

@Component({
  selector: 'iql-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss']
})
export class TreeTableComponent implements OnInit {

  readonly url = IMAGE_URL;
  readonly basePath = SALEPLAN_IMAGES_URL;

  list: IQLCategory;
  faDown = faCaretSquareDown;
  faLeft = faCaretSquareLeft;
  faEdit = faEdit;

  @Input() data: CategoryMain;
  @Output() brandEdit = new EventEmitter<Brand>();

  constructor(private readonly http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.get<IQLCategory>(`${SALEPLAN_URL}/v2/category/categoryManufactureBrands?materialGroupName=${this.data.categoryNameId}`).subscribe(list => {
      this.list = list;
    });
  }

  onDataEdit(brand: Brand, event: MouseEvent): void {
    event.stopPropagation();
    this.brandEdit.next(brand);
  }
}
