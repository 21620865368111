import { createAction, props } from '@ngrx/store';
import { City, CitySearchParams, Country, CountrySearchParams, Region, RegionSearchParams } from '../../core/models/location';

export enum type {
    FIND_COUNTRIES = '[Location] Find Countries',
    FIND_COUNTRIES_ERROR = '[Location] Find Countries Error',
    FIND_COUNTRIES_SUCCESS = '[Location] Find Countries Success',
    RESET_COUNTRIES = '[Location] Reset Countries',
    FIND_REGIONS = '[Location] Find Regions',
    FIND_REGIONS_ERROR = '[Location] Find Regions Error',
    FIND_REGIONS_SUCCESS = '[Location] Find Regions Success',
    RESET_REGIONS = '[Location] Reset Regions',
    FIND_CITIES = '[Location] Find Cities',
    FIND_CITIES_ERROR = '[Location] Find Cities Error',
    FIND_CITIES_SUCCESS = '[Location] Find Cities Success',
    RESET_CITIES = '[Location] Reset Cities',
}


export const findCountries = createAction(type.FIND_COUNTRIES, props<{ params?: Partial<CountrySearchParams> }>());
export const findCountriesError = createAction(type.FIND_COUNTRIES_ERROR, props<{ error: any }>());
export const findCountriesSuccess = createAction(type.FIND_COUNTRIES_SUCCESS, props<{ data: Array<Country> }>());
export const resetCountries = createAction(type.RESET_COUNTRIES);

export const findRegions = createAction(type.FIND_REGIONS, props<{ params?: Partial<RegionSearchParams> }>());
export const findRegionsError = createAction(type.FIND_REGIONS_ERROR, props<{ error: any }>());
export const findRegionsSuccess = createAction(type.FIND_REGIONS_SUCCESS, props<{ data: Array<Region> }>());
export const resetRegions = createAction(type.RESET_REGIONS);

export const findCities = createAction(type.FIND_CITIES, props<{ params?: Partial<CitySearchParams> }>());
export const findCitiesError = createAction(type.FIND_CITIES_ERROR, props<{ error: any }>());
export const findCitiesSuccess = createAction(type.FIND_CITIES_SUCCESS, props<{ data: Array<City> }>());
export const resetCities = createAction(type.RESET_CITIES);
